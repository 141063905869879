import React from "react";
import { 
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarSubMenu,
  CDBSidebarMenuItem } from "cdbreact";
import { NavLink } from "react-router-dom";

const Sidebar = ({bgColor, color}) => {

  const backgroundColor = bgColor ? bgColor : "#333";
  const textColor = color ? color : "#fff";

  return (
    <div
      className={`app`}
      style={{ display: "flex", height: "100%", overflow:"scroll initial", fontSize:"14px" }}
    >
      <CDBSidebar
        textColor={textColor}
        backgroundColor={backgroundColor}
      >
        <CDBSidebarHeader
          prefix={
            <i className="fa fa-bars fa-large"></i>
          }
        >
          <a href="/" className="text-decoration-none" style={{color:"inherit", fontWeight:"600"}}>
            Contrast
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent>
          <CDBSidebarMenu className="sidebar-content">
            <CDBSidebarSubMenu title="Dashboards" icon="columns">
              <NavLink
                exact
                to="/"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  dashboard
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/prodashboard"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  prodashboard
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/prodashboard2"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  prodashboard dark
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/prodashboard3"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  prodashboard 3
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/prodashboard4"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  prodashboard 4
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="About" icon="address-card">
              <NavLink
                exact
                to="/about"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  about
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/about2"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  about 2
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Hero" icon="chalkboard-teacher">
              <NavLink
                exact
                to="/hero"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  hero
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/hero2"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  hero 2
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/hero3"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  hero 3
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/hero4"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  hero 4
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/hero5"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  hero 5
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Forms" icon="sign-in-alt">
              <NavLink
                exact
                to="/create-account"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  create account
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/forgot-password"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  forgot password
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/forgot-password2"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  forgot password 2
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/forgot-password-popup"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  forgotpassword-popup
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/signin"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  signin
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/signin2"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  signin2
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/signin3"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  signin3
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/signup"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  signup
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/signup2"
                target="_blank"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  signup2
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
          </CDBSidebarMenu>
          <CDBSidebarMenu className="sidebar-content">
            <NavLink
              exact
              to="/tables"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                icon="table"
              >
                tables
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/hero404"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                icon="exclamation-triangle"
              >
                404 page
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/chatui"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                icon="comments"
              >
                chat ui
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              target="_blank"
              to="/landing"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                icon="plane-arrival"
              >
                landing
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              target="_blank"
              to="/post"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                icon="mail-bulk"
              >
                post
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/profile"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                icon="id-badge"
              >
                profile
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/profilefree"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                icon="id-badge"
              >
                profile 2
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              target="_blank"
              to="/pricing"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                icon="money-bill-alt"
              >
                pricing
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
          <CDBSidebarMenu className="sidebar-content">
            <CDBSidebarSubMenu title="Components" icon="cogs">
              <NavLink
                exact
                to="/accordion"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  accordion
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/animation"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  animation
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/box"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  box
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/card"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  card
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/carousel"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  carousel
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/chart"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  chart
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/collapse"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  collapse
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/dropdown"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  dropdown
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/icon"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  icon
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/iframe"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  iframe
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/list-group"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  list group
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/mask"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  mask
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/pane"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  pane
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/panel"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  panel
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/rating"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  rating
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/smooth-scroll"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  smooth scroll
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Button" icon="mouse">
              <NavLink
                exact
                to="/badge"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  badge
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/button"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  button
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/button-group"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  button group
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/button-toolbar"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  button toolbar
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Forms" icon="sign-in-alt">
              <NavLink
                exact
                to="/autocomplete"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  autocomplete
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/checkbox"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  checkbox
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/forms"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  forms
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/input"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  input
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/input-group"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  input group
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/multiselect"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  multiselect
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/radio"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  radio
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/select"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  select
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/select2"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  select2
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/switch"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  switch
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Navigation" icon="directions">
              <NavLink
                exact
                to="/breadcrumb"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  breadcrumb
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/footer"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  footer
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/navbar"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  navbar
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/pagination"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  pagination
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/sidebar"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  sidebar
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/stepper"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  stepper
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/tabs"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  tabs
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Notification" icon="flag-checkered">
              <NavLink
                exact
                to="/alert"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  alert
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/modal"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  modal
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/notification"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  notification
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Progress" icon="spinner">
              <NavLink
                exact
                to="/slider"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  slider
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/spinner"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  spinner
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/progress"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  progress
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Section" icon="calendar">
              <NavLink
                exact
                to="/date-picker"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  date picker
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/file-uploader"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  file uploader
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/time-picker"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  timepicker
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
            <CDBSidebarSubMenu title="Tables" icon="table">
              <NavLink
                exact
                to="/data-table"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  data table
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/editable-table"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  editable table
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/table"
                activeClassName="activeClicked"
              >
                <CDBSidebarMenuItem className="mx-0">
                  table
                </CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarSubMenu>
          </CDBSidebarMenu>
          
        </CDBSidebarContent>

        <CDBSidebarFooter className="text-center">
          <div
            className="sidebar-btn-wrapper"
            style={{ padding: "20px 5px" }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
}

export default Sidebar;
