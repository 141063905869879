import React, { Component } from "react";
import { CDBTimePicker, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export class TimePicker extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="d-flex w-100">
        <div style={{height:"100vh", overflow:"hidden initial"}}>
          <Sidebar/>
        </div>
        <Layout>
          <TemplateNav/>
      <div classname="r-w">
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a>
          <CDBTimePicker placeholder="10:05 AM" theme="classic" colorPalette="dark" />
        </div>
      </div>
      </Layout>
    </div>
    );
  }
}
