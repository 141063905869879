import React from "react";
import {
  CDBCard,
  CDBCardBody,
  CDBCardTitle,
  CDBCardText,
  CDBBtn,
  CDBIcon,
  CDBLink,
  CDBFooter,
  CDBPane,
  CDBPanel,
  CDBPanelTitle,
  CDBPanelText,
  CDBPaneImage,
  CDBBox,
} from "cdbreact";
import { Line } from "react-chartjs-2";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./ProDashboard.css";

export const ProDashboard = () => {
  const data = {
    chart1: {
      labels: [
        "Eating",
        "Drinking",
        "Sleeping",
        "Designing",
        "Coding",
        "Cycling",
        "Running",
      ],
      datasets: [
        {
          label: "My Dataset",
          lineTension: 0.1,
          pointRadius: 0,
          backgroundColor: "rgba(193, 240, 193, 1)",
          fill: 'origin',
          borderColor: "rgb(39, 174, 96)",
          borderWidth: 2,
          data: [35, 45, 55, 50, 65, 57, 55],
        },
      ],
    },
    chart2: {
      labels: [
        "Eating",
        "Drinking",
        "Sleeping",
        "Designing",
        "Coding",
        "Cycling",
        "Running",
      ],
      datasets: [
        {
          label: "My Dataset",
          lineTension: 0.1,
          pointRadius: 0,
          backgroundColor: "rgba(161, 198, 247, 1)",
          fill: 'origin',
          borderColor: "rgb(47, 128, 237)",
          borderWidth: 2,
          data: [35, 45, 55, 50, 65, 57, 55],
        },
      ],
    },
    chart3: {
      labels: [
        "Eating",
        "Drinking",
        "Sleeping",
        "Designing",
        "Coding",
        "Cycling",
        "Running",
      ],
      datasets: [
        {
          label: "My First dataset",
          lineTension: 0.1,
          pointRadius: 0,
          backgroundColor: "rgba(153, 255, 255, 0.7)",
          fill: 'origin',
          borderColor: "rgb(0, 179, 179)",
          borderWidth: 2,
          data: [35, 45, 55, 50, 65, 57, 55],
        },
        {
          label: "My Second dataset",
          lineTension: 0.1,
          pointRadius: 0,
          backgroundColor: "rgba(193, 240, 193, 0.7)",
          fill: 'origin',
          borderColor: "rgb(39, 174, 96)",
          borderWidth: 2,
          data: [58, 55, 46, 45, 49, 43, 35],
        },
        {
          label: "My Third dataset",
          lineTension: 0.1,
          pointRadius: 0,
          backgroundColor: "rgba(161, 198, 247, 0.7)",
          fill: 'origin',
          borderColor: "rgb(47, 128, 237)",
          borderWidth: 2,
          data: [50, 57, 56, 55, 59, 53, 45],
        },
      ],
    },
  };
  const options = {
    option1: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
      },
      scales: {
        x:{
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        y: {
          beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
      },
    },
    option2: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
      },
      scales: {
        x :
          {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        
        y: 
          {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
      },
    },
  };

  return (
    <div className="prodashboard" style={{ background: "#FAFAFA" }}>
      <div className="d-flex">
        <div style={{ height: "100vh", overflow: "hidden initial" }}>
          <Sidebar bgColor="#fff" color="#333" />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div className="scroll-container">
              <div className="cards-container">
                <CDBCard className="card-bg">
                  <CDBCardBody className="d-flex flex-column p-0">
                    <div className="d-flex align-items-center mx-4 mt-3">
                      <div
                        className="p-2 rounded-circle"
                        style={{ background: "#E0E0E0" }}
                      >
                        <CDBIcon fas icon="suitcase" />
                      </div>
                      <CDBPanelTitle className="ms-3 small">
                        MARKET SALES
                      </CDBPanelTitle>
                    </div>
                    <div className="d-flex flex-wrap align-items-center py-4 mx-4">
                      <CDBCardTitle className="fs font-weight-bold me-3">
                        $30,000
                      </CDBCardTitle>
                      <CDBCardText
                        className="text-success mb-0"
                        style={{ fontWeight: "600" }}
                      >
                        <CDBIcon fas className="p-0" icon="angle-up" />
                        <span
                          className="ms-2"
                          style={{ fontWeight: "600", fontSize: "0.9em" }}
                        >
                          27.4%
                        </span>
                        <span
                          style={{ fontSize: "0.8em" }}
                          className="text-dark mb-0 ms-3 d-block"
                        >
                          Since last month
                        </span>
                      </CDBCardText>
                    </div>
                    <div style={{ margin: "auto 0 -0.70rem -0.70rem" }}>
                      <Line
                        height={210}
                        data={data.chart1}
                        options={options.option1}
                      />
                    </div>
                    <div
                      className="d-flex c-p"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                      }}
                    >
                      <div className="p-2 rounded-circle bg-dark text-white">
                        <CDBIcon fas icon="arrow-right" />
                      </div>
                    </div>
                  </CDBCardBody>
                </CDBCard>
                <CDBCard className="card-bg gc-2">
                  <CDBCardBody className="p-0 d-flex flex-column">
                    <div className="d-flex align-items-center mx-4 mt-3" style={{ paddingBottom: "2rem" }}>
                      <div
                        className="p-1 rounded-circle"
                        style={{ background: "#E0E0E0" }}
                      >
                        <CDBIcon fas icon="suitcase" />
                      </div>
                      <CDBCardTitle className="ms-3 small">TRENDS</CDBCardTitle>
                    </div>
                    <div style={{ margin: "auto 0 -0.70rem -0.70rem" }}>
                      <Line
                        height={300}
                        data={data.chart2}
                        options={options.option1}
                      />
                    </div>
                    <div className="card2-pos">
                      <CDBCardText className="mb-0">
                        This site rocks:
                      </CDBCardText>
                      <CDBCardTitle
                        className="my-2 fs"
                        style={{ fontWeight: "600" }}
                      >
                        $330,000
                      </CDBCardTitle>
                      <CDBCardText className="mb-0">
                        in global design community.
                      </CDBCardText>
                    </div>
                    <div
                      className="d-flex c-p"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                      }}
                    >
                      <div className="p-2 rounded-circle bg-dark text-white">
                        <CDBIcon fas icon="arrow-right" />
                      </div>
                    </div>
                  </CDBCardBody>
                </CDBCard>
                <CDBPanel className="card-bg gr-2 w-100 d-flex flex-column panel-card">
                  <CDBPane>
                    <div
                      className="rounded-circle p-2"
                      style={{ background: "#E0E0E0" }}
                    >
                      <CDBIcon fas icon="user" />
                    </div>
                    <CDBPanelTitle className="ms-3 small">
                      TEAM MEMBERS
                    </CDBPanelTitle>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane1.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane2.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane3.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane4.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane5.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane1.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane2.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane3.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <CDBPane className="mt-4 d-flex justify-content-between">
                    <CDBBox display="flex">
                      <CDBPaneImage
                        className="pane-image"
                        src="img/pane/pane4.png"
                      />
                      <div>
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText style={{ fontSize: "0.7em" }}>
                          Online
                        </CDBPanelText>
                      </div>
                    </CDBBox>
                    <CDBBtn
                      outline
                      color="dark"
                      flat
                      size="small"
                      className="ms-auto"
                    >
                      <span className="msg-rem">Send </span>Message
                    </CDBBtn>
                  </CDBPane>
                  <div className="d-flex ms-auto mt-auto c-p">
                    <div className="p-2 rounded-circle bg-dark text-white">
                      <CDBIcon fas icon="arrow-right" />
                    </div>
                  </div>
                </CDBPanel>
                <CDBCard className="card-bg gc-2 gr-2">
                  <CDBCardBody className="p-0 w-100 d-flex flex-column">
                    <div className="d-flex align-items-center mx-4 mt-3">
                      <div
                        className="p-1 rounded-circle"
                        style={{ background: "#E0E0E0" }}
                      >
                        <CDBIcon fas icon="suitcase" />
                      </div>
                      <CDBCardTitle className="ms-3 small">
                        PAGE VIEWS
                      </CDBCardTitle>
                    </div>
                    <div className="badge-container">
                      <div className="d-flex">
                        <div
                          className="shadow rounded d-flex flex-column p-3 text-white bg-primary me-2"
                          style={{ width: "120px", height: "100px" }}
                        >
                          <p className="mb-0 small">Clicks</p>
                          <p
                            className="mt-auto mb-0 h3"
                            style={{ fontWeight: "600" }}
                          >
                            33k
                          </p>
                        </div>
                        <div
                          className="shadow rounded d-flex flex-column p-3 text-white bg-success me-2"
                          style={{ width: "120px", height: "100px" }}
                        >
                          <p className="mb-0 small">Impressions</p>
                          <p
                            className="mt-auto mb-0 h3"
                            style={{ fontWeight: "600" }}
                          >
                            248k
                          </p>
                        </div>
                      </div>
                      <div className="d-flex batch2">
                        <div
                          className="shadow rounded d-flex flex-column p-3 me-2"
                          style={{ width: "120px", height: "100px" }}
                        >
                          <p className="mb-0 small">Average CTR</p>
                          <p
                            className="mt-auto mb-0 h3"
                            style={{ fontWeight: "600" }}
                          >
                            18k
                          </p>
                        </div>
                        <div
                          className="shadow rounded d-flex flex-column p-3"
                          style={{ width: "120px", height: "100px" }}
                        >
                          <p className="mb-0 small">onHover</p>
                          <p
                            className="mt-auto mb-0 h3"
                            style={{ fontWeight: "600" }}
                          >
                            11.2s
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-auto">
                      <Line
                        height={500}
                        data={data.chart3}
                        options={options.option2}
                      />
                    </div>
                    <div className="d-flex ms-auto me-4 mb-4 c-p" style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      cursor: "pointer",
                    }}>
                      <div className="p-2 rounded-circle bg-dark text-white">
                        <CDBIcon fas icon="arrow-right" />
                      </div>
                    </div>
                  </CDBCardBody>
                </CDBCard>
              </div>
              <CDBFooter>
                <div className="footer">
                  <CDBLink to="/" className="footer-link">
                    <img
                      alt="logo"
                      src="/img/pages/logo.png"
                      className="me-2"
                      width="25px"
                    />
                    <span className="ms-4 mb-0 h5 font-weight-bold">
                      Devwares
                    </span>
                  </CDBLink>
                  <small>&copy; Devwares, 2020. All rights reserved.</small>
                  <div className="footer-rem d-flex">
                    <CDBBtn flat className="p-1 bg-dark border-0">
                      <CDBIcon fab icon="facebook-f" />
                    </CDBBtn>
                    <CDBBtn flat className="mx-3 p-1 bg-dark border-0">
                      <CDBIcon fab icon="twitter" />
                    </CDBBtn>
                    <CDBBtn flat className="p-1 bg-dark border-0">
                      <CDBIcon fab icon="instagram" />
                    </CDBBtn>
                  </div>
                </div>
              </CDBFooter>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
