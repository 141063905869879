import React, { Fragment } from "react";
import { CDBBtn, CDBBtnGrp, CDBBtnTb } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const ButtonToolbar = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <CDBBtnTb>
        <CDBBtnGrp className="mr-2">
          <CDBBtn color="primary">Click me</CDBBtn>
          <CDBBtn color="secondary">Click me</CDBBtn>
          <CDBBtn color="success">Click me</CDBBtn>
        </CDBBtnGrp>
        <CDBBtnGrp size="sm" className="mr-2">
          <CDBBtn color="primary">Click me</CDBBtn>
          <CDBBtn color="secondary">Click me</CDBBtn>
          <CDBBtn color="success">Click me</CDBBtn>
        </CDBBtnGrp>
        <CDBBtnGrp >
          <CDBBtn color="danger">Click me</CDBBtn>
          <CDBBtn color="dark">Click me</CDBBtn>
          <CDBBtn color="warning">Click me</CDBBtn>
        </CDBBtnGrp>
      </CDBBtnTb>
    </div>
      </Layout>
    </div>
  );
};
