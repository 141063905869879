import styled from "styled-components";

export const Header = styled.header`

	nav {
		background:${props => props.bg ? props.bg : "#fff"};
		color:${props => props.color ? props.color : "#333"};
	}

	.input-nav {
		margin-left:5rem !important;
		width:30rem;
	}

	.space {
		margin-right:1rem;
	}

	@media(max-width:1320px) {
		.input-nav {
			width:15rem;
		}
	}

	@media(max-width:920px) {
		.input-nav, .space {
			display:none;
		}
	}
`;