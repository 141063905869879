import React from "react";
import { CDBFileUploader, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const FileUploader = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div classname="r-w">
      <div style={{ width: "80%", margin: "0 auto" }}>
          <div className="blockcode">
            <div className="header">File Uploader <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <CDBFileUploader />
      </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
