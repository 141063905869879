import React from "react";
import { CDBIframe } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Iframe = () => {
  return (
		<div className="d-flex w-100">
		  <div style={{height:"100vh", overflow:"hidden initial"}}>
			<Sidebar/>
		  </div>
		  <Layout>
			<TemplateNav/>
    <div classname="r-w">
      <div style={{ width: "80%", margin: "0 auto", background: "#282c34" }}>
        <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
      </div>
    </div>
      </Layout>
    </div>
  );
};
