import React, { Fragment } from "react";
import { CDBEditableTable, CDBCard, CDBCardBody, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const EditableTable = () => {

  const columns = ["Fullname", "Age", "Company Name", "City", "Country"];

  const data = [
    ["Guerra Cortez", 45, "Insectus", "San Francisco", "USA"],
    ["Elisa Gallagher", 31, "Portica", "London", "United Kingdom"],
    ["Aurelia Vega", 30, "Deepends", "Madrid", "Spain"],
    ["Guadalupe House", 26, "Isotronic", "Berlin", "Germany"]
  ];

  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div
        style={{
          margin: "auto",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <h2>Editable Table <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></h2>
        <CDBCard>
          <CDBCardBody>
            <CDBEditableTable striped bordered data={data} columns={columns}/>
          </CDBCardBody>
        </CDBCard>
      </div>
    </div>
      </Layout>
    </div>
  );
};
