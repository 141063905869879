import React, { Fragment } from "react";
import { CDBListGroup, CDBListGroupItem, CDBBadge, CDBIcon } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const ListGroup = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div>
        <div className="blockcode">
          <div className="header">Default List Style</div>
          <div className="description">Default List Style</div>
          <div className="example">
            <CDBListGroup style={{ width: "30rem" }}>
              <CDBListGroupItem>List Group </CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
              <CDBListGroupItem>List Group</CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">List Group as Link</div>
          <div className="description">List Group as Link</div>
          <div className="example">
            <CDBListGroup style={{ width: "30rem" }}>
              <CDBListGroupItem href="#" active>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem href="#" hover>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem href="#" hover>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem href="#" hover>
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem href="#" hover>
                List Group Link
              </CDBListGroupItem>
              <CDBListGroupItem href="#" hover>
                List Group
              </CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Label</div>
          <div className="description">Label</div>
          <div className="example">
            <CDBListGroup style={{ width: "30rem" }}>
              <CDBListGroupItem className="d-flex justify-content-between align-items-center">
                List Group <CDBBadge color="primary">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem className="d-flex justify-content-between align-items-center">
                List Group
                <CDBBadge color="secondary">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem className="d-flex justify-content-between align-items-center">
                List Group
                <CDBBadge color="success">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem className="d-flex justify-content-between align-items-center">
                List Group
                <CDBBadge color="danger">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem className="d-flex justify-content-between align-items-center">
                List Group<CDBBadge color="info">19</CDBBadge>
              </CDBListGroupItem>
              <CDBListGroupItem className="d-flex justify-content-between align-items-center">
                List Group<CDBBadge color="warning">19</CDBBadge>
              </CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Color ListGroup</div>
          <div className="description"> Color ListGroup</div>
          <div className="example">
            <CDBListGroup style={{ width: "30rem" }}>
              <CDBListGroupItem color="primary">List Group </CDBListGroupItem>
              <CDBListGroupItem color="secondary">List Group</CDBListGroupItem>
              <CDBListGroupItem color="success">List Group</CDBListGroupItem>
              <CDBListGroupItem color="danger">List Group</CDBListGroupItem>
              <CDBListGroupItem color="info">List Group Link</CDBListGroupItem>
              <CDBListGroupItem color="warning">List Group</CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex">Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Icons</div>
          <div className="example">
            <CDBListGroup style={{ width: "30rem" }}>
              <CDBListGroupItem>
                <CDBIcon fab icon="facebook" className="mr-3" />
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon fab icon="facebook" size="2x" className="mr-3" />
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon fab icon="facebook" size="2x" className="mr-3" />
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon fab icon="facebook" size="2x" className="mr-3" />
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon fab icon="facebook" size="2x" className="mr-3" />
                List Group
              </CDBListGroupItem>
              <CDBListGroupItem>
                <CDBIcon fab icon="facebook" size="2x" className="mr-3" />
                List Group
              </CDBListGroupItem>
            </CDBListGroup>
          </div>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
