import React from "react";
import { Header } from "./Navbar.style";
import { CDBNavbar, CDBInput, CDBIcon, CDBBtn, CDBPaneImage } from "cdbreact";

const Navbar = ({ bg, color }) => {
  return (
    <Header bg={bg} color={color}>
      <CDBNavbar className="justify-content-between" dark expand="md" scrolling>
        <CDBInput
          type="search"
          size="md"
          placeholder="Search"
          className="mb-n4 mt-n3 input-nav"
          placeholder="Searh..."
        />
        <div className="ml-auto d-flex">
          <div className="d-flex align-items-center">
            <CDBIcon fas icon="moon" className="mr-2" />
            <CDBIcon fas icon="bell" className="space" />
            <CDBIcon fas icon="comment-alt" />
            <CDBBtn flat color="dark" className="px-2 mx-1" style={{ color }}>
              Hello, <span style={{ fontWeight: "600" }}>user</span>
            </CDBBtn>
          </div>
          <CDBPaneImage size="sm" src="/img/pages/Pane.png" />
        </div>
      </CDBNavbar>
    </Header>
  );
};

export default Navbar;
