import React from "react";
import {
  CDBLink,
  CDBIcon,
  CDBCard,
  CDBBtn,
  CDBInput,
  CDBCardBody,
  CDBNavbar,
  CDBNavBrand,
} from "cdbreact";
import "./SignUp.css";

export const SignUp = () => {
  return (
    <div className="signUp">
      <div className="position-relative">
        <img alt="Background" src="/img/pages/signIn.png" className="image" />
      </div>

      <div className="body">
        <CDBNavbar
          className="navigation bg-transparent"
          light
          expand="md"
          scrolling
        >
          <CDBNavBrand href="/" className="d-flex align-items-center">
            <img alt="logo" src="/img/pages/logo.png" width="25px" />
            <span className="ml-3 h4 my-0 font-weight-bold">Devwares</span>
          </CDBNavBrand>
        </CDBNavbar>
        <CDBCard className="shadow-lg form">
          <CDBCardBody
            className="mx-auto py-5 w-75"
            style={{ minWidth: "250px" }}
          >
            <div className="mt-4 mb-5">
              <p className="h4 font-weight-bold">Sign up</p>
            </div>
            <CDBInput
              id="defaultRegisterUsername"
              background
              placeholder="Username"
              className="mt-n3"
              type="text"
            />
            <CDBInput
              id="defaultRegisterPassword"
              background
              placeholder="Password"
              className="mt-n3"
              type="password"
            />
            <CDBBtn color="dark" className="btn-block w-100 mb-3 mt-5">
              Sign up
            </CDBBtn>
            <p className="text-muted text-center">
              By Clicking sign up, you agree to the Contrast{" "}
              <CDBLink
                style={{ fontWeight: "600" }}
                className="d-inline p-0 text-dark"
                to="#"
              >
                terms and conditions
              </CDBLink>
            </p>
            <div className="d-flex align-items-center">
              <span style={{ border: "0.5px solid black", width: "45%" }} />
              <span className="text-center" style={{ width: "10%" }}>
                OR
              </span>
              <span style={{ border: "0.5px solid black", width: "45%" }} />
            </div>
            <div className="my-3 d-flex justify-content-center">
              <CDBBtn flat color="dark">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn color="dark" flat className="mx-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark">
                <CDBIcon fab icon="google-plus-g" />
              </CDBBtn>
            </div>
            <p className="text-muted text-center">
              Have an Account?{" "}
              <CDBLink
                style={{ fontWeight: "600" }}
                className="d-inline p-0 text-dark"
                to="#"
              >
                Sign In
              </CDBLink>
            </p>
          </CDBCardBody>
        </CDBCard>
      </div>
    </div>
  );
};
