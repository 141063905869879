import React, { Fragment } from "react";
import { CDBRadio, CDBRadioGroup, CDBBadge} from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const Radio = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header d-flex">Single Radio Example <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description"></div>
        <div className="example2">
          <CDBRadio
            value="Choice 1"
            fill="#eb7434"
            colorfill="#eb7434"
          ></CDBRadio>
          <CDBRadio value="Choice 2" fill="pink" colorfill="red"></CDBRadio>
          <CDBRadio
            value="Choice 3"
            fill="#333"
            colorfill="#000"
          ></CDBRadio>
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Radio Group <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description"></div>
        <div className="example2">
          <CDBRadioGroup
            fill="#eb7434"
            colorfill="#eb7434"
            answers={["Choice 1", "Choice 2", "Choice 3", "Choice 4"]}
          ></CDBRadioGroup>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
