import React, { Component } from "react";
import { CDBView, CDBMask } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export class Mask extends Component {
  render() {
    return (
      <div className="d-flex w-100">
        <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
        </div>
        <Layout>
        <TemplateNav/>
      <div classname="r-w">
        <div className="blockcode">
          <div className="header">Mask patterns</div>
          <div className="description">Mask patterns</div>
          <div className="example2">
            <CDBView>
              <img
                src="img/food.jpg"
                className="img-fluid"
                alt=""
                style={{ width: "100%" }}
              />
              <CDBMask pattern="pattern1" className="flex-center" />
            </CDBView>
            <CDBView>
              <img
                src="img/food.jpg"
                className="img-fluid"
                alt=""
                style={{ width: "100%" }}
              />
              <CDBMask pattern="pattern2" className="flex-center" />
            </CDBView>
            <CDBView>
              <img
                src="img/food.jpg"
                className="img-fluid"
                alt=""
                style={{ width: "100%" }}
              />
              <CDBMask pattern="pattern3" className="flex-center" />
            </CDBView>
            <CDBView>
              <img
                src="img/food.jpg"
                className="img-fluid"
                alt=""
                style={{ width: "100%" }}
              />
              <CDBMask pattern="pattern4" className="flex-center" />
            </CDBView>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Mask Light Overlays</div>
          <div className="description">Mask Light Overlays</div>
          <div className="example2">
            <CDBView>
              <img
                src="img/food.jpg"
                className="img-fluid"
                alt=""
                style={{ width: "100%" }}
              />
              <CDBMask overlay="blueLight" className="flex-center" />
            </CDBView>
            <CDBView>
              <img
                src="img/food.jpg"
                className="img-fluid"
                alt=""
                style={{ width: "100%" }}
              />
              <CDBMask overlay="redLight" className="flex-center" />
            </CDBView>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Mask Strong Overlays</div>
          <div className="description">Mask Strong Overlays</div>
          <div className="example2">
            <CDBView>
              <img
                src="img/food.jpg"
                className="img-fluid"
                alt=""
                style={{ width: "100%" }}
              />
              <CDBMask overlay="blueStrong" className="flex-center" />
            </CDBView>
            <CDBView>
              <img
                src="img/food.jpg"
                className="img-fluid"
                alt=""
                style={{ width: "100%" }}
              />
              <CDBMask overlay="redStrong" className="flex-center" />
            </CDBView>
          </div>
        </div>
      </div>
      </Layout>
    </div>
    );
  }
}
