import React, { useState } from "react";
import {
  CDBBtn,
  CDBIcon,
  CDBInput,
  CDBContainer,
  CDBView,
  CDBIframe,
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBLink,
  CDBCollapse,
} from "cdbreact";
import "./Landing.css";

export const Landing = () => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="landing">
      <div className="landing-bg">
        <img
          alt="landing-bg"
          src="/img/pages/landingRectangle.png"
          className="page-bg"
        />
      </div>
      <div className="page">
        <header className="page-header">
          <CDBNavbar
            className="navigation bg-transparent p-0"
            expand="md"
            dark
            scrolling
          >
            <CDBNavBrand href="/">
              <img alt="logo" src="/img/pages/logo.png" width="25px" />
            </CDBNavBrand>
            <CDBNavToggle
              onClick={() => {
                setCollapse(!collapse);
              }}
            />
            <CDBCollapse
              id="navbarCollapse1"
              delay="0"
              isOpen={collapse}
              navbar
            >
              <CDBNavbarNav left>
                <CDBNavItem active>
                  <CDBBtn flat className="p-2 border-0 bg-transparent">
                    <CDBNavLink to="#">Home</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-2 border-0 bg-transparent">
                    <CDBNavLink to="#">Resources</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-2 border-0 bg-transparent">
                    <CDBNavLink to="#">Blog</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-2 border-0 bg-transparent">
                    <CDBNavLink to="#">Contact</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-2 border-0 bg-transparent">
                    <CDBNavLink to="#">Support</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
              </CDBNavbarNav>
              <CDBNavbarNav right className="align-items-center">
                <CDBNavItem>
                  <CDBBtn flat className="p-2 border-0 bg-transparent">
                    <CDBNavLink to="#">Sign In</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn
                    flat
                    outline
                    className="my-2 py-1 px-3 border-white bg-transparent"
                  >
                    <CDBNavLink to="#">Sign Up</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
              </CDBNavbarNav>
            </CDBCollapse>
          </CDBNavbar>
        </header>
        <div className="page-body">
          <section className="page-head">
            <img alt="landing" src="/img/pages/landing.png" className="image" />
            <div className="page-info">
              <p className="page-title">Creativity is but skin deep</p>
              <p className="my-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi,
                veritatis totam voluptas nostrum quisquam eum porro a pariatur
                accusamus veniam.
              </p>
              <CDBBtn
                flat
                style={{ background: "#8080ff", border: "2px #8080ff solid" }}
              >
                Start Now
              </CDBBtn>
            </div>
          </section>
          <section className="section1 center">
            <div className="section-info">
              <p className="section-title">Our Product Features</p>
              <p className="section-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi,
                veritatis totam voluptas nostrum quisquam eum porro a pariatur
                accusamus veniam.
              </p>
            </div>
            <div className="features">
              <div className="feature">
                <CDBIcon far size="4x" icon="handshake" />
                <p className="fw-600">Feature 1</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Fugit, error amet numquam iure provident voluptate esse quasi,
                  veritatis totam voluptas nostrum quisquam eum porro a pariatur
                  accusamus veniam.
                </p>
              </div>
              <div className="feature">
                <CDBIcon fab size="4x" icon="sketch" />
                <p className="fw-600">Feature 2</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Fugit, error amet numquam iure provident voluptate esse quasi,
                  veritatis totam voluptas nostrum quisquam eum porro a pariatur
                  accusamus veniam.
                </p>
              </div>
              <div className="feature">
                <CDBIcon fas size="4x" icon="sliders-h" />
                <p className="fw-600">Feature 3</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Fugit, error amet numquam iure provident voluptate esse quasi,
                  veritatis totam voluptas nostrum quisquam eum porro a pariatur
                  accusamus veniam.
                </p>
              </div>
            </div>
          </section>
          <section className="section2 center">
            <div className="section-info">
              <p className="section-title">Our Pricing</p>
              <p className="section-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi,
                veritatis totam voluptas nostrum quisquam eum porro a pariatur
                accusamus veniam.
              </p>
            </div>
            <div className="my-5">
              <CDBContainer className="pricing-container shadow-sm bg-light">
                <div className="price-info">
                  <p className="h2 font-weight-bold">$0</p>
                  <p className="fw-600">Free</p>
                  <p>Great for personal use and for your side projects.</p>
                </div>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <div className="btn-container">
                  <CDBBtn color="dark" className="w-100">
                    Set Plan
                  </CDBBtn>
                </div>
                <p className="view-details fw-600">View Details</p>
              </CDBContainer>
              <CDBContainer className="pricing-container shadow-sm bg-light">
                <div className="price-info">
                  <p className="h2 font-weight-bold">$0</p>
                  <p className="fw-600">Free</p>
                  <p>Great for personal use and for your side projects.</p>
                </div>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <div className="btn-container">
                  <CDBBtn color="dark" className="w-100">
                    Set Plan
                  </CDBBtn>
                </div>
                <p className="view-details fw-600">View Details</p>
              </CDBContainer>
              <CDBContainer className="pricing-container shadow-sm bg-light">
                <div className="price-info">
                  <p className="h2 font-weight-bold">$0</p>
                  <p className="fw-600">Free</p>
                  <p>Great for personal use and for your side projects.</p>
                </div>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <ul className="list">
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                  <li>30 Templates</li>
                </ul>
                <div className="btn-container">
                  <CDBBtn color="dark" className="w-100">
                    Set Plan
                  </CDBBtn>
                </div>
                <p className="view-details fw-600">View Details</p>
              </CDBContainer>
            </div>
          </section>
          <section className="section3 center">
            <div className="section-info">
              <p className="section-title">Product Highlight</p>
              <p className="section-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi,
                veritatis totam voluptas nostrum quisquam eum porro a pariatur
                accusamus veniam.
              </p>
            </div>
            <CDBView>
              <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
            </CDBView>
          </section>
          <section className="section4">
            <div className="section-info">
              <p className="section-title center">Want to get in touch?</p>
            </div>
            <div className="contact">
              <CDBContainer className="contact-form">
                <CDBInput
                  id="contactName"
                  placeholder="Name"
                  background
                  className="mt-n3"
                  type="text"
                />
                <CDBInput
                  id="contactEmail"
                  placeholder="Email Address"
                  background
                  className="mt-n3"
                  type="email"
                />
                <CDBInput
                  id="contactMessage"
                  height="150px"
                  placeholder="Type Message"
                  background
                  className="mt-n3"
                  type="textarea"
                />
                <CDBBtn color="dark" className="btn-block">
                  Send Message
                </CDBBtn>
              </CDBContainer>
              <CDBContainer className="contact-info">
                <p className="h2 mb-5">Contact Information</p>
                <p>
                  <CDBIcon className="me-5" icon="map-marker-alt" />
                  New York, 94126 USA
                </p>
                <p>
                  <CDBIcon className="me-5" icon="phone-alt" />+ 01 234 567 89
                </p>
                <p>
                  <CDBIcon className="me-5" icon="comment-alt" />
                  contact@example.com
                </p>
              </CDBContainer>
            </div>
          </section>
        </div>
        <footer className="footer">
          <CDBLink href="/" className="footer-logo">
            <img alt="logo" src="/img/pages/logo.png" width="25px" />
            <span className="mb-0 ms-4 h5 font-weight-bold">Devwares</span>
          </CDBLink>
          <small>&copy; Devwares, 2020. All rights reserved.</small>
          <div className="footer-contact d-flex">
            <CDBBtn flat color="dark" className="p-2">
              <CDBIcon fab icon="facebook-f" />
            </CDBBtn>
            <CDBBtn flat color="dark" className="p-2 mx-3">
              <CDBIcon fab icon="twitter" />
            </CDBBtn>
            <CDBBtn flat color="dark" className="p-2">
              <CDBIcon fab icon="instagram" />
            </CDBBtn>
          </div>
        </footer>
      </div>
    </div>
  );
};
