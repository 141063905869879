import React, { Fragment, useState } from "react";
import { 
  CDBModal,
  CDBModalHeader,
  CDBModalFooter,
  CDBModalBody,
  CDBBtn } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Modal = () => {
  const [state, setState] = useState({
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    modal6: false,
    modal7: false,
    modal8: false,
    modal9: false,
  });
  const toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    setState({
      ...state,
      [modalNumber]: !state[modalNumber],
    });
  };
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header">Custom Modal</div>
        <div className="description">custom Modal</div>
        <div className="example">
          <CDBBtn onClick={toggle(1)} color="dark">
            Modal
          </CDBBtn>
          <CDBModal isOpen={state.modal1} toggle={toggle(1)} centered fade>
            <div className="border d-flex flex-column">
              <img alt="modalImage" className="img-fluid" src="img/modal.jpg" />
              <div className="p-3">
                <h4>Title</h4>
                <p>
                  Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla
                  orcane faucibus ex, non facilisis nisl. Maexenas aliquet
                  mauris ut tempus.
                </p>
                <div className="d-flex">
                  <div
                    className="d-flex justify-content-start"
                    style={{ flex: " 50%" }}
                  >
                    <CDBBtn color="light" flat>
                      Label
                    </CDBBtn>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ flex: " 50%" }}
                  >
                    <CDBBtn color="white" flat onClick={toggle(1)}>
                      Cancel
                    </CDBBtn>
                    <CDBBtn color="dark" flat>
                      Done
                    </CDBBtn>
                  </div>
                </div>
              </div>
            </div>
          </CDBModal>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Modal Sizes</div>
        <div className="description">Modal Sizes</div>
        <div className="example">
          <div>
            <CDBBtn color="light" flat onClick={toggle(2)}>
              Medium modal
            </CDBBtn>
            <CDBModal isOpen={state.modal2} toggle={toggle(2)}>
              <div  className="border d-flex flex-column">
                <img alt="modalImage" className="img-fluid" src="img/modal.jpg" />
                <div className="p-3">
                  <h4>Title</h4>
                  <p>
                    Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla
                    orcane faucibus ex, non facilisis nisl. Maexenas aliquet
                    mauris ut tempus.
                  </p>
                  <div className="d-flex">
                    <div
                      className="d-flex justify-content-start"
                      style={{ flex: " 50%" }}
                    >
                      <CDBBtn color="light" flat>
                        Label
                      </CDBBtn>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ flex: " 50%" }}
                    >
                      <CDBBtn color="white" flat onClick={toggle(2)}>
                        Cancel
                      </CDBBtn>
                      <CDBBtn color="dark" flat>
                        Done
                      </CDBBtn>
                    </div>
                  </div>
                </div>
              </div>
            </CDBModal>
            <CDBBtn color="light" flat onClick={toggle(3)}>
              Small modal
            </CDBBtn>
            <CDBModal isOpen={state.modal3} toggle={toggle(3)} size="sm">
              <div className="border d-flex flex-column">
                <img alt="modalImage" className="img-fluid" src="img/modal.jpg" />
                <div className="p-3">
                  <h4>Title</h4>
                  <p>
                    Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla
                    orcane faucibus ex, non facilisis nisl. Maexenas aliquet
                    mauris ut tempus.
                  </p>
                  <div className="d-flex">
                    <div
                      className="d-flex justify-content-start"
                      style={{ flex: " 50%" }}
                    >
                      <CDBBtn color="light" flat>
                        Label
                      </CDBBtn>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ flex: " 50%" }}
                    >
                      <CDBBtn color="white" flat onClick={toggle(3)}>
                        Cancel
                      </CDBBtn>
                      <CDBBtn color="dark" flat>
                        Done
                      </CDBBtn>
                    </div>
                  </div>
                </div>
              </div>
            </CDBModal>
            <CDBBtn color="light" flat onClick={toggle(4)}>
              Large modal
            </CDBBtn>
            <CDBModal isOpen={state.modal4} toggle={toggle(4)} size="lg">
              <div className="border d-flex flex-column">
                <div className="p-3">
                  <h4>Title</h4>
                  <p>
                    Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla
                    orcane faucibus ex, non facilisis nisl. Maexenas aliquet
                    mauris ut tempus.
                  </p>
                  <div className="d-flex">
                    <div
                      className="d-flex justify-content-start"
                      style={{ flex: " 50%" }}
                    >
                      <CDBBtn color="light" flat>
                        Label
                      </CDBBtn>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ flex: " 50%" }}
                    >
                      <CDBBtn color="white" flat onClick={toggle(4)}>
                        Cancel
                      </CDBBtn>
                      <CDBBtn color="dark" flat>
                        Done
                      </CDBBtn>
                    </div>
                  </div>
                </div>
              </div>
            </CDBModal>
            <CDBBtn color="light" flat onClick={toggle(5)}>
              Fluid modal
            </CDBBtn>
            <CDBModal isOpen={state.modal5} toggle={toggle(5)} size="fluid">
              <div className="border d-flex flex-column">
                <div className="p-3">
                  <h4>Title</h4>
                  <p>
                    Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla
                    orcane faucibus ex, non facilisis nisl. Maexenas aliquet
                    mauris ut tempus.
                  </p>
                  <div className="d-flex">
                    <div
                      className="d-flex justify-content-start"
                      style={{ flex: " 50%" }}
                    >
                      <CDBBtn color="light" flat>
                        Label
                      </CDBBtn>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ flex: " 50%" }}
                    >
                      <CDBBtn color="white" flat onClick={toggle(5)}>
                        Cancel
                      </CDBBtn>
                      <CDBBtn color="dark" flat>
                        Done
                      </CDBBtn>
                    </div>
                  </div>
                </div>
              </div>
            </CDBModal>
          </div>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Modal Positions</div>
        <div className="description">Modal Positions</div>
        <div className="example">
          <CDBBtn color="dark" flat onClick={toggle(6)}>
            Top right
          </CDBBtn>
          <CDBModal
            isOpen={state.modal6}
            toggle={toggle(6)}
            side
            position="top-right"
          >
            <CDBModalHeader toggle={toggle(6)}>Title</CDBModalHeader>
            <CDBModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </CDBModalBody>
            <CDBModalFooter>
              <CDBBtn color="dark" flat onClick={toggle(6)}>
                Close
              </CDBBtn>
              <CDBBtn color="light" flat>
                Save changes
              </CDBBtn>
            </CDBModalFooter>
          </CDBModal>
          <CDBBtn color="dark" flat onClick={toggle(7)}>
            Bottom left
          </CDBBtn>
          <CDBModal
            isOpen={state.modal7}
            toggle={toggle(7)}
            side
            position="bottom-left"
          >
            <CDBModalHeader toggle={toggle(7)}>Title</CDBModalHeader>
            <CDBModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </CDBModalBody>
            <CDBModalFooter>
              <CDBBtn color="dark" flat onClick={toggle(7)}>
                Close
              </CDBBtn>
              <CDBBtn color="light" flat>
                Save changes
              </CDBBtn>
            </CDBModalFooter>
          </CDBModal>
          <CDBBtn color="dark" flat onClick={toggle(8)}>
            Top Left
          </CDBBtn>
          <CDBModal
            isOpen={state.modal8}
            toggle={toggle(8)}
            side
            position="top-left"
          >
            <CDBModalHeader toggle={toggle(8)}>Title</CDBModalHeader>
            <CDBModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </CDBModalBody>
            <CDBModalFooter>
              <CDBBtn color="dark" flat onClick={toggle(8)}>
                Close
              </CDBBtn>
              <CDBBtn color="light" flat>
                Save changes
              </CDBBtn>
            </CDBModalFooter>
          </CDBModal>
          <CDBBtn color="light" flat onClick={toggle(9)}>
            Bottom right
          </CDBBtn>
          <CDBModal
            isOpen={state.modal9}
            toggle={toggle(9)}
            side
            position="bottom-right"
          >
            <CDBModalHeader toggle={toggle(9)}>Title</CDBModalHeader>
            <CDBModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </CDBModalBody>
            <CDBModalFooter>
              <CDBBtn color="dark" onClick={toggle(6)}>
                Close
              </CDBBtn>
              <CDBBtn color="light" flat>
                Save changes
              </CDBBtn>
            </CDBModalFooter>
          </CDBModal>
          <CDBBtn color="light" flat onClick={toggle(9)}>
            Center
          </CDBBtn>
          <CDBModal isOpen={state.modal9} toggle={toggle(9)} side>
            <CDBModalHeader toggle={toggle(9)}>Title</CDBModalHeader>
            <CDBModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </CDBModalBody>
            <CDBModalFooter>
              <CDBBtn color="dark" onClick={toggle(6)}>
                Close
              </CDBBtn>
              <CDBBtn color="light" flat>
                Save changes
              </CDBBtn>
            </CDBModalFooter>
          </CDBModal>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
