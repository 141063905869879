import React, { Fragment } from "react";
import { CDBNotification, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Notification = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header d-flex">Custom Notification Types <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Custom Notification Types</div>
        <div className="example3">
          <CDBNotification
            show
            fade
            autohide={2}
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
          <CDBNotification
            show
            fade
            iconClassName="text-primary"
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
          <CDBNotification
            show
            fade
            iconClassName="text-secondary"
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Custom Notification Types <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Notification with Hidden Message</div>
        <div className="example3">
          <CDBNotification
            show
            fade
            hideMessage
            autohide={5}
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
          <CDBNotification
            show
            fade
            hideMessage
            iconClassName="text-success"
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
          <CDBNotification
            show
            fade
            hideMessage
            iconClassName="text-warning"
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Change Color of IconBox <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Change Color of IconBox</div>
        <div className="example3">
          <CDBNotification
            show
            fade
            color="success"
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
          <CDBNotification
            show
            fade
            color="danger"
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />

          <CDBNotification
            show
            fade
            color="secondary"
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
          <CDBNotification
            show
            fade
            color="primary"
            title="Contrast"
            message="Hello, world! This is a primary message."
            text="11 mins ago"
          />
        </div>
      </div>
      <div>
        <div className="blockcode">
          <div className="header d-flex">Notification with Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Notification with Icons</div>
          <div className="example3">
            <CDBNotification
              show
              fade
              title="Contrast"
              icon="award"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            />
            <CDBNotification
              show
              fade
              iconClassName="text-primary"
              title="Contrast"
              icon="bus"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            />

            <CDBNotification
              show
              fade
              iconClassName="text-danger"
              title="Contrast"
              icon="burn"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            />
            <CDBNotification
              show
              fade
              iconClassName="text-success"
              icon="car-crash"
              title="Contrast"
              message="Hello, world! This is a primary message."
              text="11 mins ago"
            />
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex">Positioning <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Top Left, Bottom Right</div>
          <div className="example4">
            <CDBNotification
              show
              fade
              title="Contrast"
              message="Hello, world! This is a positioned notification."
              text="11 mins ago"
              position="top-left"
            />
            <CDBNotification
              show
              fade
              title="Contrast"
              message="Hello, world! This is a positioned notification."
              text="11 mins ago"
              position="bottom-right"
            />
          </div>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
