import React, { useState } from "react";
import { 
  CDBNavbar,
  CDBNavBrand,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBIcon,
  CDBBtn,
  CDBCollapse,
  CDBCardTitle,
  CDBCardText } from "cdbreact";
import "./Hero4.css";

export const Hero4 = () => {

  const [collapse, setCollapse] = useState(false);

	return(
		<div className="hero4">
			<header className="navigation">
        <CDBNavbar className="px-0 bg-transparent" light expand="md" scrolling>
          <CDBNavBrand className="navbar-expand-md mx-auto" href="/">
            <img alt="logo" src="/img/pages/logo.png" width="25px" className="navbar-toggler p-0 border-0" />
          </CDBNavBrand>
          <CDBNavToggle
            onClick={() => {
              setCollapse(!collapse);
            }}
          />
          <CDBCollapse id="navbarCollapse1" isOpen={collapse} navbar>
            <ul className="navbar-nav mx-auto">
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Resources</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Blog</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
	            <CDBNavBrand href="/" className="my-auto mx-0 px-4 inner-logo">
	              <img alt="logo" src="/img/pages/logo.png" width="25px"/>
	            </CDBNavBrand>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Contact</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Support</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </ul>
          </CDBCollapse>
        </CDBNavbar>
      </header>
			<div className="page-body">
				<CDBCardTitle className="h1 font-weight-bold">Introducing Contrast UI Kit</CDBCardTitle>
				<CDBCardText className="my-4 mx-auto" style={{maxWidth:"550px"}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</CDBCardText>
				<CDBBtn flat className="m-auto" style={{background:"#8080ff", border:"2px #8080ff solid"}}>Explore</CDBBtn>
				<CDBIcon fas className="d-block mt-4" icon="angle-double-down"/>
				<img alt="sideImage" src="/img/pages/promotionImage.png" className="image"/>
			</div>
		</div>
	);
}
