import styled from "styled-components";

export const StyledLayout = styled.div`
  width: 100%;
  // padding:0 10%;
  // margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;

  .r-w {
    width: 90%;
    margin: 0 auto;
  }

  .blockcode {
    margin: 20px 0;
    padding: 20px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 12%), 0 2px 10px rgb(0 0 0 / 8%);
  }
  .header {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
    color: rgb(54, 49, 49);
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    display: flex;

    align-items: center;
    a {
      text-decoration: none;
      & > * {
        padding: 10px !important;
        margin-left: 10px !important;
        font-size: 14px !important;
        border-radius: 4px !important;
      }
    }
  }
  .description {
    font-size: 15px;
    margin: 10px 0;
  }
  .example {
    border: 1px solid #f4f4f4;
    margin: 0 20px;
    display: flex;
    padding: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    & > * {
      margin: 15px;
    }
  }
  .example2 {
    // border: 1px solid #f4f4f4;
    margin: 0 20px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > * {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 15px 0;
    }
  }
  @media (max-width: 768px) {
    // padding:0 2%;
    .blockcode {
      padding: 10px;
    }
    .r-w {
      width: 95%;
    }
    .example2 {
      margin: 0px;
      & > * {
        margin: 0px;
      }
    }
  }
  .example3 {
    border: 1px solid #f4f4f4;
    margin: 0 20px;
    display: block;
    padding: 20px;
  }
  .example4 {
    border: 1px solid #f4f4f4;
    margin: 0 20px;
    display: block;
    padding: 20px;
    height: 300px;
    position: relative;
    background: rgba(0, 0, 0, 0.3);
  }
  .scroll-panel::-webkit-scrollbar {
    display: none !important;
  }

  .db-header nav {
    color: #333;
    background: #fff;
    height: 64px;
  }

  .db-header .input-nav:hover {
    border-color: #333;
  }

  .db-header .input-nav {
    padding: 10px;
    height: 2rem !important;
    background: transparent;
    border: 1px solid rgb(173, 168, 168);
    margin-left: 3rem !important;
    width: 30rem !important;
  }

  .db-header .pane-sm {
    width: 3rem;
    height: 3rem;
  }

  .shadow .breadcrumb {
    border-top: 1px solid rgba(51, 51, 51, 0.2);
  }

  @media (max-width: 900px) {
    .db-header .input-nav {
      margin-left: 2rem !important;
      width: 15rem !important;
    }
  }

  @media (max-width: 768px) {
    .db-header .input-nav {
      margin-left: 0.5rem !important;
      width: 8rem !important;
    }
  }

  @media (max-width: 376px) {
    .db-header .input-nav {
      display: none;
    }
  }
`;
