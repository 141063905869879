import React from "react";
import {
  CDBInput,
  CDBIcon,
  CDBPaneImage,
  CDBPanel,
  CDBPane,
  CDBPanelTitle,
  CDBPanelText,
  CDBBtn,
  CDBLink,
  CDBFooter,
  CDBBox,
} from "cdbreact";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./ChatUI.css";

export function ChatUi() {
  return (
    <div className="chatui d-flex" style={{ height: "100vh" }}>
      <Sidebar bgColor="#333" color="#fff" />
      <div style={{ height: "100%", width: "100%", lineHeight: "2em" }}>
        <Navbar bg="#333" color="#fff" />
        <div style={{ height: "calc(100vh - 65px)", overflowY: "scroll" }}>
          <div className="d-flex w-100 panel-container">
            <CDBPanel className="scroll-panel panel1">
              <CDBPane
                style={{
                  color: "#fff",
                  backgroundColor: "#333333",
                  padding: "15px",
                }}
                className="mt-3 d-flex justify-content-between"
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane1.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", color: "#fff" }}>
                      Online
                    </span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span
                      style={{
                        fontSize: "1.4em",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane3.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane4.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane5.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane1.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane5.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane3.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane4.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane5.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane1.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane4.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane3.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
              <CDBPane
                className="message-card d-flex justify-content-between"
                style={{ padding: "15px" }}
              >
                <CDBBox className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane4.png"
                    style={{ minWidth: "2.5em" }}
                    size="sm"
                  />
                  <div className="ms-3">
                    <CDBPanelTitle>Mezue</CDBPanelTitle>
                    <p className="small mb-0">Hey, how are you.</p>
                  </div>
                </CDBBox>
                <div className="d-flex flex-column ms-auto">
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em" }}>Online</span>
                  </CDBPanelText>
                  <CDBPanelText
                    style={{ fontSize: "0.5em" }}
                    className="px-3 text-muted"
                  >
                    <span style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                      19:32
                    </span>
                  </CDBPanelText>
                </div>
              </CDBPane>
            </CDBPanel>
            <CDBPanel className="scroll-panel panel2">
              <div
                style={{ overflowY: "scroll", overflowX: "hidden" }}
                className="scroll-panel"
              >
                <div className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane1.png"
                    className="me-3 mt-5 pane-image"
                  />
                  <CDBPane
                    style={{
                      alignSelf: "start",
                      width: "60%",
                      color: "#333333",
                      backgroundColor: "#f2f2f2",
                      padding: "15px",
                      position: "relative",
                    }}
                    className="mt-4"
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "-38px",
                        zIndex: "100",
                        display: "block",
                        width: "8px",
                        height: "13px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 8 13"
                        width="50"
                        height="50"
                      >
                        <path
                          opacity=".13"
                          fill="#f4f4f4"
                          d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                        ></path>
                        <path
                          fill="#f4f4f4"
                          d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                        ></path>
                      </svg>
                    </span>
                    <div className="ms-3">
                      <CDBPanelText small>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Fugit, error amet numquam iure provident voluptate
                        esse quasi.
                      </CDBPanelText>
                      <CDBPanelText
                        style={{
                          fontSize: "0.5em",
                          display: "flex",
                          justifyContent: "end",
                        }}
                        className="mt-3 text-muted"
                      >
                        <span
                          style={{
                            fontSize: "1.4em",
                            fontWeight: "bold",
                            color: "#333",
                            marginLeft: "auto",
                          }}
                        >
                          19:32
                        </span>
                      </CDBPanelText>
                    </div>
                  </CDBPane>
                </div>
                <CDBPane
                  style={{
                    alignSelf: "end",
                    width: "60%",
                    color: "#fff",
                    backgroundColor: "#333333",
                    padding: "15px",
                    marginLeft: "auto",
                    marginRight: "30px",
                    position: "relative",
                  }}
                  className="mt-4"
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "2px",
                      zIndex: "100",
                      display: "block",
                      width: "8px",
                      height: "13px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 8 13"
                      width="50"
                      height="50"
                    >
                      <path
                        opacity=".13"
                        d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                      ></path>
                      <path
                        fill="#333333"
                        d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                      ></path>
                    </svg>
                  </span>
                  <div className="ms-3">
                    <CDBPanelText small>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Fugit, error amet numquam iure provident voluptate esse
                      quasi.
                    </CDBPanelText>
                    <CDBPanelText
                      style={{ fontSize: "0.5em" }}
                      className="mt-3 text-muted"
                    >
                      <span
                        style={{
                          fontSize: "1.4em",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        19:32
                      </span>
                    </CDBPanelText>
                  </div>
                </CDBPane>
                <div className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane1.png"
                    style={{ position: "" }}
                    className="me-3 mt-5 pane-image"
                  />
                  <CDBPane
                    style={{
                      alignSelf: "start",
                      width: "60%",
                      color: "#333333",
                      backgroundColor: "#f2f2f2",
                      padding: "15px",
                      position: "relative",
                    }}
                    className="mt-4"
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "-38px",
                        zIndex: "100",
                        display: "block",
                        width: "8px",
                        height: "13px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 8 13"
                        width="50"
                        height="50"
                      >
                        <path
                          opacity=".13"
                          fill="#f4f4f4"
                          d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                        ></path>
                        <path
                          fill="#f4f4f4"
                          d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                        ></path>
                      </svg>
                    </span>
                    <div className="ms-3">
                      <CDBPanelText small>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Fugit, error amet numquam iure provident voluptate
                        esse quasi.
                      </CDBPanelText>
                      <CDBPanelText
                        style={{
                          fontSize: "0.5em",
                          display: "flex",
                          justifyContent: "end",
                        }}
                        className="mt-3 text-muted"
                      >
                        <span
                          style={{
                            fontSize: "1.4em",
                            fontWeight: "bold",
                            color: "#333",
                            marginLeft: "auto",
                          }}
                        >
                          19:32
                        </span>
                      </CDBPanelText>
                    </div>
                  </CDBPane>
                </div>
                <CDBPane
                  style={{
                    alignSelf: "end",
                    width: "60%",
                    color: "#fff",
                    backgroundColor: "#333333",
                    padding: "15px",
                    marginLeft: "auto",
                    marginRight: "30px",
                    position: "relative",
                  }}
                  className="mt-4"
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "2px",
                      zIndex: "100",
                      display: "block",
                      width: "8px",
                      height: "13px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 8 13"
                      width="50"
                      height="50"
                    >
                      <path
                        opacity=".13"
                        d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                      ></path>
                      <path
                        fill="#333333"
                        d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                      ></path>
                    </svg>
                  </span>
                  <div className="ms-3">
                    <CDBPanelText small>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Fugit, error amet numquam iure provident voluptate esse
                      quasi.
                    </CDBPanelText>
                    <CDBPanelText
                      style={{ fontSize: "0.5em" }}
                      className="mt-3 text-muted"
                    >
                      <span
                        style={{
                          fontSize: "1.4em",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        19:32
                      </span>
                    </CDBPanelText>
                  </div>
                </CDBPane>
                <CDBPane
                  style={{
                    alignSelf: "end",
                    width: "60%",
                    color: "#fff",
                    backgroundColor: "#333333",
                    padding: "15px",
                    marginLeft: "auto",
                    marginRight: "30px",
                    position: "relative",
                  }}
                  className="mt-4"
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "2px",
                      zIndex: "100",
                      display: "block",
                      width: "8px",
                      height: "13px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 8 13"
                      width="50"
                      height="50"
                    >
                      <path
                        opacity=".13"
                        d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                      ></path>
                      <path
                        fill="#333333"
                        d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                      ></path>
                    </svg>
                  </span>
                  <div className="ms-3">
                    <CDBPanelText small>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Fugit, error amet numquam iure provident voluptate esse
                      quasi.
                    </CDBPanelText>
                    <CDBPanelText
                      style={{ fontSize: "0.5em" }}
                      className="mt-3 text-muted"
                    >
                      <span
                        style={{
                          fontSize: "1.4em",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        19:32
                      </span>
                    </CDBPanelText>
                  </div>
                </CDBPane>
                <div className="d-flex">
                  <CDBPaneImage
                    src="/img/pane/pane1.png"
                    className="me-3 mt-5 pane-image"
                  />
                  <CDBPane
                    style={{
                      alignSelf: "start",
                      width: "60%",
                      color: "#333333",
                      backgroundColor: "#f2f2f2",
                      padding: "15px",
                      position: "relative",
                    }}
                    className="mt-4"
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "-38px",
                        zIndex: "100",
                        display: "block",
                        width: "8px",
                        height: "13px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 8 13"
                        width="50"
                        height="50"
                      >
                        <path
                          opacity=".13"
                          fill="#f4f4f4"
                          d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                        ></path>
                        <path
                          fill="#f4f4f4"
                          d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                        ></path>
                      </svg>
                    </span>
                    <div className="ms-3">
                      <CDBPanelText small>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Fugit, error amet numquam iure provident voluptate
                        esse quasi.
                      </CDBPanelText>
                      <CDBPanelText
                        style={{
                          fontSize: "0.5em",
                          display: "flex",
                          justifyContent: "end",
                        }}
                        className="mt-3 text-muted"
                      >
                        <span
                          style={{
                            fontSize: "1.4em",
                            fontWeight: "bold",
                            color: "#333",
                            marginLeft: "auto",
                          }}
                        >
                          19:32
                        </span>
                      </CDBPanelText>
                    </div>
                  </CDBPane>
                </div>
              </div>
              <div className="d-flex mt-5">
                <div className="d-flex mx-auto">
                  <CDBInput width="40vw" label="Type Message" />
                  <CDBBtn
                    color="dark"
                    style={{
                      width: "80px",
                      maxHeight: "50px",
                      margin: "21px 0",
                    }}
                  >
                    Send
                  </CDBBtn>
                </div>
              </div>
            </CDBPanel>
          </div>
          <CDBFooter>
            <div
              className="d-flex justify-content-between align-items-center mx-auto py-4"
              style={{ width: "80%" }}
            >
              <CDBLink
                to="/"
                className="d-flex align-items-center p-0 text-dark footer-rem"
              >
                <img alt="logo" src="/img/pages/logo.png" width="25px" />
                <span className="ms-4 h5 font-weight-bold">Devwares</span>
              </CDBLink>
              <small>&copy; Devwares, 2020. All rights reserved.</small>
              <div className="footer-rem d-flex">
                <CDBBtn flat className="p-1 bg-dark border-0">
                  <CDBIcon fab icon="facebook-f" />
                </CDBBtn>
                <CDBBtn flat className="mx-3 p-1 bg-dark border-0">
                  <CDBIcon fab icon="twitter" />
                </CDBBtn>
                <CDBBtn flat className="p-1 bg-dark border-0">
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
              </div>
            </div>
          </CDBFooter>
        </div>
      </div>
    </div>
  );
}
