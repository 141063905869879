import React, { useState } from "react";
import { 
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBIcon,
  CDBBtn,
  CDBCollapse,
  CDBCardTitle,
  CDBCardText } from "cdbreact";
import "./Hero.css";

export const Hero = () => {

  const [collapse, setCollapse] = useState(false);

	return(
		<div className="hero">
			<header className="navigation">
        <CDBNavbar className="px-0 bg-transparent" light expand="md" scrolling>
          <CDBNavBrand href="/">
            <img alt="logo" src="/img/pages/logo.png" width="25px"/>
          </CDBNavBrand>
          <CDBNavToggle
            onClick={() => {
              setCollapse(!collapse);
            }}
          />
          <CDBCollapse id="navbarCollapse1" isOpen={collapse} navbar>
            <CDBNavbarNav left>
              <CDBNavItem active>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Home</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Resources</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Blog</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Contact</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Support</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
            <CDBNavbarNav right>
              <CDBNavItem active>
                <CDBBtn flat outline style={{width:"120px"}} className="py-1 m-0 border-dark bg-transparent">
                  <CDBNavLink to="#">Sign In</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
          </CDBCollapse>
        </CDBNavbar>
      </header>
	    <div className="page-body">
				<div className="page-info">
					<CDBCardTitle className="h1 font-weight-bold" style={{maxWidth:"350px"}}>Creativity is but skin deep</CDBCardTitle>
					<CDBCardText className="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</CDBCardText>
					<CDBBtn flat style={{background:"#8080ff", border:"2px #8080ff solid"}}>Start Now</CDBBtn>
				</div>
				<div className="image-container">
					<img alt="sideImage" className="image" src="/img/pages/heroImage3.png" />
					<div className="btn-container">
						<CDBBtn color="dark" className="mr-1"><CDBIcon fas icon="arrow-left"/></CDBBtn>
						<CDBBtn color="dark"><CDBIcon fas icon="arrow-right"/></CDBBtn>
					</div>
				</div>
			</div>
		</div>
	);
}
