import React, { Fragment } from "react";
import { 
  CDBInput,
  CDBCard,
  CDBCardTitle,
  CDBCardText,
  CDBBtn,
  CDBLink,
  CDBSelect,
  CDBCardBody,
  CDBIcon,
  CDBBadge,
  CDBMask } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Forms = () => {
    const option = [
    {
      text: "Feature request",
      value: "1",
    },
    {
      text: "Report a bug",
      value: "2",
    },
    {
      text: "Help",
      value: "3",
      icon:"question-circle",
    },
  ];

  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div style={{ display: "grid", gridTemplateColumns: "repeat(1, minmax(20rem, 30rem))", margin:"0 auto" }}>
        <div className="blockcode">
          <div className="header">Login/Sign in Form</div>
          <div className="description">Default form login</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4"> Sign in </p>
                </div>
                <CDBInput material placeholder="E-mail" type="email" />
                <CDBInput material placeholder="Password" type="password" />
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                  <CDBInput type="checkbox" id="sign-in-checkbox"/>
                  <p className="m-0">Remember me</p>
                  <CDBLink to="#">Forgot Password ?</CDBLink>
                </div>
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 my-3 mx-0" >
                    Sign in
                </CDBBtn>
                <p className="text-center">Not a member? <CDBLink className="d-inline p-0" to="#">Register</CDBLink></p>
                <p className="text-center"> or sign in with</p>
                <div className="flex-row  my-3 d-flex justify-content-center">
                  <CDBBtn
                    color="white"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-twitter"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-google-plus-g"></i>
                  </CDBBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="description">Contrast Form Login</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div style={{ background:"black" }} className="text-center text-white">
                <p className="h5 mt-2 py-4 font-weight-bold">Sign in</p>
              </div>
              <div className="mx-4 p-3">
                <CDBInput label="Email" type="email"/>
                <CDBInput label="Password" type="password"/>
                <div className="mt-5 d-flex flex-wrap justify-content-center align-items-center">
                  <CDBInput type="checkbox" className="mr-2"/>
                  <p className="m-0">Remember me</p>
                  <CDBLink to="#">Forgot Password ?</CDBLink>
                </div>
                <CDBBtn
                  color="dark"
                  outline
                  className="btn-block w-100 my-3 mx-0" >
                    Sign in
                </CDBBtn>
                <p className="text-center">Not a member? <CDBLink className="d-inline p-0" to="#">Register</CDBLink></p>
                <p className="text-center"> or sign in with</p>
                <div className="flex-row  my-3 d-flex justify-content-center">
                  <CDBBtn
                    color="white"
                    className="m-0"
                    outline
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    outline
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-twitter"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    outline
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    outline
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-github"></i>
                  </CDBBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Register/Sign up Form</div>
          <div className="description">Default form register</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4"> Sign up </p>
                </div>
                <div className="form-row mb-n4">
                  <div className="col"><CDBInput material placeholder="First name" type="text" /></div>
                  <div className="col"><CDBInput material placeholder="Last name" type="text" /></div>
                </div>
                <CDBInput material placeholder="E-mail" type="email" />
                <p className="text-muted text-center small mt-n4">At least 8 characters and 1 digit</p>
                <CDBInput material placeholder="Password" type="password" />
                <CDBInput material placeholder="Phone number" type="text" />
                <p className="text-muted text-center small mt-n4">Optional - for two step authentication</p>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <CDBInput type="checkbox" />
                  <p className="m-0">Subscribe to our newsletter</p>
                </div>
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 my-3 mx-0" >
                    Sign up
                </CDBBtn>
                <p className="text-center"> or sign up with</p>
                <div className="row mb-3 d-flex justify-content-center">
                  <CDBBtn
                    color="white"
                    className="m-0"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-twitter"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-google-plus-g"></i>
                  </CDBBtn>
                </div>
                  <p className="text-center m-0">Already have an account? <CDBLink className="d-inline p-0" to="#">Sign In</CDBLink></p>
                <hr/>
                <p className="text-center">By clicking <em>Sign up</em> you agree to our <CDBLink className="d-inline p-0" to="#">terms of service</CDBLink></p>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="description">Contrast form register</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="text-center text-white" style={{background:"black"}}>
                <p className="h5 mt-2 py-4 font-weight-bold"> Sign up </p>
              </div>
              <div className="mx-4 p-3">
                <div className="form-row mb-n4">
                  <div className="col"><CDBInput label="First name" type="text" /></div>
                  <div className="col"><CDBInput label="Last name" type="text" /></div>
                </div>
                <CDBInput label="E-mail" type="email" />
                <p className="text-muted text-center small mt-n4">At least 8 characters and 1 digit</p>
                <CDBInput label="Password" type="password" />
                <CDBInput label="Phone number" type="text" />
                <p className="text-muted text-center small mt-n4">Optional - for two step authentication</p>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <CDBInput type="checkbox" className="mr-2"/>
                  <p className="m-0">Subscribe to our newsletter</p>
                </div>
                <CDBBtn
                  color="dark"
                  outline
                  className="btn-block w-100 my-3 mx-0" >
                    Sign up
                </CDBBtn>
                <p className="text-center"> or sign up with</p>
                <div className="flex-row  mb-3 d-flex justify-content-center">
                  <CDBBtn
                    color="white"
                    className="m-0"
                    outline
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    outline
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-twitter"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    outline
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="white"
                    className="m-0"
                    outline
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-github"></i>
                  </CDBBtn>
                </div>
                <p className="text-center m-0">Already have an account? <CDBLink className="d-inline p-0" to="#">Sign In</CDBLink></p>
                <hr/>
                <p className="text-center">By clicking <em>Sign up</em> you agree to our <CDBLink className="d-inline p-0" to="#">terms of service</CDBLink></p>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Subscription Form</div>
          <div className="description">Default form Subscription</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4">Subscribe</p>
                </div>
                <p className="text-center mt-4 font-weight-light">Join our mailing list. We write rarely, but only the best content.</p>
                <CDBLink className="text-center p-0 font-weight-light" to="#">See the last newsletter</CDBLink>
                <CDBInput material placeholder="Name" type="text" />
                <CDBInput material placeholder="E-mail" type="email" />
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 my-3 mx-0" >
                    Subscribe
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="description">Contrast form Subscription</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="text-center text-white" style={{ background: "black" }}>
                <p className="h5 mt-2 py-4 font-weight-bold">Subscribe</p>
              </div>
              <div className="mx-4 p-3">
                <p className="text-center mt-2">Join our mailing list. We write rarely, but only the best content.</p>
                <CDBLink className="text-center p-0" to="#">See the last newsletter</CDBLink>
                <CDBInput label="Name" type="text" />
                <CDBInput label="E-mail" type="email" />
                <CDBBtn
                  color="dark"
                  outline
                  className="btn-block w-100 my-3 mx-0" >
                    Subscribe
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Contact Form</div>
          <div className="description">Default form contact</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4"> Contact us </p>
                </div>
                <CDBInput material placeholder="Name" type="text" />
                <CDBInput material placeholder="E-mail" type="email" />
                <p className="text-center m-0">Subject</p>
                <CDBSelect options={option} selected="Feedback"/>
                <CDBInput placeholder="Message" type="textarea" />
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <CDBInput material type="checkbox" />
                  <p className="m-0">Send me a copy of this message</p>
                </div>
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 my-3 mx-0" >
                    Send
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="description">Contrast form contact</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div style={{ background:"black" }} className="text-center text-white">
                <p className="h5 mt-2 py-4 font-weight-bold">Contact Us</p>
              </div>
              <div className="mx-4 p-3">
                <CDBInput label="Name" type="text" />
                <CDBInput label="E-mail" type="email" />
                <p className="text-center m-0 text-muted">Subject</p>
                <CDBSelect color="white" options={option} selected="Feedback"/>
                <CDBInput label="Message" type="textarea" />
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <CDBInput type="checkbox" />
                  <p className="m-0">Send me a copy of this message</p>
                </div>
                <CDBBtn
                  color="dark"
                  outline
                  className="btn-block w-100 my-3 mx-0" >
                    Send
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Login/Sign in Form</div>
          <div className="description">Login form with icon</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4 font-weight-bold"> Sign in </p>
                </div>
                <CDBInput label="E-mail" type="email" icon="envelope" iconClass="text-muted"/>
                <CDBInput label="Password" type="password" icon="lock" iconClass="text-muted"/>
                <CDBBtn
                  color="success"
                  style={{width:"40%"}}
                  className="btn-block  mt-5 mx-auto">
                    Login
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Login/Sign in Form</div>
          <div className="description">Login form with outside label</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-4">
                  <p className="h4"> Sign in </p>
                </div>
                <label htmlFor="defaultLoginEmail" className="text-muted m-0">
                  Your email
                </label>
                <CDBInput id="defaultLoginEmail" className="mt-n3" type="email" />
                <label htmlFor="defaultLoginPassword" className="text-muted m-0">
                  Your password
                </label>
                <CDBInput id="defaultLoginPassword" className="mt-n3" type="password" />
                <CDBBtn
                  color="primary"
                  style={{width:"40%"}}
                  className="btn-block mt-5 mx-auto" >
                    Login
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Form Register</div>
          <div className="description">Sign Up form with icon</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4 font-weight-bold"> Sign up </p>
                </div>
                <CDBInput label="Your name" type="text" icon="user" iconClass="text-muted"/>
                <CDBInput label="Your email" type="email" icon="envelope" iconClass="text-muted"/>
                <CDBInput label="Confirm your email" type="email" icon="exclamation-triangle" iconClass="text-muted"/>
                <CDBInput label="Your Password" type="password" icon="lock" iconClass="text-muted"/>
                <CDBBtn
                  color="primary"
                  style={{width:"40%"}}
                  className="btn-block mt-5 mx-auto" >
                    Register
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Form Regiser</div>
          <div className="description">Sign Up form outside label</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4"> Sign up </p>
                </div>
                <label htmlFor="defaultRegisterName" className="text-muted m-0">
                  Your name
                </label>
                <CDBInput id="defaultRegisterName" className="mt-n3" type="text" />
                <label htmlFor="defaultRegisterEmail" className="text-muted m-0">
                  Your email
                </label>
                <CDBInput id="defaultRegisterEmail" className="mt-n3" type="email" />
                <label htmlFor="defaultRegisterEmailConfirm" className="text-muted m-0">
                  Confirm your email
                </label>
                <CDBInput id="defaultRegisterEmailConfirm" className="mt-n3" type="email" />
                <label htmlFor="defaultRegisterPassword" className="text-muted m-0">
                  Your password
                </label>
                <CDBInput id="defaultRegisterPassword" className="mt-n3" type="password"/>
                <CDBBtn
                  color="danger"
                  style={{width:"40%"}}
                  className="btn-block mt-5 mx-auto" >
                    Register
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Form Subscription</div>
          <div className="description">Subscription form with icon</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4 font-weight-bold"> Subscribe </p>
                </div>
                <CDBInput label="Your name" type="text" icon="user" iconClass="text-muted"/>
                <CDBInput label="Your email" type="email" icon="envelope" iconClass="text-muted"/>
                <CDBBtn
                  outline
                  color="primary"
                  style={{width:"40%"}}
                  className="btn-block mt-5 mx-auto" >
                  Send
                  <i className="far fa-paper-plane"></i>
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Form Subscription</div>
          <div className="description">Subscription form with outside label</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4"> Subscribe </p>
                </div>
                <label htmlFor="defaultSubscribeName" className="text-muted m-0">
                  Your name
                </label>
                <CDBInput id="defaultSubscribeName" className="mt-n3" type="text" />
                <label htmlFor="defaultSubscribeEmail" className="text-muted m-0">
                  Your email
                </label>
                <CDBInput id="defaultSubscribeEmail" className="mt-n3" type="email" />
                <CDBBtn
                  outline
                  color="danger"
                  style={{width:"40%"}}
                  className="btn-block mt-5 mx-auto" >
                  Send
                  <i className="far fa-paper-plane"></i>
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Form Contact</div>
          <div className="description">Contact form with icon</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4 font-weight-bold"> Write to Us </p>
                </div>
                <CDBInput label="Your name" type="text" icon="user" iconClass="text-muted"/>
                <CDBInput label="Your email" type="email" icon="envelope" iconClass="text-muted"/>
                <CDBInput label="Subject" type="text" icon="tags" iconClass="text-muted"/>
                <CDBInput label="Your message" type="textarea" icon="pencil-alt" iconClass="text-muted"/>
                <CDBBtn
                  outline
                  color="secondary"
                  style={{width:"40%"}}
                  className="btn-block mt-5 mx-auto" >
                  Send
                  <i className="far fa-paper-plane"></i>
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Form Contact</div>
          <div className="description">Contact form with icon</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-4">
                  <p className="h4"> Write to Us </p>
                </div>
                <label htmlFor="defaultContactName" className="text-muted m-0">
                  Your name
                </label>
                <CDBInput id="defaultContactName" className="mt-n3" type="text" />
                <label htmlFor="defaultContactEmail" className="text-muted m-0">
                  Your email
                </label>
                <CDBInput id="defaultContactEmail" className="mt-n3" type="email" />
                <label htmlFor="defaultContactSubject" className="text-muted m-0">
                  Subject
                </label>
                <CDBInput id="defaultContactSubject" className="mt-n3" type="text" />
                <label htmlFor="defaultContactMessage" className="text-muted m-0">
                  Your message
                </label>
                <CDBInput id="defaultContactMessage" className="mt-n3" type="textarea" />
                <CDBBtn
                  outline
                  color="secondary"
                  style={{width:"40%"}}
                  className="btn-block mt-5 mx-auto" >
                  Send
                  <i className="far fa-paper-plane"></i>
                </CDBBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Login/Sign in Form</div>
          <div className="description">Form login</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4 font-weight-bold"> Sign in </p>
                </div>
                <CDBInput placeholder="E-mail" type="email" />
                <CDBInput placeholder="Password" type="password" />
                <p className="mt-n3 text-end "><CDBLink className="p-0" to="#">Forgot Password ?</CDBLink></p>
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 my-4 mx-0" >
                    Sign in
                </CDBBtn>
                <p className="text-center"> or sign in with</p>
                <div className="flex-row  my-3 d-flex justify-content-center">
                  <CDBBtn
                    color="dark"
                    className="m-0"
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="dark"
                    className="m-0"
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-twitter"></i>
                  </CDBBtn>
                  <CDBBtn
                    color="dark"
                    className="m-0"
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-google-plus-g"></i>
                  </CDBBtn>
                </div>
                <hr/>
                <p className="text-center">Not a member? <CDBLink className="d-inline p-0" to="#">Sign up</CDBLink></p>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Login/Sign in Form</div>
          <div className="description">Sign Up</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div className="mx-4 p-3">
                <div className="text-center mt-4 mb-2">
                  <p className="h4"> Sign up </p>
                </div>
                <CDBInput label ="E-mail" type="email" />
                <CDBInput label ="Password" type="password" />
                <div className="d-flex align-items-center">
                  <CDBInput type="checkbox" className="mr-2"/>
                  <p className="mb-0 ml-2">Accept the <CDBLink className="d-inline p-0" to="#">terms and conditions</CDBLink></p>
                </div>
                <div className="d-flex mt-4 align-items-center">
                  <CDBBtn
                    
                    style={{width:"30%", background:"linear-gradient(0deg, rgba(152,117,196,1) 0%, rgba(169,208,213,0.8746849081429446) 100%)"}}
                    className="btn-block mx-0" >
                      Sign up
                  </CDBBtn>
                  <p className="ml-auto mb-0">Have an account? <CDBLink className="d-inline p-0" to="#">Log in</CDBLink></p>
                </div>
              </div>
              <div className="pt-4" style={{background:"linear-gradient(0deg, rgba(152,117,196,1) 0%, rgba(169,208,213,0.8746849081429446) 100%)"}}>
                <p className="text-center m-0"> or sign up with</p>
                <div className="flex-row  mb-3 d-flex justify-content-center">
                  <CDBBtn
                    
                    className="m-0"
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </CDBBtn>
                  <CDBBtn
                    
                    className="m-0"
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-twitter"></i>
                  </CDBBtn>
                  <CDBBtn
                    
                    className="m-0"
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-google-plus-g"></i>
                  </CDBBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="description">Login form</div>
          <div className="example2">
            <div className="border d-flex flex-column" style={{ width: "30rem" }}>
              <div style={{ background:"linear-gradient(0deg, rgba(152,117,196,1) 0%, rgba(169,208,213,0.8746849081429446) 100%)" }} className="text-center">
                <p className="h5 mt-2 mt-5 mb-2 font-weight-bold">Login</p>
                <div className="flex-row  mb-3 d-flex justify-content-center">
                  <CDBBtn
                    
                    className="m-0"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </CDBBtn>
                  <CDBBtn
                    
                    className="m-0"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-twitter"></i>
                  </CDBBtn>
                  <CDBBtn
                    
                    className="m-0"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </CDBBtn>
                  <CDBBtn
                    
                    className="m-0"
                    flat
                    style={{width:"initial"}}
                  >
                    <i className="fab fa-github"></i>
                  </CDBBtn>
                </div>
              </div>
              <div className="mx-4 p-3">
                <CDBInput label="E-mail" type="email" />
                <CDBInput label="Password" type="password" />
                <p className="text-end ">Forgot <CDBLink className="d-inline p-0 ml-auto" to="#">Password ?</CDBLink></p>
                <CDBBtn
                
                  style={{width:"40%", background:"linear-gradient(0deg, rgba(152,117,196,1) 0%, rgba(169,208,213,0.8746849081429446) 100%)"}}
                  className="btn-block my-3 mx-0 font-weight-bold" >
                    Login
                </CDBBtn>
                <p className=" text-end mt-4">Don't have an account? <CDBLink className="d-inline p-0" to="#">Sign Up</CDBLink></p>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Login/Sign in Form</div>
          <div className="description">Sign Up with background</div>
          <div className="example2">
            <div className="border d-flex flex-column position-relative" style={{ width: "30rem", background:"url(https://images.unsplash.com/photo-1528287942171-fbe365d1d9ac?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&w=1200&cs=srgb&ixid=eyJh)", backgroundSize:"cover"}}>
              <CDBMask overlay="darkStrong" className="flex-center" />
              <div className="mx-4 p-3" style={{zIndex:"2"}}>
                <div className="text-center mt-5 mb-2">
                  <p className="h4 font-weight-bold text-white"> Sign up </p>
                </div>
                <CDBInput material label ="E-mail" type="email" />
                <CDBInput material label ="Password" type="password" />
                <div className="d-flex mt-3 align-items-center justify-content-center">
                  <CDBInput type="checkbox"/>
                  <p className="text-white mb-0 ml-2">Accept the <CDBLink className="d-inline p-0" to="#">terms and conditions</CDBLink></p>
                </div>
                <CDBBtn
                  color="primary"
                  className="btn-block mx-0 my-4" >
                    Sign up
                </CDBBtn>
                <p className="text-white text-center mb-5">Have an account? <CDBLink className="d-inline p-0" to="#">Log in</CDBLink></p>
              </div>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex">Sign In forms <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Sign In Form in card</div>
          <div className="example2">
            <CDBCard className="shadow">
              <CDBCardBody className="p-5">
                <div className="mt-4 mb-5">
                  <CDBCardTitle className="h2 font-weight-bold">Sign up</CDBCardTitle>
                </div>
                <label htmlFor="defaultRegisterUsername" className="text-muted m-0">
                  Username
                </label>
                <CDBInput id="defaultRegisterUsername" background placeholder="Username" className="mt-n3" type="text" />
                <label htmlFor="defaultRegisterPassword" className="text-muted m-0">
                  Password
                </label>
                <CDBInput id="defaultRegisterPassword" background placeholder="Password" className="mt-n3" type="password"/>
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 mb-3 mt-5" >
                    Sign up
                </CDBBtn>
                <p className="text-muted">By Clicking sign up, you agree to the Contrast <CDBLink style={{fontWeight:"600"}} className="d-inline p-0 text-dark" to="#">terms and conditions</CDBLink></p>
                <div className="d-flex align-items-center">
                  <span style={{border:"0.5px solid black", width:"45%"}}/>
                  <span className="text-center" style={{width:"10%"}}>OR</span>
                  <span style={{border:"0.5px solid black", width:"45%"}}/>
                </div>
                <div className="flex-row  my-3 d-flex justify-content-center">
                  <CDBBtn
                    flat
                    color="dark"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="facebook-f" />
                  </CDBBtn>
                  <CDBBtn
                    flat
                    color="dark"
                    className="mx-2"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="twitter" />
                  </CDBBtn>
                  <CDBBtn
                    flat
                    color="dark"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="google-plus-g"/>
                  </CDBBtn>
                </div>
                <p className="text-muted text-center">Have an Account? <CDBLink style={{fontWeight:"600"}} className="d-inline p-0 text-dark" to="#">Sign In</CDBLink></p>
              </CDBCardBody>
            </CDBCard>
            <CDBCard className="shadow mt-4">
              <CDBCardBody className="p-5">
                <div className="my-4 d-flex align-items-center">
                  <div>
                    <CDBCardTitle className="h4 mt-0 font-weight-bold">Sign up</CDBCardTitle>
                    <CDBCardText className="m-0" style={{fontWeight:"600"}}>Let's get started</CDBCardText>
                  </div>
                  <CDBBtn outline color="dark" className="ml-auto px-4" style={{fontWeight:"600"}}>Sign Up</CDBBtn>
                </div>
                <label htmlFor="defaultRegisterUsername" className="text-muted m-0">
                  Username
                </label>
                <CDBInput id="defaultRegisterUsername" background placeholder="Username" className="mt-n3" type="text" />
                <label htmlFor="defaultRegisterPassword" className="text-muted m-0">
                  Password
                </label>
                <CDBInput id="defaultRegisterPassword" background placeholder="Password" className="mt-n3" type="password"/>
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 mb-3 mt-5" >
                    Sign up
                </CDBBtn>
                <p className="text-muted">By Clicking sign up, you agree to the Contrast <CDBLink style={{fontWeight:"600"}} className="d-inline p-0 text-dark" to="#">terms and conditions</CDBLink></p>
                <div className="d-flex align-items-center">
                  <span style={{border:"0.5px solid black", width:"45%"}}/>
                  <span className="text-center" style={{width:"10%"}}>OR</span>
                  <span style={{border:"0.5px solid black", width:"45%"}}/>
                </div>
                <div className="flex-row  my-3 d-flex justify-content-center">
                  <CDBBtn
                    flat
                    color="dark"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="facebook-f" />
                  </CDBBtn>
                  <CDBBtn
                    flat
                    color="dark"
                    className="mx-2"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="twitter" />
                  </CDBBtn>
                  <CDBBtn
                    flat
                    color="dark"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="google-plus-g"/>
                  </CDBBtn>
                </div>
                <p className="text-muted text-center">Have an Account? <CDBLink style={{fontWeight:"600"}} className="d-inline p-0 text-dark" to="#">Sign In</CDBLink></p>
              </CDBCardBody>
            </CDBCard>
          </div>
          <div className="example2">
            <CDBCard className="shadow">
              <CDBCardBody className="p-5">
                <div className="mt-4 mb-5">
                  <CDBCardTitle className="h2 font-weight-bold">Sign in</CDBCardTitle>
                </div>
                <label htmlFor="defaultRegisterUsername" className="text-muted m-0">
                  Username
                </label>
                <CDBInput id="defaultRegisterUsername" background placeholder="Username" className="mt-n3" type="text" />
                <label htmlFor="defaultRegisterPassword" className="text-muted m-0">
                  Password
                </label>
                <CDBInput id="defaultRegisterPassword" background placeholder="Password" className="mt-n3" type="password"/>
                <CDBLink style={{fontWeight:"600"}} className="d-inline p-0 text-dark" to="#">Forgot Password?</CDBLink>
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 mb-3 mt-5" >
                    Sign in
                </CDBBtn>
                <div className="d-flex align-items-center">
                  <span style={{border:"0.5px solid #555", width:"45%"}}/>
                  <span className="text-center" style={{width:"10%"}}>OR</span>
                  <span style={{border:"0.5px solid #555", width:"45%"}}/>
                </div>
                <div className="flex-row  my-3 d-flex justify-content-center">
                  <CDBBtn
                    flat
                    color="dark"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="facebook-f" />
                  </CDBBtn>
                  <CDBBtn
                    color="dark"
                    flat
                    className="mx-2"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="twitter" />
                  </CDBBtn>
                  <CDBBtn
                    flat
                    color="dark"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="google-plus-g"/>
                  </CDBBtn>
                </div>
                <p className="text-muted text-center">Don't have an Account? <CDBLink style={{fontWeight:"600"}} className="d-inline p-0 text-dark" to="#">Sign Up</CDBLink></p>
              </CDBCardBody>
            </CDBCard>
            <CDBCard className="shadow mt-4">
              <CDBCardBody className="p-5">
                <div className="my-4 d-flex align-items-center">
                  <div>
                    <CDBCardTitle className="h4 mt-0 font-weight-bold">Sign in</CDBCardTitle>
                    <CDBCardText className="m-0" style={{fontWeight:"600"}}>Welcome Back.</CDBCardText>
                  </div>
                  <CDBBtn outline color="dark" className="ml-auto px-4" style={{fontWeight:"600"}}>Sign Up</CDBBtn>
                </div>
                <label htmlFor="defaultRegisterUsername" className="text-muted m-0">
                  Username
                </label>
                <CDBInput id="defaultRegisterUsername" background placeholder="Username" className="mt-n3" type="text" />
                <label htmlFor="defaultRegisterPassword" className="text-muted m-0">
                  Password
                </label>
                <CDBInput id="defaultRegisterPassword" background placeholder="Password" className="mt-n3" type="password"/>
                <CDBLink style={{fontWeight:"600"}} className="d-inline p-0 text-dark" to="#">Forgot Password?</CDBLink>
                <CDBBtn
                  color="dark"
                  className="btn-block w-100 mb-3 mt-5" >
                    Sign in
                </CDBBtn>
                <div className="d-flex align-items-center">
                  <span style={{border:"0.5px solid #555", width:"45%"}}/>
                  <span className="text-center" style={{width:"10%"}}>OR</span>
                  <span style={{border:"0.5px solid #555", width:"45%"}}/>
                </div>
                <div className="flex-row  my-3 d-flex justify-content-center">
                  <CDBBtn
                    flat
                    color="dark"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="facebook-f" />
                  </CDBBtn>
                  <CDBBtn
                    flat
                    color="dark"
                    className="mx-2"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="twitter" />
                  </CDBBtn>
                  <CDBBtn
                    flat
                    color="dark"
                    style={{width:"initial"}}
                  >
                    <CDBIcon fab icon="google-plus-g"/>
                  </CDBBtn>
                </div>
                <p className="text-muted text-center">Don't have an Account? <CDBLink style={{fontWeight:"600"}} className="d-inline p-0 text-dark" to="#">Sign Up</CDBLink></p>
              </CDBCardBody>
            </CDBCard>
          </div>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
