import React, { Component } from "react";
import { 
  CDBStepper,
  CDBBtn,
  CDBStep,
  CDBInput,
  CDBContainer,
  CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export class Stepper extends Component {
  state = {
    active: 1,
  };

  handleNextPrevClick = (a) => {
    this.setState({
      active: a,
    });
  };

  handleSubmission = () => {
    alert("Form submitted!");
  };
  render() {
    return (
      <div className="d-flex w-100">
        <div style={{height:"100vh", overflow:"hidden initial"}}>
          <Sidebar/>
        </div>
        <Layout>
          <TemplateNav/>
      <div classname="r-w">
        <div className="blockcode">
          <div className="header d-flex">Basic Vertical Stepper <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Basic Vertical Stepper</div>
          <div
            className="example"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <CDBContainer style={{ height: "500px", width: "150px" }}>
              <CDBStepper direction="vertical">
                <CDBStep
                  id={1}
                  far
                  icon="folder-open"
                  name="Basic Information"
                  handleClick={() => this.handleNextPrevClick(1)}
                  active={this.state.active}
                ></CDBStep>
                <CDBStep
                  id={2}
                  icon="pencil-alt"
                  name="Personal Data"
                  handleClick={() => this.handleNextPrevClick(2)}
                  active={this.state.active}
                ></CDBStep>
                <CDBStep
                  id={3}
                  icon="dragon"
                  name="Terms and Conditions"
                  handleClick={() => this.handleNextPrevClick(3)}
                  active={this.state.active}
                ></CDBStep>
                <CDBStep
                  id={4}
                  icon="check"
                  name="Finish"
                  handleClick={() => this.handleNextPrevClick(4)}
                  active={this.state.active}
                ></CDBStep>
              </CDBStepper>
            </CDBContainer>
            <CDBContainer
              style={{
                height: "500px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {this.state.active === 1 && (
                <CDBContainer md="12">
                  <h3
                    className="font-weight-bold pl-0 my-4 "
                    style={{
                      width: "100%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Your Information
                  </h3>
                  <CDBInput label="Email" className="mt-4" />
                  <CDBInput label="username" className="mt-4" />
                  <CDBInput label="Password" className="mt-4" />
                  <CDBInput label="Repeat Password" className="mt-4" />
                  <CDBBtn
                    color="dark"
                    block
                    flat
                    className="float-right"
                    onClick={() => this.handleNextPrevClick(2)}
                  >
                    Next
                  </CDBBtn>
                </CDBContainer>
              )}
              {this.state.active === 2 && (
                <CDBContainer md="12">
                  <h3
                    className="font-weight-bold pl-0 my-4"
                    style={{
                      width: "100%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Personal Data
                  </h3>
                  <CDBInput label="First Name" className="mt-3" />
                  <CDBInput label="Second Name" className="mt-3" />
                  <CDBInput label="Surname" className="mt-3" />
                  <CDBInput label="Address" type="textarea" rows="2" />
                  <CDBBtn
                    color="light"
                    flat
                    className="float-left"
                    onClick={() => this.handleNextPrevClick(1)}
                  >
                    Previous
                  </CDBBtn>
                  <CDBBtn
                    color="dark"
                    flat
                    className="float-right"
                    onClick={() => this.handleNextPrevClick(3)}
                  >
                    Next
                  </CDBBtn>
                </CDBContainer>
              )}
              {this.state.active === 3 && (
                <CDBContainer md="12">
                  <h3
                    className="font-weight-bold pl-0 my-4"
                    style={{
                      width: "100%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Terms and conditions
                  </h3>
                  <CDBInput
                    label="I agreee to the terms and conditions"
                    type="checkbox"
                    id="checkbox3"
                  />
                  <CDBInput
                    label="I want to receive newsletter"
                    type="checkbox"
                    id="checkbox4"
                  />
                  <CDBBtn
                    color="light"
                    className="float-left"
                    flat
                    onClick={() => this.handleNextPrevClick(2)}
                  >
                    Previous
                  </CDBBtn>
                  <CDBBtn
                    color="dark"
                    className="float-right"
                    flat
                    onClick={() => this.handleNextPrevClick(4)}
                  >
                    Next
                  </CDBBtn>
                </CDBContainer>
              )}
              {this.state.active === 4 && (
                <CDBContainer md="12">
                  <h3
                    className="font-weight-bold pl-0 my-4"
                    style={{
                      width: "100%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Finish
                  </h3>
                  <h2 className="text-center font-weight-bold my-4">
                    Registration completed!
                  </h2>
                  <CDBBtn
                    color="light"
                    flat
                    className="float-left"
                    onClick={() => this.handleNextPrevClick(3)}
                  >
                    Previous
                  </CDBBtn>
                  <CDBBtn
                    color="success"
                    flat
                    className="float-right"
                    onClick={this.handleSubmission}
                  >
                    submit
                  </CDBBtn>
                </CDBContainer>
              )}
            </CDBContainer>
          </div>
        </div>
        <div className="blockcode">
          <div className="header  d-flex">Basic Horizontal Stepper <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Basic Horizontal Stepper</div>
          <div
            className="example2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <CDBContainer style={{ width: "100%" }}>
              <CDBStepper direction="horizontal">
                <CDBStep
                  id={1}
                  far
                  icon="folder-open"
                  name="Basic Information"
                  handleClick={() => this.handleNextPrevClick(1)}
                  active={this.state.active}
                ></CDBStep>
                <CDBStep
                  id={2}
                  icon="pencil-alt"
                  name="Personal Data"
                  handleClick={() => this.handleNextPrevClick(2)}
                  active={this.state.active}
                ></CDBStep>
                <CDBStep
                  id={3}
                  icon="dragon"
                  name="Terms and Conditions"
                  handleClick={() => this.handleNextPrevClick(3)}
                  active={this.state.active}
                ></CDBStep>
                <CDBStep
                  id={4}
                  icon="check"
                  name="Finish"
                  handleClick={() => this.handleNextPrevClick(4)}
                  active={this.state.active}
                ></CDBStep>
              </CDBStepper>
            </CDBContainer>
            <CDBContainer
              style={{
                height: "500px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {this.state.active === 1 && (
                <CDBContainer md="12">
                  <h3
                    className="font-weight-bold pl-0 my-4 d-flex justify-content-center"
                    style={{
                      width: "100%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Your Information
                  </h3>
                  <CDBInput label="Email" className="mt-4" />
                  <CDBInput label="username" className="mt-4" />
                  <CDBInput label="Password" className="mt-4" />
                  <CDBInput label="Repeat Password" className="mt-4" />
                  <CDBBtn
                    color="dark"
                    block
                    flat
                    className="float-right"
                    onClick={() => this.handleNextPrevClick(2)}
                  >
                    Next
                  </CDBBtn>
                </CDBContainer>
              )}
              {this.state.active === 2 && (
                <CDBContainer md="12">
                  <h3
                    className="font-weight-bold pl-0 my-4"
                    style={{
                      width: "100%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Personal Data
                  </h3>
                  <CDBInput label="First Name" className="mt-3" />
                  <CDBInput label="Second Name" className="mt-3" />
                  <CDBInput label="Surname" className="mt-3" />
                  <CDBInput label="Address" type="textarea" rows="2" />
                  <CDBBtn
                    color="light"
                    flat
                    className="float-left"
                    onClick={() => this.handleNextPrevClick(1)}
                  >
                    Previous
                  </CDBBtn>
                  <CDBBtn
                    color="dark"
                    flat
                    className="float-right"
                    onClick={() => this.handleNextPrevClick(3)}
                  >
                    Next
                  </CDBBtn>
                </CDBContainer>
              )}
              {this.state.active === 3 && (
                <CDBContainer md="12">
                  <h3
                    className="font-weight-bold pl-0 my-4"
                    style={{
                      width: "100%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Terms and conditions
                  </h3>
                  <CDBInput
                    label="I agreee to the terms and conditions"
                    type="checkbox"
                    id="checkbox3"
                  />
                  <CDBInput
                    label="I want to receive newsletter"
                    type="checkbox"
                    id="checkbox4"
                  />
                  <CDBBtn
                    color="light"
                    className="float-left"
                    flat
                    onClick={() => this.handleNextPrevClick(2)}
                  >
                    Previous
                  </CDBBtn>
                  <CDBBtn
                    color="dark"
                    className="float-right"
                    flat
                    onClick={() => this.handleNextPrevClick(4)}
                  >
                    Next
                  </CDBBtn>
                </CDBContainer>
              )}
              {this.state.active === 4 && (
                <CDBContainer md="12">
                  <h3
                    className="font-weight-bold pl-0 my-4"
                    style={{
                      width: "100%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Finish
                  </h3>
                  <h2 className="text-center font-weight-bold my-4">
                    Registration completed!
                  </h2>
                  <CDBBtn
                    color="light"
                    flat
                    className="float-left"
                    onClick={() => this.handleNextPrevClick(3)}
                  >
                    Previous
                  </CDBBtn>
                  <CDBBtn
                    color="success"
                    flat
                    className="float-right"
                    onClick={this.handleSubmission}
                  >
                    submit
                  </CDBBtn>
                </CDBContainer>
              )}
            </CDBContainer>
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex">Stepper Without Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Stepper Without Icons</div>
          <div className="example">
            <CDBStepper direction="horizontal">
              <CDBStep name="Label 1" id={1}></CDBStep>
              <CDBStep name="Label 2" id={2}></CDBStep>
              <CDBStep name="Label 3" id={3}></CDBStep>
              <CDBStep name="Label 4" id={4}></CDBStep>
            </CDBStepper>
          </div>
        </div>
      </div>
      </Layout>
    </div>
    );
  }
}
