import React, { Fragment } from "react";
import { Routes as RoutesWrapper, Route } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { Tables } from "./pages/Tables";
import { Hero404 } from "./pages/Hero404";
import { ProDashboard } from "./pages/ProDashboard";
import { ProDashboard2 } from "./pages/ProDashboard2";
import { ProDashboard3 } from "./pages/ProDashboard3";
import { ProDashboard4 } from "./pages/ProDashboard4";
import { About } from "./pages/About";
import { About2 } from "./pages/About2";
import { SignUp } from "./pages/SignUp";
import { SignIn } from "./pages/SignIn";
import { ForgotPasswordPopUp } from "./pages/ForgotPasswordPopUp";
import { SignUp2 } from "./pages/SignUp2";
import { SignIn2 } from "./pages/SignIn2";
import { SignIn3 } from "./pages/SignIn3";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ForgotPassword2 } from "./pages/ForgotPassword2";
import { CreateAccount } from "./pages/CreateAccount";
import { Hero } from "./pages/Hero";
import { Hero2 } from "./pages/Hero2";
import { Hero3 } from "./pages/Hero3";
import { Hero4 } from "./pages/Hero4";
import { Hero5 } from "./pages/Hero5";
import { Pricing } from "./pages/Pricing";
import { Post } from "./pages/Post";
import { Landing } from "./pages/Landing";
import { ChatUi } from "./pages/ChatUI";
import { Profile } from "./pages/Profile";
import { ProfileFree } from "./pages/ProfileFree";
import { Accordion } from "./containers/Accordion";
import { Alert } from "./containers/Alert";
import { Animation } from "./containers/Animation";
import { Autocomplete } from "./containers/Autocomplete";
import { Badge } from "./containers/Badge";
import { Box } from "./containers/Box";
import { Breadcrumb } from "./containers/Breadcrumb";
import { Button } from "./containers/Button";
import { ButtonGroup } from "./containers/ButtonGroup";
import { ButtonToolbar } from "./containers/ButtonToolbar";
import { Carousel } from "./containers/Carousel";
import { Card } from "./containers/Card";
import { Checkbox } from "./containers/Checkbox";
import { DropDown } from "./containers/DropDown";
import { DatePicker } from "./containers/DatePicker";
import { EditableTable } from "./containers/EditableTable";
import { FileUploader } from "./containers/FileUploader";
import { Footer } from "./containers/Footer";
import { Forms } from "./containers/Forms";
import { Input } from "./containers/Input";
import { InputGroup } from "./containers/InputGroup";
import { Icon } from "./containers/Icon";
import { Iframe } from "./containers/Iframe";
import { ListGroup } from "./containers/ListGroup";
import { Mask } from "./containers/Mask";
import { Modal } from "./containers/Modal";
import { Multiselect } from "./containers/Multiselect";
import { Navbar } from "./containers/Navbar";
import { Notification } from "./containers/Notification";
import { Pane } from "./containers/Pane";
import { Panel } from "./containers/Panel";
import { Rating } from "./containers/Rating";
import { Radio } from "./containers/Radio";
import { Select } from "./containers/Select";
import { Select2 } from "./containers/Select2";
import { SideBar } from "./containers/Sidebar";
import { Slider } from "./containers/Slider";
import { SmoothScroll } from "./containers/SmoothScroll";
import { Spinner } from "./containers/Spinner";
import { Switch } from "./containers/Switch";
import { Table } from "./containers/Table";
import { Progress } from "./containers/Progress";
import { Pagination } from "./containers/Pagination";
import { Stepper } from "./containers/Stepper";
import { Tabs } from "./containers/Tabs";
import { TimePicker } from "./containers/TimePicker";
import { Chart } from "./containers/Chart";
import { Collapse } from "./containers/Collapse";
import { DataTables } from "./containers/DataTables";

const Routes = () => {
  return (
      <RoutesWrapper>
        <Route exact path="/" element={<Dashboard/>} />
        <Route path="/tables" element={<Tables/>}/>
        <Route path="/hero404" element={<Hero404/>} />
        <Route path="/profilefree" element={<ProfileFree/>} />
        <Route path="/prodashboard" element={<ProDashboard/>} />
        <Route path="/prodashboard2" element={<ProDashboard2/>} />
        <Route path="/prodashboard3" element={<ProDashboard3/>} />
        <Route path="/prodashboard4" element={<ProDashboard4/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/about2" element={<About2/>} />
        <Route path="/signup" element={<SignUp/>} />
        <Route path="/signin" element={<SignIn/>} />
        <Route path="/forgot-password-popup" element={<ForgotPasswordPopUp/>} />
        <Route path="/signup2" element={<SignUp2/>} />
        <Route path="/signin2" element={<SignIn2/>} />
        <Route path="/signin3" element={<SignIn3/>} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/forgot-password2" element={<ForgotPassword2/>} />
        <Route path="/create-account" element={<CreateAccount/>} />
        <Route path="/hero" element={<Hero/>} />
        <Route path="/hero2" element={<Hero2/>} />
        <Route path="/hero3" element={<Hero3/>} />
        <Route path="/hero4" element={<Hero4/>} />
        <Route path="/hero5" element={<Hero5/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="/post" element={<Post/>} />
        <Route path="/landing" element={<Landing/>} />
        <Route path="/chatui" element={<ChatUi/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/accordion" element={<Accordion/>} />
        <Route path="/alert" element={<Alert/>} />
        <Route path="/autocomplete" element={<Autocomplete/>} />
        <Route path="/dropdown" element={<DropDown/>} />
        <Route path="/date-picker" element={<DatePicker/>} />
        <Route path="/editable-table" element={<EditableTable/>}/>
        <Route path="/file-uploader" element={<FileUploader/>} />
        <Route path="/icon" element={<Icon/>} />
        <Route path="/iframe" element={<Iframe/>} />
        <Route path="/multiselect" element={<Multiselect/>} />
        <Route path="/notification" element={<Notification/>} />
        <Route path="/pane" element={<Pane/>} />
        <Route path="/panel" element={<Panel/>} />
        <Route path="/radio" element={<Radio/>} />
        <Route path="/badge" element={<Badge/>} />
        <Route path="/spinner" element={<Spinner/>} />
        <Route path="/button" element={<Button/>} />
        <Route path="/breadcrumb" element={<Breadcrumb/>} />
        <Route path="/button-group" element={<ButtonGroup/>} />
        <Route path="/button-toolbar" element={<ButtonToolbar/>} />
        <Route path="/list-group" element={<ListGroup/>} />
        <Route path="/pagination" element={<Pagination/>} />
        <Route path="/progress" element={<Progress/>} />
        <Route path="/box" element={<Box/>} />
        <Route path="/animation" element={<Animation/>} />
        <Route path="/table" element={<Table/>} />
        <Route path="/carousel" element={<Carousel/>} />
        <Route path="/card" element={<Card/>} />
        <Route path="/checkbox" element={<Checkbox/>} />
        <Route path="/rating" element={<Rating/>} />
        <Route path="/modal" element={<Modal/>} />
        <Route path="/mask" element={<Mask/>} />
        <Route path="/select" element={<Select/>} />
        <Route path="/select2" element={<Select2/>} />
        <Route path="/navbar" element={<Navbar/>} />
        <Route path="/sidebar" element={<SideBar/>} />
        <Route path="/switch" element={<Switch/>} />
        <Route path="/forms" element={<Forms/>} />
        <Route path="/input" element={<Input/>} />
        <Route path="/input-group" element={<InputGroup/>} />
        <Route path="/slider" element={<Slider/>} />
        <Route path="/smooth-scroll" element={<SmoothScroll/>} />
        <Route path="/footer" element={<Footer/>} />
        <Route path="/data-table" element={<DataTables/>} />
        <Route path="/collapse" element={<Collapse/>} />
        <Route path="/stepper" element={<Stepper/>} />
        <Route path="/chart" element={<Chart/>} />
        <Route path="/tabs" element={<Tabs/>} />
        <Route path="/time-picker" element={<TimePicker/>} />
      </RoutesWrapper>
  );
};

export default Routes;
