import React, { Fragment } from "react";

export const TemplateNav = () => {
  return (
    <Fragment>
      <div class="shadow">
        <header class="db-header">
          <nav class="d-flex justify-content-between align-items-center px-3 py-2">
            <div class="cdb-form mb-0">
              <input type="search" placeholder="Search" class="input-nav" />
            </div>
            <div class="ms-auto d-flex align-items-center">
              <div class="d-flex align-items-center me-3">
                <i class="fas fa-bell me-3 fa-lg"></i>
                <i class="fas fa-comment-alt fa-lg"></i>
              </div>
              <img class="pane-sm" alt="" src="/img/pane/pane4.png" />
            </div>
          </nav>
        </header>

        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-flat py-3 bg-white mb-0">
            <li class="breadcrumb-item">Home</li>
            <li class="breadcrumb-item">Library</li>
            <li class="breadcrumb-item active" aria-current="page">
              Data
            </li>
          </ol>
        </nav>
      </div>
    </Fragment>
  );
};
