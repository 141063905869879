import React, { Fragment } from "react";
import { CDBProgress, CDBCircularProgress, CDBBadge} from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Progress = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div>
        <div style={{ margin: "20px 0", width: "100%" }}></div>
        <div className="blockcode">
          <div className="header  d-flex">Circular Progress colors variations <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Circular Progress color variations</div>
          <div className="example">
            <CDBCircularProgress
              value={25}
              max={100}
              min={0}
              text={`${25}%`}
            ></CDBCircularProgress>
            <CDBCircularProgress
              value={50}
              max={100}
              min={0}
              text={`${50}%`}
              color="primary"
            ></CDBCircularProgress>
            <CDBCircularProgress
              value={95}
              max={100}
              min={0}
              color="secondary"
              text={`${95}%`}
            ></CDBCircularProgress>
            <CDBCircularProgress
              value={35}
              max={100}
              min={0}
              color="danger"
              text={`${35}%`}
            ></CDBCircularProgress>
            <CDBCircularProgress
              value={47}
              max={100}
              min={0}
              color="info"
              text={`${47}%`}
            ></CDBCircularProgress>
            <CDBCircularProgress
              value={100}
              max={100}
              min={0}
              color="success"
              text={`${100}%`}
            ></CDBCircularProgress>
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex"> Circular Progress Size variations <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Circular Progress Size variations</div>
          <div className="example">
            <CDBCircularProgress
              value={25}
              max={100}
              min={0}
              text={`${25}%`}
              size="lg"
            ></CDBCircularProgress>
            <CDBCircularProgress
              value={50}
              max={100}
              min={0}
              text={`${50}%`}
              size="sm"
            ></CDBCircularProgress>
            <CDBCircularProgress
              value={95}
              max={100}
              min={0}
              text={`${95}%`}
              size="md"
            ></CDBCircularProgress>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Rect Progress Default </div>
          <div className="description"></div>
          <div className="example2">
            <div>
              <CDBProgress value={69} text={`${69}%`}></CDBProgress>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Rect Progress color variants </div>
          <div className="description"></div>
          <div className="example2">
            <div>
              <CDBProgress
                value={10}
                text={`${10}%`}
                colors="primary"
              ></CDBProgress>
            </div>
            <div>
              <CDBProgress
                value={20}
                text={`${20}%`}
                colors="secondary"
              ></CDBProgress>
            </div>
            <div>
              <CDBProgress
                value={70}
                text={`${70}%`}
                colors="success"
              ></CDBProgress>
            </div>
            <div>
              <CDBProgress
                value={40}
                text={`${40}%`}
                colors="danger"
              ></CDBProgress>
            </div>
            <div>
              <CDBProgress
                value={90}
                text={`${90}%`}
                colors="info"
              ></CDBProgress>
            </div>
            <div>
              <CDBProgress
                value={60}
                text={`${60}%`}
                colors="warning"
              ></CDBProgress>
            </div>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Fixed height</div>
          <div className="description"></div>
          <div className="example2">
            <div>
              <CDBProgress
                value={45}
                colors="dark"
                height={30}
                text={`${45}%`}
              ></CDBProgress>
            </div>
            <div>
              <CDBProgress
                value={60}
                colors="success"
                height={20}
                text={`${60}%`}
              ></CDBProgress>
            </div>
            <div>
              <CDBProgress
                value={80}
                colors="danger"
                height={40}
                text={`${80}%`}
              ></CDBProgress>
            </div>
          </div>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
