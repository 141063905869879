import React, { Fragment } from "react";
import { CDBDropDown, CDBDropDownToggle, CDBDropDownMenu, CDBDropDownItem, CDBLink } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const DropDown = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div
        style={{
          margin: "0 auto",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="blockcode w-100">
          <div className="header">Basic Dropdown</div>
          <div className="description">Dark Toggle Bar</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="dark" caret>
                Dark Toggle Bar
              </CDBDropDownToggle>
              <CDBDropDownMenu>
                This is the toogle menu
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Dropdown</div>
          <div className="description">Primary Toggle Bar With Caret</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="primary" caret dropdown>
                Primary Toggle Bar
              </CDBDropDownToggle>
              <CDBDropDownMenu>
                This is the toogle menu with caret
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Drop right</div>
          <div className="description">Secondary ToggleBar</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="secondary" caret dropright>
                Secondary Toggle Bar as nav
              </CDBDropDownToggle>
              <CDBDropDownMenu dropright>
                This is the toogle menu to the right
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Drop Up</div>
          <div className="description">Danger ToggleBar</div>
          <div className="example">
            <CDBDropDown style={{ margin: "30px 0" }}>
              <CDBDropDownToggle color="danger" caret dropup>
                Danger Toggle Bar
              </CDBDropDownToggle>
              <CDBDropDownMenu dropup>
                This is the toogle menu to the top
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Drop left</div>
          <div className="description">Success Toggle Bar</div>
          <div className="example">
            <CDBDropDown style={{ margin: "30px 0" }}>
              <CDBDropDownToggle color="success" caret dropleft>
                Success Toggle Bar
              </CDBDropDownToggle>
              <CDBDropDownMenu dropleft>
                This is the toogle menu to the bottom
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Size Variation</div>
          <div className="description">Large Dropdown</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="dark" size="lg">
                Large Dark Toggle Bar
              </CDBDropDownToggle>
              <CDBDropDownMenu dropright>
                This is the toogle menu to the right
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Size Variation</div>
          <div className="description">Small Dropdown</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="secondary" size="sm">
                Small Secondary Toggle Bar
              </CDBDropDownToggle>
              <CDBDropDownMenu dropright>
                <CDBDropDownItem header>cold place</CDBDropDownItem>
                <CDBDropDownItem divider />
                <CDBDropDownItem disabled>
                  First Item in cold place
                </CDBDropDownItem>
                <CDBDropDownItem disabled>second</CDBDropDownItem>
                <CDBDropDownItem toggle>second</CDBDropDownItem>
                <CDBDropDownItem>second</CDBDropDownItem>
                <CDBDropDownItem>
                  <CDBLink to="/alert"> Alert</CDBLink>
                </CDBDropDownItem>
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Disabled Dropdown</div>
          <div className="description">Danger Toggle Bar</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="danger" disabled>
                Disabled Danger Toggle Bar
              </CDBDropDownToggle>
              <CDBDropDownMenu dropright>
                This is the toogle menu to the right
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Dropdown Menu</div>
          <div className="description">Menu With Header</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="secondary">
                DropDown
              </CDBDropDownToggle>
              <CDBDropDownMenu dropright>
                <CDBDropDownItem header>Cold place</CDBDropDownItem>
                <CDBDropDownItem>
                  First Item in cold place
                </CDBDropDownItem>
                <CDBDropDownItem toggle>second</CDBDropDownItem>
                <CDBDropDownItem>second</CDBDropDownItem>
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Dropdown Menu</div>
          <div className="description">Menu With Divider</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="warning">
                DropDown
              </CDBDropDownToggle>
              <CDBDropDownMenu dropleft>
                <CDBDropDownItem>
                  <CDBLink to="/alert"> Alert</CDBLink>
                </CDBDropDownItem>
                <CDBDropDownItem divider />
                <CDBDropDownItem>
                  First Item in cold place
                </CDBDropDownItem>
                <CDBDropDownItem toggle>second</CDBDropDownItem>
                <CDBDropDownItem>second</CDBDropDownItem>
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Dropdown Menu</div>
          <div className="description">Disabled Menu Items</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="success">
                DropDown
              </CDBDropDownToggle>
              <CDBDropDownMenu dropup>
                <CDBDropDownItem>Cold place</CDBDropDownItem>
                <CDBDropDownItem disabled>
                  Disabled
                </CDBDropDownItem>
                <CDBDropDownItem disabled>Second</CDBDropDownItem>
                <CDBDropDownItem>Last</CDBDropDownItem>
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
        <div className="blockcode w-100">
          <div className="header">Dropdown Menu</div>
          <div className="description">Disabled Menu Items</div>
          <div className="example">
            <CDBDropDown>
              <CDBDropDownToggle color="dark">
                DropDown
              </CDBDropDownToggle>
              <CDBDropDownMenu dropup>
                <CDBDropDownItem>Cold place</CDBDropDownItem>
                <CDBDropDownItem active>
                  Active
                </CDBDropDownItem>
                <CDBDropDownItem toggle>Second</CDBDropDownItem>
                <CDBDropDownItem>Last</CDBDropDownItem>
              </CDBDropDownMenu>
            </CDBDropDown>
          </div>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
