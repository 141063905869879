import React from "react";
import {
  CDBIcon,
  CDBPaneImage,
  CDBCardTitle,
  CDBPanel,
  CDBCardText,
  CDBBtn,
  CDBCard,
  CDBCardImage,
  CDBCardBody,
  CDBFooter,
  CDBLink,
  CDBInputGroup,
} from "cdbreact";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./Profile.css";

export function Profile() {
  return (
    <div className="d-flex profile" style={{ height: "100vh" }}>
      <Sidebar bgColor="#fff" color="#333" />
      <div className="w-100">
        <Navbar />
        <div
          className="mx-auto"
          style={{
            height: "calc(100vh - 65px)",
            overflowY: "scroll",
            maxWidth: "1320px",
          }}
        >
          <div>
            <div className="panel-container">
              <div className="card-1">
                <CDBCard className="scroll-panel shadow">
                  <CDBCardImage
                    className="img-fluid w-100"
                    src="./img/uber.png"
                  />
                  <CDBPaneImage
                    style={{
                      marginTop: "-5rem",
                      borderStyle: "solid",
                      borderWidth: "4px",
                    }}
                    className="mx-auto border-white"
                    width="130px"
                    src="./img/ellipse.png"
                  />
                  <CDBCardBody className="d-flex flex-column align-items-center mb-4 pt-1">
                    <CDBCardTitle>Chukwudi Mezue</CDBCardTitle>
                    <CDBCardText className="text-muted">
                      Product Designer
                    </CDBCardText>
                    <CDBCardText
                      style={{ color: "#333333", margin: "20px 0 30px" }}
                    >
                      Creating High Quality Resources and tools to Aid
                      developers during the developement of their projects
                    </CDBCardText>
                    <div className="w-100">
                      <CDBBtn className="w-100 mb-0" color="dark">
                        Send Message
                      </CDBBtn>
                    </div>
                  </CDBCardBody>
                </CDBCard>
              </div>
              <CDBPanel className="scroll-panel shadow card-2 mt-1 d-flex flex-column">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#ffffff",
                    padding: "40px 20px 20px",
                  }}
                >
                  <CDBCardText style={{ fontWeight: "bold" }} muted size="lg">
                    Profile
                  </CDBCardText>
                  <CDBInputGroup background label="Name" className="mb-3" />
                  <CDBInputGroup
                    background
                    label="Email Address"
                    type="email"
                    className="mb-3"
                  />
                  <CDBInputGroup background label="Username" className="mb-3" />
                  <CDBInputGroup
                    background
                    label="Phone Number"
                    className="mb-5"
                  />
                  <CDBCardText
                    style={{ fontWeight: "bold" }}
                    className="mb-5"
                    muted
                    size="lg"
                  >
                    Account Security
                  </CDBCardText>
                  <CDBInputGroup
                    background
                    label="Old Password"
                    type="password"
                    className="mb-3"
                  />
                  <CDBInputGroup
                    background
                    label="New Password"
                    type="password"
                    className="mb-5"
                  />
                  <div className="d-flex justify-content-end">
                    <CDBBtn className="mb-0" color="dark">
                      Save Changes
                    </CDBBtn>
                  </div>
                </div>
              </CDBPanel>
            </div>
            <CDBFooter>
              <div
                className="d-flex justify-content-between align-items-center mx-auto py-4"
                style={{ width: "80%" }}
              >
                <CDBLink
                  to="/"
                  className="d-flex align-items-center p-0 text-dark footer-rem"
                >
                  <img alt="logo" src="/img/pages/logo.png" width="25px" />
                  <span className="ml-4 h5 font-weight-bold">Devwares</span>
                </CDBLink>
                <small>&copy; Devwares, 2020. All rights reserved.</small>
                <div className="d-flex footer-rem">
                  <CDBBtn flat className="p-1 bg-dark border-0">
                    <CDBIcon fab icon="facebook-f" />
                  </CDBBtn>
                  <CDBBtn flat className="mx-3 p-1 bg-dark border-0">
                    <CDBIcon fab icon="twitter" />
                  </CDBBtn>
                  <CDBBtn flat className="p-1 bg-dark border-0">
                    <CDBIcon fab icon="instagram" />
                  </CDBBtn>
                </div>
              </div>
            </CDBFooter>
          </div>
        </div>
      </div>
    </div>
  );
}
