import React, { useState } from "react";
import {
  CDBBtn,
  CDBCard,
  CDBCardBody,
  CDBCardImage,
  CDBCardTitle,
  CDBCardText,
  CDBIcon,
  CDBInput,
  CDBPane,
  CDBPaneImage,
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBLink,
  CDBCollapse,
} from "cdbreact";
import "./Post.css";

export const Post = () => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="post">
      <header className="navigation">
        <CDBNavbar className="px-0 bg-transparent" light expand="md" scrolling>
          <CDBNavBrand href="/">
            <img alt="logo" src="/img/pages/logo.png" width="25px" />
          </CDBNavBrand>
          <CDBNavToggle
            onClick={() => {
              setCollapse(!collapse);
            }}
          />
          <CDBCollapse id="navbarCollapse1" isOpen={collapse} navbar>
            <CDBNavbarNav left>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Resources</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Blog</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Contact</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Support</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
          </CDBCollapse>
        </CDBNavbar>
      </header>
      <div className="page-body">
        <img alt="postImage" src="/img/pages/postImage.png" width="100%" />
        <p className="page-title">iPhone 12: Apple makes jump to 5g</p>
        <CDBPane className="mt-3">
          <CDBPaneImage src="/img/pages/pane.png" className="my-auto" />
          <div className="ms-4">
            <CDBCardTitle className="h6 fw-600 my-0">
              By Leo Kelion
            </CDBCardTitle>
            <CDBCardText small>Technology desk editor</CDBCardText>
          </div>
        </CDBPane>
        <section className="section1">
          <main className="main">
            <p className="fw-600">
              Apple has confirmed its iPhone 12 handsets will be its first to
              work on faster 5G networks.
            </p>
            <p>
              The company has also extended the range to include a new "Mini"
              model that has a smaller 5.4in screen.
            </p>
            <p>
              The US firm bucked a wider industry downturn by increasing its
              handset sales over the past year.
            </p>
            <p>
              But some experts say the new features give Apple its best
              opportunity for growth since 2014, when it revamped its line-up
              with the iPhone 6.
            </p>
            <p>
              "5G will bring a new level of performance for downloads and
              uploads, higher quality video streaming, more responsive gaming,
              real-time interactivity and so much more," said chief executive
              Tim Cook.
            </p>
            <p>
              There has also been a cosmetic refresh this time round, with the
              sides of the devices getting sharper, flatter edges.
            </p>
            <img
              alt="postImage"
              src="/img/pages/postImage2.png"
              width="100%"
              className="mb-3"
            />
            <p>
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga. Et harum quidem rerum
              facilis est et expedita distinctio.
            </p>
            <p>
              Nam libero tempore, cum soluta nobis est eligendi optio cumque
              nihil impedit quo minus id quod maxime placeat facere possimus,
              omnis voluptas assumenda est, omnis dolor repellendus. Temporibus
              autem quibusdam et aut officiis debitis aut rerum necessitatibus
              saepe eveniet ut et voluptates repudiandae sint et molestiae non
              recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut
              aut reiciendis voluptatibus maiores alias consequatur aut
              perferendis doloribus.
            </p>
            <p>
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga. Et harum quidem rerum
              facilis est et expedita distinctio.
            </p>
            <p>
              Nam libero tempore, cum soluta nobis est eligendi optio cumque
              nihil impedit quo minus id quod maxime placeat facere possimus,
              omnis voluptas assumenda est, omnis dolor repellendus. Temporibus
              autem quibusdam et aut officiis debitis aut rerum necessitatibus
              saepe eveniet ut et voluptates repudiandae sint et molestiae non
              recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut
              aut reiciendis voluptatibus maiores alias consequatur aut
              perferendis doloribus.
            </p>
            <div className="demo">
              <span>"</span>
              <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                rerum facilis est et expedita distinctio.
              </p>
            </div>
            <p>
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga. Et harum quidem rerum
              facilis est et expedita distinctio.
            </p>
            <p>
              Nam libero tempore, cum soluta nobis est eligendi optio cumque
              nihil impedit quo minus id quod maxime placeat facere possimus,
              omnis voluptas assumenda est, omnis dolor repellendus. Temporibus
              autem quibusdam et aut officiis debitis aut rerum necessitatibus
              saepe eveniet ut et voluptates repudiandae sint et molestiae non
              recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut
              aut reiciendis voluptatibus maiores alias consequatur aut
              perferendis doloribus.
            </p>
          </main>
          <aside className="aside">
            <p className="h4 fw-600 aside-title">Related Posts</p>
            <CDBCard className="aside-card">
              <CDBCardBody className="d-flex flex-column p-0">
                <CDBCardImage
                  className="img-fluid"
                  src="/img/pages/post1.png"
                />
                <CDBCardTitle className="fw-600 h5">
                  Global Warming: Is humanity doomed to a new ice age
                </CDBCardTitle>
                <CDBCardText
                  style={{ cursor: "pointer" }}
                  className="fw-600 text-end mt-auto"
                >
                  See More
                  <CDBIcon fas className="ms-1" icon="arrow-right" />
                </CDBCardText>
              </CDBCardBody>
            </CDBCard>
            <CDBCard className="aside-card">
              <CDBCardBody className="d-flex flex-column p-0">
                <CDBCardImage
                  className="img-fluid"
                  src="/img/pages/post2.png"
                />
                <CDBCardTitle className="fw-600 h5">
                  Five Reasons to keep your massage palor reservation
                </CDBCardTitle>
                <CDBCardText
                  style={{ cursor: "pointer" }}
                  className="fw-600 text-end mt-auto"
                >
                  See More
                  <CDBIcon fas className="ms-1" icon="arrow-right" />
                </CDBCardText>
              </CDBCardBody>
            </CDBCard>
            <CDBCard className="aside-card">
              <CDBCardBody className="d-flex flex-column p-0">
                <CDBCardImage
                  className="img-fluid"
                  src="/img/pages/post3.png"
                />
                <CDBCardTitle className="fw-600 h5">
                  Top 10 best rated tech companies in the world
                </CDBCardTitle>
                <CDBCardText
                  style={{ cursor: "pointer" }}
                  className="fw-600 text-end mt-auto"
                >
                  See More
                  <CDBIcon fas className="ms-1" icon="arrow-right" />
                </CDBCardText>
              </CDBCardBody>
            </CDBCard>
            <CDBCard className="aside-card">
              <CDBCardBody className="d-flex flex-column p-0">
                <CDBCardImage
                  className="img-fluid"
                  src="/img/pages/post4.png"
                />
                <CDBCardTitle className="fw-600 h5">
                  Ways to raise your market value
                </CDBCardTitle>
                <CDBCardText
                  style={{ cursor: "pointer" }}
                  className="fw-600 text-end mt-auto"
                >
                  See More
                  <CDBIcon fas className="ms-1" icon="arrow-right" />
                </CDBCardText>
              </CDBCardBody>
            </CDBCard>
          </aside>
        </section>
        <section className="section2">
          <p className="h4 fw-600">Comments</p>
          <CDBPane className="write-comment d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <CDBPaneImage src="/img/pane/pane1.png" className="me-2 mt-2" />
              <CDBInput
                placeholder="Write Comment"
                style={{ width: "100%" }}
                className="mb-0"
                type="text"
              />
            </div>
            <CDBBtn flat color="dark">
              Post
            </CDBBtn>
          </CDBPane>
          <CDBPane className="pane mt-5">
            <CDBPaneImage src="/img/pane/pane2.png" />
            <div>
              <p className="fw-600">Clark Williams</p>
              <p className="comment-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </p>
            </div>
          </CDBPane>
          <CDBPane className="pane">
            <CDBPaneImage src="/img/pane/pane3.png" />
            <div>
              <p className="fw-600">Clark Williams</p>
              <p className="comment-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </p>
            </div>
          </CDBPane>
          <CDBPane className="pane">
            <CDBPaneImage src="/img/pane/pane4.png" />
            <div>
              <p className="fw-600">Clark Williams</p>
              <p className="comment-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </p>
            </div>
          </CDBPane>
          <CDBPane className="pane">
            <CDBPaneImage src="/img/pane/pane5.png" />
            <div>
              <p className="fw-600">Clark Williams</p>
              <p className="comment-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </p>
            </div>
          </CDBPane>
          <p className="text-center mt-3" style={{ cursor: "pointer" }}>
            View more comments <CDBIcon fas icon="angle-down" />
          </p>
        </section>
      </div>
      <footer className="footer">
        <CDBLink href="/" className="footer-logo">
          <img alt="logo" src="/img/pages/logo.png" width="25px" />
          <span className="mb-0 ms-4 h5 font-weight-bold">Devwares</span>
        </CDBLink>
        <small>&copy; Devwares, 2020. All rights reserved.</small>
        <div className="footer-contact d-flex">
          <CDBBtn flat color="dark" className="p-2">
            <CDBIcon fab icon="facebook-f" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="p-2 mx-3">
            <CDBIcon fab icon="twitter" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="p-2">
            <CDBIcon fab icon="instagram" />
          </CDBBtn>
        </div>
      </footer>
    </div>
  );
};
