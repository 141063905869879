import React, { useState } from "react";
import {
  CDBLink,
  CDBIcon,
  CDBCard,
  CDBBtn,
  CDBInput,
  CDBCardBody,
  CDBNavbar,
  CDBNavBrand,
  CDBModal,
} from "cdbreact";
import "./ForgotPasswordPopUp.css";

export const ForgotPasswordPopUp = () => {
  const [modal, setModal] = useState(true);
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="forgotPasswordPopUp">
      <div className="position-relative">
        <img alt="Background" src="/img/pages/signIn.png" className="image" />
      </div>
      <div className="body">
        <CDBNavbar
          className="navigation bg-transparent"
          light
          expand="md"
          scrolling
        >
          <CDBNavBrand href="/" className="d-flex align-items-center">
            <img alt="logo" src="/img/pages/logo.png" width="25px" />
            <span className="ms-3 h4 my-0 font-weight-bold">Devwares</span>
          </CDBNavBrand>
        </CDBNavbar>
        <CDBCard className="shadow-lg form">
          <CDBCardBody className="mx-5 py-5 px-0">
            <div className="mt-4 mb-5">
              <p className="h4 font-weight-bold">Sign in</p>
            </div>
            <CDBInput
              id="defaultRegisterUsername"
              background
              placeholder="Username"
              className="mt-n3"
              type="text"
            />
            <CDBInput
              id="defaultRegisterPassword"
              background
              placeholder="Password"
              className="mt-n3"
              type="password"
            />
            <CDBLink
              onClick={toggle}
              style={{ fontWeight: "600" }}
              className="d-inline p-0 text-dark"
              to="#"
            >
              Forgot Password?
            </CDBLink>
            <CDBModal isOpen={modal} toggle={toggle} centered fade>
              <CDBCard>
                <CDBBtn
                  color="none"
                  flat
                  onClick={toggle}
                  className="ms-auto border-0 mt-2 me-2"
                >
                  <CDBIcon fas icon="times" />
                </CDBBtn>
                <CDBCardBody className="w-75 mx-auto modal-card">
                  <div className="mt-4 mb-5">
                    <p className="h3 font-weight-bold">Forgot Password</p>
                  </div>
                  <CDBInput
                    id="defaultRegisterEmail"
                    placeholder="Email Address"
                    className="mt-n3"
                    background
                    type="email"
                  />
                  <CDBBtn color="dark" className="btn-block w-100 mb-3 mt-5">
                    Recover Password
                  </CDBBtn>
                  <p className="text-muted text-center">
                    Return to
                    <CDBLink
                      style={{ fontWeight: "600" }}
                      onClick={toggle}
                      className="d-inline ms-2 p-0 text-dark"
                      to="#"
                    >
                      Sign in
                    </CDBLink>
                  </p>
                </CDBCardBody>
              </CDBCard>
            </CDBModal>
            <CDBBtn color="dark" className="btn-block w-100 mb-3 mt-5">
              Sign in
            </CDBBtn>
            <div className="d-flex align-items-center">
              <span style={{ border: "0.5px solid black", width: "45%" }} />
              <span className="text-center" style={{ width: "10%" }}>
                OR
              </span>
              <span style={{ border: "0.5px solid black", width: "45%" }} />
            </div>
            <div className="my-3 d-flex justify-content-center">
              <CDBBtn flat color="dark">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn color="dark" flat className="mx-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark">
                <CDBIcon fab icon="google-plus-g" />
              </CDBBtn>
            </div>
            <p className="text-muted text-center">
              Don't have an Account?{" "}
              <CDBLink
                style={{ fontWeight: "600" }}
                className="d-inline p-0 text-dark"
                to="#"
              >
                Sign Up
              </CDBLink>
            </p>
          </CDBCardBody>
        </CDBCard>
      </div>
    </div>
  );
};
