import React, { Fragment, useState } from "react";
import { CDBSelect2, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const Select2 = () => {
  const [option] = useState([
    {
      text: "Select Option",
      icon:"stack-overflow",
    },
    {
      text: "Another Option",
      icon:"reddit",
    },
    {
      text: "Option 3",
      icon:"instagram",
    },
    {
      text: "Option 4",
    },
    {
      text: "Last Option",
    },
  ]);
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div
        style={{
          margin: "auto",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div className="blockcode">
          <div className="header d-flex">Select <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Select Button</div>
          <div className="example" style={{minWidth:"350px"}}>
            <CDBSelect2 options={option} iconBrand selected="Choose an option" color="white"/>
          </div>
        </div>

        <div className="blockcode">
          <div className="description d-flex">Disabled Select Button <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="example" style={{minWidth:"350px"}}>
          <CDBSelect2 options={option} iconBrand selected="Choose an option" disabled color="none" />
          </div>
        </div>

        <div className="blockcode">
          <div className="description d-flex">Colored Select Button <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="example" style={{flexDirection:"column", minWidth:"350px"}}>
            <CDBSelect2 options={option} iconBrand selected="Choose an option" color="primary" />
            <CDBSelect2 options={option} iconBrand selected="Choose an option" color="secondary" />
            <CDBSelect2 options={option} iconBrand selected="Choose an option" color="success" />
            <CDBSelect2 options={option} iconBrand selected="Choose an option" color="warning" />
            <CDBSelect2 options={option} iconBrand selected="Choose an option" color="dark" />
          </div>
        </div>

        <div className="blockcode">
          <div className="description d-flex">Colored Options on Hover <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="example" style={{minWidth:"350px"}}>
            <CDBSelect2 options={option} iconBrand selected="Choose an option" hoverColor color="danger" />
          </div>
        </div>      
      </div>
    </div>
      </Layout>
    </div>
  );
};
