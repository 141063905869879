import React, { useState } from "react";
import { CDBDatePicker, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const DatePicker = () => {
  const [value, onChange] = useState(new Date());
  return (
    <div className="d-flex w-100">
      <div style={{ height: "100vh", overflow: "hidden initial" }}>
        <Sidebar />
      </div>
      <Layout>
        <TemplateNav />
        <div className="r-w">
          <div className="blockcode">
            <div className="header">
              Basic Example
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="description">Basic Example</div>
            <div className="example">
              <CDBDatePicker onChange={onChange} value={value} />
            </div>
          </div>
          <div
            style={{
              margin: "auto",
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          ></div>
        </div>
      </Layout>
    </div>
  );
};
