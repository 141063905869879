import React from "react";
import { 
  CDBBtn,
  CDBCard,
  CDBCardImage,
  CDBCardBody,
  CDBCardTitle,
  CDBCardText,
  CDBView,
  CDBIframe,
  CDBIcon,
  CDBProgress,
  CDBPane,
  CDBPaneImage,
  CDBRating,
  CDBDropDown,
  CDBDropDownToggle,
  CDBDropDownMenu,
  CDBDropDownItem,
  CDBCheckbox,
  CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const Card = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header">Basic</div>
        <div className="description">Basic cards</div>
        <div className="example2">
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <img
              alt="cardImg"
              className="img-fluid"
              src="/img/cardbg.png"
            />
            <div className="p-3">
              <h3>Basic</h3>
              <p>This is just a card text Get important notifications about you or activity you've missed </p>
              <CDBBtn>
                Button
                </CDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Widget <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Widget cards</div>
        <div className="example2">
          <CDBCard style={{maxWidth: "25rem"}}>
            <CDBCardBody>
              <CDBCardTitle className="lead">Total Downloads</CDBCardTitle>
              <CDBCardTitle tag="h2" style={{fontWeight:"600"}}>1,057,891</CDBCardTitle>
              <CDBCardText className="small text-muted">Oct 1 - Dec 31,<CDBIcon fas icon="globe"/> Worldwide</CDBCardText>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Counters <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Counter card</div>
        <div className="example2">
          <CDBCard style={{maxWidth: "25rem"}}>
            <CDBCardBody>
              <CDBIcon size="2x" className="p-4 bg-primary" fas icon="chart-line"/>
              <CDBCardTitle className="lead">Customers</CDBCardTitle>
              <CDBCardTitle tag="h2" style={{fontWeight:"600"}}>852k</CDBCardTitle>
              <CDBCardText className="small text-muted">Oct 1 - Dec 31,<CDBIcon fas icon="globe"/> Worldwide</CDBCardText>
              <CDBCardText className="text-success">
                <CDBIcon fas icon="angle-up"/> 27.4%
                <span style={{fontSize:"0.9em"}} className="ml-2 text-muted">Since last quarter</span> 
              </CDBCardText>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Ratings <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Rating card</div>
        <div className="example2">
          <CDBCard style={{maxWidth: "25rem"}}>
            <CDBCardBody>
              <CDBCardTitle className="lead">Average Rating</CDBCardTitle>
              <CDBCardTitle tag="h2" style={{fontWeight:"600"}}>4.7</CDBCardTitle>
              <CDBRating feedback/>
              <CDBCardText className="text-muted">Based on 231,156 ratings</CDBCardText>
              <div className="text-muted">
                <div className="d-flex align-items-center">
                  <span>5</span>
                  <CDBIcon fas icon="star" className="mr-3"/>
                  <CDBProgress value={50} colors="success"></CDBProgress>
                </div>
                <div className="d-flex align-items-center">
                  <span>4</span>
                  <CDBIcon fas icon="star" className="mr-3"/>
                  <CDBProgress value={40} colors="warning"></CDBProgress>
                </div>
                <div className="d-flex align-items-center">
                  <span>3</span>
                  <CDBIcon fas icon="star" className="mr-3"/>
                  <CDBProgress value={30} colors="primary"></CDBProgress>
                </div>
                <div className="d-flex align-items-center">
                  <span>2</span>
                  <CDBIcon fas icon="star" className="mr-3"/>
                  <CDBProgress value={20} colors="dark"></CDBProgress>
                </div>
                <div className="d-flex align-items-center">
                  <span>1</span>
                  <CDBIcon fas icon="star" className="mr-3"/>
                  <CDBProgress value={10} colors="danger"></CDBProgress>
                </div>
              </div>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Events <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Event list card</div>
        <div className="example2">
          <CDBCard style={{maxWidth: "25rem"}}>
            <CDBCardTitle className="lead p-4 border-bottom" style={{fontWeight:"600"}}>Events</CDBCardTitle>
            <CDBCardBody className="py-0">
              <CDBPane className="border-bottom pb-2">
                <CDBIcon size="4x" far icon="calendar-alt"/>
                <div className="ml-3">
                  <CDBCardTitle className="mb-1 lead" style={{fontWeight:"600"}}>Newmarket Nights</CDBCardTitle>
                  <CDBCardText className="mb-2">Organized by University of Oxford</CDBCardText>
                  <CDBCardText className="mb-0 small text-muted">Time: 6:00AM</CDBCardText>
                  <CDBCardText className="mb-0 small text-muted">Place: Cambridge Boat Club, Cambridge</CDBCardText>
                </div>
              </CDBPane>
              <CDBPane className="border-bottom pb-2">
                <CDBIcon size="4x" far icon="calendar-alt"/>
                <div className="ml-3">
                  <CDBCardTitle className="mb-1 lead" style={{fontWeight:"600"}}>Noco Hemp Expo</CDBCardTitle>
                  <CDBCardText className="mb-2">Organized by University of Oxford</CDBCardText>
                  <CDBCardText className="mb-0 small text-muted">Thu, 12 Sep - Sat, 18 Sep 2020</CDBCardText>
                  <CDBCardText className="mb-0 small text-muted">PPlace: Denver Expo Club, USA</CDBCardText>
                </div>
              </CDBPane>
              <CDBPane className="pb-2">
                <CDBIcon size="4x" far icon="calendar-alt"/>
                <div className="ml-3">
                  <CDBCardTitle className="mb-1 lead" style={{fontWeight:"600"}}>Canadian National Exhibition (CNE)</CDBCardTitle>
                  <CDBCardText className="mb-2">Organized by University of Oxford</CDBCardText>
                  <CDBCardText className="mb-0 small text-muted">Fri, 20 Sep - Mon, 07 Oct 2020</CDBCardText>
                  <CDBCardText className="mb-0 small text-muted">Place: Toronto , Canada</CDBCardText>
                </div>
              </CDBPane>
            </CDBCardBody>
            <CDBCardText className="p-4 text-center pointer border-top">See All</CDBCardText>
          </CDBCard>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Invoices <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Invoice list card</div>
        <div className="example2">
          <CDBCard style={{maxWidth: "25rem"}}>
            <CDBCardBody className="p-0">
              <CDBPane className="py-3 px-3 border-bottom">
                <div>
                  <CDBCardTitle className="mb-2 mt-0 lead" style={{fontWeight:"600"}}>Order History</CDBCardTitle>
                  <CDBCardText className="text-muted small">Manage billing information and view receipts</CDBCardText>
                </div>         
              </CDBPane>
              <CDBPane className="py-3 px-3 border-bottom">
                <div>
                  <CDBCardTitle className="mb-2 mt-0 h6" style={{fontWeight:"600"}}>Invoice #120345</CDBCardTitle>
                  <CDBCardText className="text-muted small">Billed August 21, 2019</CDBCardText>
                </div>         
                <CDBBtn outline size="small" className="ml-auto" color="success" flat>Pay now</CDBBtn>
              </CDBPane>
              <CDBPane className="py-3 px-3">
                <div>
                  <CDBCardTitle className="mb-2 mt-0 h6" style={{fontWeight:"600"}}>Invoice #120344</CDBCardTitle>
                  <CDBCardText className="text-muted small">Billed July 21, 2019</CDBCardText>
                </div>         
              </CDBPane>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Alerts & Notifications <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Alerts and Notifications Card</div>
        <div className="example2">
          <CDBCard style={{maxWidth: "25rem"}}>
            <CDBCardBody className="py-0">
              <CDBCardTitle className="lead m-0 py-3 border-bottom" style={{fontWeight:"600"}}>Alerts & Notifications</CDBCardTitle>
              <CDBPane className="py-3 border-bottom">
                <div>
                  <CDBCardTitle className="my-0 mb-2 h6" style={{fontWeight:"600"}}>Company News</CDBCardTitle>
                  <p className="small m-0">Get Rocket news, announcements, and product updates</p>
                </div>
                <CDBCheckbox className="ml-auto"/>
              </CDBPane>
              <CDBPane className="py-3 border-bottom">
                <div>
                  <CDBCardTitle className="my-0 mb-2 h6" style={{fontWeight:"600"}}>Account Activity</CDBCardTitle>
                  <p className="small m-0">Get important notifications about you or activity you've missed</p>
                </div>         
                <CDBCheckbox className="ml-auto"/>
              </CDBPane>
              <CDBPane className="py-3">
                <div>
                  <CDBCardTitle className="my-0 mb-2 h6" style={{fontWeight:"600"}}>Meetups Near You</CDBCardTitle>
                  <p className="small m-0">Get an email when a Dribbble Meetup is posted close to my location</p>
                </div>         
                <CDBCheckbox className="ml-auto"/>
              </CDBPane>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Profile</div>
        <div className="description">Profile display card</div>
        <div className="example2">
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <img
              alt="cardImg"
              className="img-fluid"
              src="/img/cardbg.png"
            />
            <img alt="cardImg" className="mx-auto border rounded-circle" style={{marginTop:"-5rem"}} width="130px" src="/img/pane/pane4.png" />
            <div className="p-3 d-flex flex-column align-items-center mb-4">
              <h4 style={{fontWeight:"600"}}>Sammy Russo</h4>
              <p>Senior Software Developer</p>
              <p className="text-muted">Detroit, USA</p>
              <div>
                <CDBBtn size="small" color="dark"><i className="fas fa-user-plus"></i>Connect</CDBBtn>
                <CDBBtn className="ml-3" size="small" color="warning"> Send Message </CDBBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Profile <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Profile list card</div>
        <div className="example2">
          <CDBCard style={{ maxWidth: "25rem" }}>
            <CDBCardTitle className="lead p-3 border-bottom" style={{fontWeight:"600"}}>Team Members</CDBCardTitle>
            <CDBCardBody className="py-0">
              <CDBPane className="mt-2">
                <CDBPaneImage size="md" src="/img/pane/pane1.png" />
                <div className="ml-3">
                  <CDBCardTitle className="my-0 h6" style={{fontWeight:"600"}}>Warren Briggs</CDBCardTitle>
                  <p className="m-0 d-flex align-items-center" style={{fontSize:"0.7em"}}><CDBIcon fas className="p-0 mr-1 text-success" style={{fontSize:"0.5em"}} icon="circle"/>Online</p>
                </div>         
                <CDBBtn size="small" className="ml-auto" color="success" flat>Add</CDBBtn>
              </CDBPane>
              <CDBPane className="mt-4">
                <CDBPaneImage size="md" src="/img/pane/pane2.png" />
                <div className="ml-3">
                  <CDBCardTitle className="my-0 h6" style={{fontWeight:"600"}}>Lu Chen</CDBCardTitle>
                  <p className="m-0 d-flex align-items-center" style={{fontSize:"0.7em"}}><CDBIcon fas style={{fontSize:"0.5em"}} className="p-0 mr-1 text-warning" icon="circle"/>In a meeting</p>
                </div>         
                <CDBBtn size="small" className="ml-auto" color="success" flat>Add</CDBBtn>
              </CDBPane>
              <CDBPane className="mt-4">
                <CDBPaneImage size="md" src="/img/pane/pane2.png" />
                <div className="ml-3">
                  <CDBCardTitle className="my-0 h6" style={{fontWeight:"600"}}>Lilah Loselev</CDBCardTitle>
                  <p className="m-0 d-flex align-items-center" style={{fontSize:"0.7em"}}><CDBIcon fas style={{fontSize:"0.5em"}} className="p-0 mr-1 text-muted" icon="circle"/>Not Available</p>
                </div>         
                <CDBBtn size="small" className="ml-auto" color="success" flat>Add</CDBBtn>
              </CDBPane>
              <CDBPane className="my-4">
                <CDBPaneImage size="md" src="/img/pane/pane4.png" />
                <div className="ml-3">
                  <CDBCardTitle className="my-0 h6" style={{fontWeight:"600"}}>Sammy</CDBCardTitle>
                  <p className="m-0 d-flex align-items-center" style={{fontSize:"0.7em"}}><CDBIcon fas style={{fontSize:"0.5em"}} className="p-0 mr-1 text-danger" icon="circle"/>Offline</p>
                </div>         
                <CDBBtn size="small" className="ml-auto" color="success" flat>Add</CDBBtn>
              </CDBPane>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>      
      <div className="blockcode">
        <div className="header">Blog <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Blog Card</div>
        <div className="example2">
          <CDBCard style={{ maxWidth: "25rem" }}>
            <CDBCardBody className="p-1">
              <CDBPane className="border-bottom p-3">
                <CDBPaneImage size="xs" src="/img/pane/pane2.png" />
                <CDBCardText className="m-0 ml-3">techlead</CDBCardText>
                <CDBDropDown className="ml-auto">
                  <CDBDropDownToggle color="white"><CDBIcon className="text-muted" fas icon="ellipsis-h" /></CDBDropDownToggle>
                  <CDBDropDownMenu>
                    <CDBDropDownItem disabled>Edit Profile</CDBDropDownItem>
                    <CDBDropDownItem divider />
                    <CDBDropDownItem>Action 1</CDBDropDownItem>
                    <CDBDropDownItem>Action 2</CDBDropDownItem>
                  </CDBDropDownMenu>
                </CDBDropDown> 
              </CDBPane>
              <CDBCardImage
                className="img-fluid w-100 my-3"
                src="/img/cardbg.png"
              />
              <div className="mx-2 mb-4">
                <CDBCardTitle>A Dummy blog title sample</CDBCardTitle>
                <CDBCardText className="text-muted mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</CDBCardText>
                <div>
                  <CDBBtn color="dark" style={{width:"initial"}} className="py-1 mr-2"><CDBIcon className="pl-0" fas icon="arrow-up"/>11</CDBBtn>
                  <CDBBtn color="dark" style={{width:"initial"}} className="py-1"><CDBIcon className="pl-0" fas icon="arrow-down"/>2</CDBBtn>
                </div>
                <div className="my-2">
                  <CDBBtn flat color="none" style={{width:"initial"}} className="p-0"><CDBIcon far icon="comments"/>21.4k</CDBBtn>
                  <CDBBtn flat color="none" style={{width:"initial"}} className="p-0 mx-4"><CDBIcon fas icon="share"/>Share</CDBBtn>
                  <CDBBtn color="warning" style={{width:"initial"}} className="py-1"><CDBIcon far icon="save"/>Save</CDBBtn>
                </div>
              </div>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Simple</div>
        <div className="description">Simple cards</div>
        <div className="example2">
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
              <div className="d-flex justify-content-center">
                <CDBBtn color="light" flat circle>
                  Button
                </CDBBtn>
              </div>
            </div>
          </div>
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
              <CDBBtn color="light" flat block>
                Button
              </CDBBtn>
            </div>
          </div>
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
              <CDBBtn color="light" flat circle>
                Button
              </CDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Bodered</div>
        <div className="description">Bodered cards</div>
        <div className="example2">
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }} border>
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
              <div className="d-flex justify-content-center">
                <CDBBtn color="light" flat circle>
                  Button
                </CDBBtn>
              </div>
            </div>
          </div>
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }} border>
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
              <CDBBtn color="light" flat block>
                Button
              </CDBBtn>
            </div>
          </div>
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <div className="p-3 text-end ">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
              <CDBBtn color="light" flat className="w-100" circle>
                Button
              </CDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Artwork top</div>
        <div className="description">Artwork top cards</div>
        <div className="example2">
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <img
              alt="cardImg"
              className="img-fluid"
              src="/img/cardbg.png"
            />
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
              <div className="d-flex justify-content-center">
                <CDBBtn color="light" flat block circle>
                  Button
                </CDBBtn>
              </div>
            </div>
          </div>
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <img
              alt="cardImg"
              className="img-fluid"
              src="/img/cardbg.png"
            />
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
              <CDBBtn color="light" flat circle>
                Button
              </CDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Artwork middle</div>
        <div className="description">Artwork middle cards</div>
        <div className="example2">
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }}>
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
            </div>
            <CDBView>
              <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
            </CDBView>
            <div className="p-3">
              <CDBBtn color="light" flat circle>
                Button
              </CDBBtn>
            </div>
          </div>
          <div className="border d-flex flex-column" style={{ maxWidth: "25rem" }} border>
            <div className="p-3">
              <h4 className="mt-3">Heading</h4>
              <p>Paragraph</p>
            </div>
            <CDBView>
              <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
            </CDBView>
            <div className="p-3">
              <CDBBtn color="light" flat circle>
                Button
              </CDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Artwork full cards <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">ArtWork full cards</div>
        <div className="example2">
          <CDBCard
            style={{
              maxWidth: "25rem",
              backgroundImage:
                "url('https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_960_720.jpg')",
            }}
          >
            <CDBCardBody style={{ color: "white" }}>
              <CDBCardTitle>Heading</CDBCardTitle>
              <CDBCardText>Paragraph</CDBCardText>
            </CDBCardBody>
            <CDBCardBody>
              <CDBBtn color="white" flat circle>
                Button
              </CDBBtn>
            </CDBCardBody>
          </CDBCard>
          <CDBCard
            style={{
              maxWidth: "25rem",
              backgroundImage:
                "url('https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_960_720.jpg')",
            }}
            border
          >
            <CDBCardBody style={{ color: "white" }}>
              <CDBCardTitle>Heading</CDBCardTitle>
              <CDBCardText>Paragraph</CDBCardText>
            </CDBCardBody>
            <CDBCardBody>
              <CDBBtn color="white" flat circle>
                Button
              </CDBBtn>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Profile cards <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Profile cards</div>
        <div className="example2">
          <CDBCard style={{ position: "relative", width: "100%", height: "33rem",maxWidth:"400px", backgroundImage: "url('img/rectangle.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
            <CDBCardBody style={{backgroundColor:"white",textAlign: "center", align: "center", position: "absolute", bottom: "0px", left:"0px", right:"0px"}}>
              <CDBCardTitle className="font-weight-normal text-dark">Chukwudi Mezue</CDBCardTitle>
              <CDBCardText style={{fontSize: "0.9rem"}} className="text-dark font-weight-lighter" >Product Designer</CDBCardText>
              <CDBCardText className="text-dark font-weight-light">Enugu, Nigeria</CDBCardText>
              <CDBCardBody style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 50px",
                margin: "20px 0"
              }}>
                <CDBIcon size="lg" fab icon="facebook-f" style={{color:"#333"}}/>
                <CDBIcon size="lg" fab icon="twitter" style={{color:"#333"}}/>
                <CDBIcon size="lg" fab icon="instagram"  style={{color:"#333"}}/>
              </CDBCardBody>
                <CDBBtn color="dark" style={{width:"100%"}}>
                  Add User
                </CDBBtn>
            </CDBCardBody>
          </CDBCard>
          <CDBCard className="mt-4" style={{ position: "relative", width: "100%", height: "33rem",maxWidth:"400px", backgroundImage: "url('img/rectangle.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
            <CDBCardBody style={{backgroundColor:"white", width: "calc(100% - 50px)", textAlign:"left", align:"center", position:"absolute", bottom:"0px"}}>
              <CDBCardTitle className="font-weight-normal text-dark">Chukwudi Mezue</CDBCardTitle>
              <CDBCardText style={{fontSize: "0.9rem"}} className="font-weight-lighter text-dark" >Product Designer</CDBCardText>
              <CDBCardText className="font-weight-light text-dark">Enugu, Nigeria</CDBCardText>
              <CDBBtn color="dark" style={{width:"100%"}}>
                Send Message
              </CDBBtn>
            </CDBCardBody>
          </CDBCard>
          <CDBCard className="mt-4" style={{
            position: "relative",
            width: "100%",
            height: "33rem",
            maxWidth:"400px",
            backgroundImage: "url('img/rectangle.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain"
          }}>
            <CDBCardBody style={{
              backgroundColor: "white",
              width: "100%",
              textAlign: "center",
              align: "center",
              position: "absolute",
              bottom: 0
            }}>
              <CDBCardTitle className="text-dark font-weight-normal">Mac
                                Xenon</CDBCardTitle>
              <CDBCardText style={{fontSize: "0.9rem" }} className="text-dark font-weight-lighter">Product
                                Designer</CDBCardText>
              <CDBCardText className="text-dark font-weight-light">Enugu,
                                Nigeria</CDBCardText>
              <CDBBtn color="dark" style={{width:"100%"}}>
                Send Message
                            </CDBBtn>
              <CDBCardBody style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 20%",
                margin: "20px 0"
              }}>
                <CDBBtn size="sm" outline flat color="dark">
                  <CDBIcon size="lg" fab icon="facebook-f" style={{ color: "#333" }} />
                </CDBBtn>
                <CDBBtn size="sm" outline flat color="dark">
                  <CDBIcon size="lg" fab icon="twitter" style={{ color: "#333" }} />
                </CDBBtn>
                <CDBBtn size="sm" outline flat color="dark">
                  <CDBIcon size="lg" fab icon="instagram" style={{ color: "#333" }} />
                </CDBBtn>
              </CDBCardBody>
            </CDBCardBody>
          </CDBCard>
        </div>
        <div className="example2">
          <CDBCard style={{ maxWidth: "25rem" }}>
            <CDBCardImage
              style={{width:"100%"}}
              src="./img/uber.png"
            />
            <CDBPaneImage style={{ marginTop: "-5rem", borderStyle: "solid", borderWidth: "4px" }}
              className="mx-auto border-white" width="130px" src="./img/ellipse.png" />
            <CDBCardBody className="d-flex flex-column align-items-center mb-4 pt-1">
              <CDBCardTitle>Chukwudi Mezue</CDBCardTitle>
              <CDBCardText className="text-muted">Product Designer</CDBCardText>
              <CDBCardText style={{ fontSize: 16, color: "#333" }}>Enugu, Nigeria</CDBCardText>
              <CDBCardText style={{ color: "#333" }}>
                Creating High Quality Resources and tools to Aid developers during the developement of
                their projects
              </CDBCardText>
              <div className="d-flex justify-content-between w-100">
                <CDBBtn className="w-50 mr-4" color="dark">Add User</CDBBtn>
                <CDBBtn className="w-50" outline color="dark"> Send Message </CDBBtn>
              </div>
            </CDBCardBody>
          </CDBCard>
          <CDBCard className="mt-4" style={{ maxWidth: "25rem" }}>
            <CDBCardImage
              style={{width:"100%"}}
              src="./img/uber.png"
            />
            <CDBCardBody className="d-flex justify-content-between">
              <CDBPaneImage style={{ marginTop: "-6rem", borderStyle: "solid", borderWidth: "4px" }}
                className="border-white" width="130px" src="./img/ellipse.png" />
              <div className="align-items-left pt-1 pb-0">
                <CDBCardTitle>Chukwudi Mezue</CDBCardTitle>
                <CDBCardText className="text-muted">Product Designer</CDBCardText>
              </div>
            </CDBCardBody>
            <CDBCardBody className="d-flex flex-column align-items-center mb-2">
              <CDBCardText style={{ color: "#333" }}>
                Creating High Quality Resources and tools to Aid developers during the developement of
                their projects
              </CDBCardText>
              <div className="d-flex justify-content-between w-100">
                <CDBBtn className="w-50 mr-4" color="dark">Add User</CDBBtn>
                <CDBBtn className="w-50" outline color="dark"> Send Message </CDBBtn>
              </div>
            </CDBCardBody>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 20%",
              margin: "0 0 20px"
            }}>
              <CDBBtn size="sm" outline flat color="dark">
                <CDBIcon size="lg" fab icon="facebook-f" style={{ color: "#333" }} />
              </CDBBtn>
              <CDBBtn size="sm" outline flat color="dark">
                <CDBIcon size="lg" fab icon="twitter" style={{ color: "#333" }} />
              </CDBBtn>
              <CDBBtn size="sm" outline flat color="dark">
                <CDBIcon size="lg" fab icon="instagram" style={{ color: "#333" }} />
              </CDBBtn>
            </div>
          </CDBCard>
          <CDBCard className="mt-4" style={{ maxWidth: "25rem" }}>
            <CDBPaneImage style={{ marginTop: "2rem", borderStyle: "solid", borderWidth: "4px" }}
              className="mx-auto border-white" width="130px" src="./img/ellipse.png" />
            <CDBCardBody className="d-flex flex-column align-items-center mb-4 pt-1">
              <CDBCardTitle>Chukwudi Mezue</CDBCardTitle>
              <CDBCardText className="text-muted">Product Designer</CDBCardText>
              <CDBCardText style={{ fontSize: 16, color: "#333" }}>Enugu, Nigeria</CDBCardText>
              <CDBCardText style={{ color: "#333" }}>
                Creating High Quality Resources and tools to Aid developers during the developement of
                their projects
              </CDBCardText>
              <div className="w-100">
                <CDBBtn className="w-100 mb-3" color="dark">Send Message</CDBBtn>
                <CDBBtn className="w-100" outline color="dark"> Send Message </CDBBtn>
              </div>
            </CDBCardBody>
          </CDBCard>
        </div>
        <div className="example2">
          <CDBCard style={{ maxWidth: "25rem" }}>
            <CDBCardImage
              style={{width:"100%"}}
              src="./img/uber.png"
            />
            <CDBPaneImage style={{ marginTop: "-5rem", borderStyle: "solid", borderWidth: "4px" }}
              className="mx-auto border-white" width="130px" src="./img/ellipse.png" />
            <CDBCardBody className="d-flex flex-column align-items-center mb-4 pt-1">
              <CDBCardTitle>Chukwudi Mezue</CDBCardTitle>
              <CDBCardText className="text-muted">Product Designer</CDBCardText>
              <CDBCardText style={{ fontSize: 16, color: "#333" }}>Enugu, Nigeria</CDBCardText>
              <CDBCardBody style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "0 70px",
                margin: "10px 0 30px"
              }}>
                <CDBIcon size="lg" fab icon="facebook-f" style={{ color: "#333" }} />
                <CDBIcon size="lg" fab icon="twitter" style={{ color: "#333" }} />
                <CDBIcon size="lg" fab icon="instagram" style={{ color: "#333" }} />
              </CDBCardBody>
              <div className="w-100">
                <CDBBtn className="w-100 mb-3" color="dark">Add User</CDBBtn>
                <CDBBtn className="w-100" outline color="dark"> Send Message </CDBBtn>
              </div>
            </CDBCardBody>
          </CDBCard>
          <CDBCard className="mt-4" style={{ maxWidth: "25rem" }}>
            <CDBCardImage
              style={{width:"100%"}}
              src="./img/uber.png"
            />
            <CDBPaneImage style={{ marginTop: "-5rem", borderStyle: "solid", borderWidth: "4px" }}
              className="mx-auto border-white" width="130px" src="./img/ellipse.png" />
            <CDBCardBody className="d-flex flex-column align-items-center mb-4 pt-1">
              <CDBCardTitle>Chukwudi Mezue</CDBCardTitle>
              <CDBCardText className="text-muted">Product Designer</CDBCardText>
              <CDBCardText style={{ fontSize: 16, color: "#333" }}>Enugu, Nigeria</CDBCardText>
              <CDBCardText style={{ color: "#333", margin: "20px 0 30px" }}>
                Creating High Quality Resources and tools to Aid developers during the developement of
                their projects
                            </CDBCardText>
              <div className="w-100">
                <CDBBtn className="w-100 mb-0" color="dark">Send Message</CDBBtn>
              </div>
            </CDBCardBody>
          </CDBCard>
          <CDBCard style={{ maxWidth: "25rem", marginTop:"6rem" }}>
            <CDBPaneImage style={{ marginTop: "-4rem", borderStyle: "solid", borderWidth: "4px" }}
              className="mx-auto border-white" width="130px" src="./img/ellipse.png" />
            <CDBCardBody className="d-flex flex-column align-items-center mb-4 pt-1">
              <CDBCardTitle>Chukwudi Mezue</CDBCardTitle>
              <CDBCardText className="text-muted">Product Designer</CDBCardText>
              <CDBCardText style={{ fontSize: 16, color: "#333" }}>Enugu, Nigeria</CDBCardText>
              <CDBCardText style={{ color: "#333" }}>
                Creating High Quality Resources and tools to Aid developers during the developement of
                their projects
              </CDBCardText>
              <div className="w-100">
                <CDBBtn className="w-100" color="dark">Send Message</CDBBtn>
                <CDBBtn className="w-100 mt-3" outline color="dark">Send Message </CDBBtn>
              </div>
            </CDBCardBody>
          </CDBCard>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
