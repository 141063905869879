import React, { Fragment } from "react";
import { CDBBadge, CDBIcon } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Badge = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header">Pill Badges</div>
        <div className="description">Default Styles Badges</div>
        <div className="example">
          <CDBBadge color="primary">Primary</CDBBadge>
          <CDBBadge color="secondary">Secondary</CDBBadge>
          <CDBBadge color="success">Success</CDBBadge>
          <CDBBadge color="danger">Danger</CDBBadge>
          <CDBBadge color="warning">Warning</CDBBadge>
          <CDBBadge color="info">Info</CDBBadge>
          <CDBBadge color="dark">Dark</CDBBadge>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Pill Badges</div>
        <div className="description">Pill Badges</div>
        <div className="example">
          <CDBBadge color="primary" borderType="pill">
            Primary
          </CDBBadge>
          <CDBBadge color="secondary" borderType="pill">
            Secondary Badge
          </CDBBadge>
          <CDBBadge color="success" borderType="pill">
            Success Badge
          </CDBBadge>
          <CDBBadge color="danger" borderType="pill">
            Danger Badge
          </CDBBadge>
          <CDBBadge color="warning" borderType="pill">
            Warning Badge
          </CDBBadge>
          <CDBBadge color="info" borderType="pill">
            Info Badge
          </CDBBadge>
          <CDBBadge color="dark" borderType="pill">
            Dark Badge
          </CDBBadge>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Sizes Variations</div>
        <div className="description">Different variations of Badges Sizes</div>
        <div className="example">
          <CDBBadge color="secondary" size="large">
            Large
          </CDBBadge>
          <CDBBadge color="primary">Medium</CDBBadge>
          <CDBBadge color="success" size="small">
            Small
          </CDBBadge>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Flat Badges</div>
        <div className="description">Flat badges</div>
        <div className="example">
          <CDBBadge color="primary" flat>
            Flat Primary
          </CDBBadge>
          <CDBBadge color="secondary" flat>
            Flat Secondary
          </CDBBadge>
          <CDBBadge color="success" flat>
            Flat Success
          </CDBBadge>
          <CDBBadge color="danger" flat>
            Flat Danger
          </CDBBadge>
          <CDBBadge color="warning" flat>
            Flat Warning
          </CDBBadge>
          <CDBBadge color="info" flat>
            Flat Info
          </CDBBadge>
          <CDBBadge color="dark" flat>
            Flat Dark
          </CDBBadge>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Badges with Icons
        <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a>
       </div>
        <div className="description">
          Different variations of Badges with Icons
        </div>
        <div className="example">
          <CDBBadge color="secondary">
            <CDBIcon fab icon="facebook-f" />
          </CDBBadge>
          <CDBBadge color="primary">
            <CDBIcon fab icon="instagram" />
          </CDBBadge>
          <CDBBadge color="success">
            <CDBIcon fab icon="snapchat-ghost" />
          </CDBBadge>
          <CDBBadge color="info">
            <CDBIcon icon="anchor" />
          </CDBBadge>
          <CDBBadge color="warning">
            <CDBIcon far icon="sun" />
          </CDBBadge>
          <CDBBadge color="danger">
            <CDBIcon icon="battery-three-quarters" />
          </CDBBadge>
          <CDBBadge color="success">
            <CDBIcon fab icon="btc" size="large" />
          </CDBBadge>
          <CDBBadge borderType="pill" color="secondary" size="large">
            <CDBIcon icon="heart" />
          </CDBBadge>
          <CDBBadge borderType="pill" color="danger">
            <CDBIcon fab icon="apple" />
          </CDBBadge>
          <CDBBadge borderType="pill" color="primary" size="large">
            <CDBIcon icon="users" />
          </CDBBadge>
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Rounded Badges <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a> </div>
        <div className="description">
          Different variations of Rounded Badges with Icons
        </div>
        <div className="example">
          <CDBBadge color="secondary">
            <CDBIcon fab icon="facebook-f" />
          </CDBBadge>
          <CDBBadge color="primary">
            <CDBIcon fab icon="instagram" />
          </CDBBadge>
          <CDBBadge color="success">
            <CDBIcon fab icon="snapchat-ghost" />
          </CDBBadge>
          <CDBBadge color="info">
            <CDBIcon icon="anchor" />
          </CDBBadge>
          <CDBBadge color="warning">
            <CDBIcon far icon="sun" />
          </CDBBadge>
          <CDBBadge color="danger">
            <CDBIcon icon="battery-three-quarters" />
          </CDBBadge>
          <CDBBadge color="success">
            <CDBIcon fab icon="btc" size="large" />
          </CDBBadge>
          <CDBBadge borderType="pill" color="secondary" size="large">
            <CDBIcon icon="heart" />
          </CDBBadge>
          <CDBBadge borderType="pill" color="danger">
            <CDBIcon fab icon="apple" />
          </CDBBadge>
          <CDBBadge borderType="pill" color="primary" size="large">
            <CDBIcon icon="users" />
          </CDBBadge>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
