import React from "react";
import {
  CDBBtn,
  CDBCard,
  CDBCardBody,
  CDBCardTitle,
  CDBCardText,
  CDBIcon,
  CDBPane,
  CDBPanel,
  CDBPanelTitle,
  CDBPanelText,
  CDBPaneImage,
  CDBLink,
  CDBFooter,
} from "cdbreact";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { Bar, Line } from "react-chartjs-2";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./ProDashboard4.css";

export const ProDashboard4 = () => {
  const data = {
    chart1: {
      labels: [
        "Eating",
        "Drinking",
        "Sleeping",
        "Designing",
        "Coding",
        "Cycling",
        "Running",
      ],
      datasets: [
        {
          label: "My Dataset",
          pointRadius: 0,
          backgroundColor: "rgba(47, 128, 237, 0.3)",
          tension: 0.5,
          fill: "origin",
          borderColor: "rgb(47, 128, 237)",
          borderWidth: 2,
          data: [35, 50, 55, 50, 55, 50, 53],
        },
      ],
    },
    chart2: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "# of Blue Votes",
          data: [2, 3, 20, 5, 1, 4, 3, 10, 13, 15, 22, 30],
          backgroundColor: "#F2994A",
        },
        {
          label: "# of Green Votes",
          data: [3, 10, 13, 15, 22, 30, 2, 3, 20, 5, 1, 4],
          backgroundColor: "#2D9CDB",
        },
      ],
    },
  };
  const options = {
    option1: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false,
          },
        },
      },
    },
    option2: {
      responsive: true,
      maintainAspectRatio: false,
     plugins: { legend: { display: false },},
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          ticks: {},
        },
        x: {
          grid: {
            display: false,
          },
          stacked: true,
        },
      },
    },
  };

  return (
    <div className="prodashboard4" style={{ background: "#FAFAFA" }}>
      <div className="d-flex">
        <div style={{ height: "100vh", overflow: "hidden initial" }}>
          <Sidebar bgColor="#fff" color="#333" />
        </div>
        <div
          className="text-dark"
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div className="scroll-container">
              <div className="cards-container">
                <CDBCard className="p-2 card-boundary card1">
                  <CDBCardBody className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <CDBIcon fas size="lg" icon="suitcase" />
                      <CDBCardTitle
                        className="h6 ms-3"
                        style={{ fontWeight: "600" }}
                      >
                        Total Orders
                      </CDBCardTitle>
                    </div>
                    <div className="d-flex mt-4">
                      <div>
                        <CircularProgressbarWithChildren
                          value={65}
                          className="circleProgress"
                          strokeWidth={5}
                          styles={buildStyles({
                            pathColor: "#F2C94C",
                            trailColor: "transparent",
                            rotation: 0.5,
                          })}
                        >
                          <CircularProgressbarWithChildren
                            value={75}
                            className="circleProgress2"
                            strokeWidth={5.5}
                            styles={buildStyles({
                              pathColor: "#BB6BD9",
                              trailColor: "transparent",
                              rotation: 0.5,
                            })}
                          >
                            <CircularProgressbarWithChildren
                              value={45}
                              className="circleProgress3"
                              strokeWidth={6}
                              styles={buildStyles({
                                pathColor: "#2D9CDB",
                                trailColor: "transparent",
                                rotation: 0.5,
                              })}
                            />
                          </CircularProgressbarWithChildren>
                        </CircularProgressbarWithChildren>
                      </div>
                      <div className="w-25 card1-desc">
                        <div className="mt-2 d-flex align-items-center justify-content-between text-primary">
                          <span
                            style={{
                              fontSize: "3em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span style={{ fontWeight: "600" }}>Google</span>
                        </div>
                        <div
                          className="mt-2 d-flex align-items-center justify-content-between"
                          style={{ color: "#9B51E0" }}
                        >
                          <span
                            style={{
                              fontSize: "3em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span style={{ fontWeight: "600" }}>Yahoo</span>
                        </div>
                        <div className="mt-2 d-flex align-items-center justify-content-between text-warning">
                          <span
                            style={{
                              fontSize: "3em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span style={{ fontWeight: "600" }}>Bing</span>
                        </div>
                      </div>
                    </div>
                    <CDBBtn
                      flat
                      className="ms-auto mt-5 py-2 px-3 bg-dark text-white border-0"
                      style={{ fontSize: "0.8em" }}
                    >
                      View Details
                      <CDBIcon className="ms-2" fas icon="arrow-right" />
                    </CDBBtn>
                  </CDBCardBody>
                </CDBCard>
                <CDBCard className="p-2 card-boundary card2">
                  <CDBCardBody className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <CDBIcon fas size="lg" icon="suitcase" />
                      <CDBCardTitle
                        className="h6 ms-3"
                        style={{ fontWeight: "600" }}
                      >
                        Sales
                      </CDBCardTitle>
                    </div>
                    <div className="d-flex flex-wrap mt-4">
                      <div className="card2-prog">
                        <CircularProgressbarWithChildren
                          value={63}
                          className="circleProgress"
                          strokeWidth={15}
                          styles={buildStyles({
                            pathColor: "#27AE60",
                            trailColor: "#E0E0E0",
                            rotation: 0.5,
                          })}
                        >
                          <div className="d-flex flex-column align-items-center text-center">
                            <CDBCardTitle className="mb-2 h4 font-weight-bold">
                              $30,000
                            </CDBCardTitle>
                            <CDBCardText
                              className="text-success mb-0"
                              style={{ fontWeight: "600" }}
                            >
                              <span
                                style={{ fontWeight: "600", fontSize: "0.9em" }}
                              >
                                +27.4%
                              </span>
                            </CDBCardText>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                      <div className="card2-desc">
                        <div className="d-flex align-items-center justify-content-between text-success">
                          <span
                            style={{
                              fontSize: "3em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span
                            style={{ fontSize: "0.9em", fontWeight: "600" }}
                          >
                            Ongoing
                          </span>
                        </div>
                        <div
                          className="mt-2 d-flex align-items-center justify-content-between"
                          style={{ color: "#E0E0E0" }}
                        >
                          <span
                            style={{
                              fontSize: "3em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span
                            style={{ fontSize: "0.9em", fontWeight: "600" }}
                          >
                            Completed
                          </span>
                        </div>
                      </div>
                    </div>
                    <CDBBtn
                      flat
                      className="ms-auto mt-auto py-2 px-3 bg-dark text-white border-0"
                      style={{ fontSize: "0.8em" }}
                    >
                      View Details
                      <CDBIcon className="ms-2" fas icon="arrow-right" />
                    </CDBBtn>
                  </CDBCardBody>
                </CDBCard>
                <CDBCard className="p-2 card-boundary card3">
                  <CDBCardBody className="d-flex flex-column">
                    <div className="d-flex flex-wrap align-items-center">
                      <CDBIcon fas size="lg" icon="suitcase" />
                      <CDBCardTitle
                        className="h6 ms-3"
                        style={{ fontWeight: "600" }}
                      >
                        Sales
                      </CDBCardTitle>
                      <div className="ms-auto d-flex">
                        <div className="d-flex align-items-center text-warning me-2">
                          <span
                            style={{
                              fontSize: "3em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span
                            style={{ fontSize: "0.9em", fontWeight: "600" }}
                          >
                            Bing
                          </span>
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ color: "#2D9CDB" }}
                        >
                          <span
                            style={{
                              fontSize: "3em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span
                            style={{ fontSize: "0.9em", fontWeight: "600" }}
                          >
                            Google
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="p-0 mt-auto">
                        <Bar
                          height={235}
                          data={data.chart2}
                          options={options.option2}
                        />
                      </div>
                    </div>
                    <CDBBtn
                      flat
                      className="ms-auto mt-4 py-2 px-3 bg-dark text-white border-0"
                      style={{ fontSize: "0.8em" }}
                    >
                      View Details
                      <CDBIcon className="ms-2" fas icon="arrow-right" />
                    </CDBBtn>
                  </CDBCardBody>
                </CDBCard>
                <CDBPanel className="card-boundary card4 card-body">
                  <CDBPane>
                    <CDBIcon fas size="lg" icon="comment-alt" />
                    <CDBPanelTitle
                      className="ms-3 mb-2"
                      style={{ fontWeight: "600" }}
                    >
                      Messages
                    </CDBPanelTitle>
                  </CDBPane>
                  <CDBPane className="mt-4 align-items-start">
                    <div className="position-relative">
                      <CDBPaneImage
                        src="img/pane/pane2.png"
                        size="sm"
                        className="align-self-center pane-image"
                      />
                      <span
                        className="text-success"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "-1px",
                          fontSize: "0.7rem",
                        }}
                      >
                        <CDBIcon
                          fas
                          className="p-0 border rounded-circle"
                          size="xs"
                          icon="circle"
                        />
                      </span>
                    </div>
                    <div className="ms-3 h-100 d-flex flex-column">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>
                        Mezue
                      </CDBPanelTitle>
                      <CDBPanelText
                        style={{ fontSize: "0.75rem" }}
                        className="mt-2"
                      >
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </CDBPanelText>
                    </div>
                    <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                      <span style={{ fontSize: "0.8em" }}>Online</span>
                    </CDBPanelText>
                  </CDBPane>
                  <CDBPane className="mt-3 align-items-start">
                    <div className="position-relative">
                      <CDBPaneImage
                        src="img/pane/pane3.png"
                        size="sm"
                        className="align-self-center pane-image"
                      />
                      <span
                        className="text-success"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "-1px",
                          fontSize: "0.7rem",
                        }}
                      >
                        <CDBIcon
                          fas
                          className="p-0 border rounded-circle"
                          size="xs"
                          icon="circle"
                        />
                      </span>
                    </div>
                    <div className="ms-3 h-100 d-flex flex-column">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>
                        Mezue
                      </CDBPanelTitle>
                      <CDBPanelText
                        style={{ fontSize: "0.75rem" }}
                        className="mt-2"
                      >
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </CDBPanelText>
                    </div>
                    <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                      <span style={{ fontSize: "0.8em" }}>Online</span>
                    </CDBPanelText>
                  </CDBPane>
                  <CDBPane className="mt-3 align-items-start">
                    <div className="position-relative">
                      <CDBPaneImage
                        src="img/pane/pane4.png"
                        size="sm"
                        className="align-self-center pane-image"
                      />
                      <span
                        className="text-success"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "-1px",
                          fontSize: "0.7rem",
                        }}
                      >
                        <CDBIcon
                          fas
                          className="p-0 border rounded-circle"
                          size="xs"
                          icon="circle"
                        />
                      </span>
                    </div>
                    <div className="ms-3 h-100 d-flex flex-column">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>
                        Mezue
                      </CDBPanelTitle>
                      <CDBPanelText
                        style={{ fontSize: "0.75rem" }}
                        className="mt-2"
                      >
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </CDBPanelText>
                    </div>
                    <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                      <span style={{ fontSize: "0.8em" }}>Online</span>
                    </CDBPanelText>
                  </CDBPane>
                  <CDBPane className="mt-3 align-items-start">
                    <div className="position-relative">
                      <CDBPaneImage
                        src="img/pane/pane5.png"
                        size="sm"
                        className="align-self-center pane-image"
                      />
                      <span
                        className="text-success"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "-1px",
                          fontSize: "0.7rem",
                        }}
                      >
                        <CDBIcon
                          fas
                          className="p-0 border rounded-circle"
                          size="xs"
                          icon="circle"
                        />
                      </span>
                    </div>
                    <div className="ms-3 h-100 d-flex flex-column">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>
                        Mezue
                      </CDBPanelTitle>
                      <CDBPanelText
                        style={{ fontSize: "0.75rem" }}
                        className="mt-2"
                      >
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </CDBPanelText>
                    </div>
                    <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                      <span style={{ fontSize: "0.8em" }}>Online</span>
                    </CDBPanelText>
                  </CDBPane>
                  <CDBPane className="mt-3 align-items-start">
                    <div className="position-relative">
                      <CDBPaneImage
                        src="img/pane/pane1.png"
                        size="sm"
                        className="align-self-center pane-image"
                      />
                      <span
                        className="text-success"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "-1px",
                          fontSize: "0.7rem",
                        }}
                      >
                        <CDBIcon
                          fas
                          className="p-0 border rounded-circle"
                          size="xs"
                          icon="circle"
                        />
                      </span>
                    </div>
                    <div className="ms-3 h-100 d-flex flex-column">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>
                        Mezue
                      </CDBPanelTitle>
                      <CDBPanelText
                        style={{ fontSize: "0.75rem" }}
                        className="mt-2"
                      >
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </CDBPanelText>
                    </div>
                    <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                      <span style={{ fontSize: "0.8em" }}>Online</span>
                    </CDBPanelText>
                  </CDBPane>
                  <CDBBtn
                    flat
                    className="ms-auto mt-4 py-2 px-3 bg-dark text-white border-0"
                    style={{ fontSize: "0.8em" }}
                  >
                    View Details
                    <CDBIcon className="ms-2" fas icon="arrow-right" />
                  </CDBBtn>
                </CDBPanel>
                <CDBCard className="p-2 card-boundary card5">
                  <CDBCardBody className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <CDBIcon fas size="lg" icon="suitcase" />
                      <CDBCardTitle
                        className="h6 ms-3"
                        style={{ fontWeight: "600" }}
                      >
                        Page Views
                      </CDBCardTitle>
                    </div>
                    <div className="d-flex flex-column ms-5 mb-4">
                      <CDBCardTitle className="mb-2 h4 font-weight-bold">
                        Traffic
                      </CDBCardTitle>
                      <CDBCardText className="mb-0">
                        <span className="font-weight-bold">14.3%</span>
                        <span
                          className="text-success ms-2"
                          style={{ fontWeight: "600", fontSize: "0.8em" }}
                        >
                          +2.3%
                        </span>
                      </CDBCardText>
                    </div>
                    <div className="mt-auto">
                      <Line
                        height={window.innerWidth > 375 ? 274 : 200}
                        data={data.chart1}
                        options={options.option1}
                      />
                    </div>
                    <CDBBtn
                      flat
                      className="ms-auto mt-4 py-2 px-3 bg-dark text-white border-0"
                      style={{ fontSize: "0.8em" }}
                    >
                      View Details
                      <CDBIcon className="ms-2" fas icon="arrow-right" />
                    </CDBBtn>
                  </CDBCardBody>
                </CDBCard>
              </div>
              <CDBFooter>
                <div className="footer">
                  <CDBLink to="/" className="footer-link">
                    <img alt="logo" src="/img/pages/logo.png" width="25px" />
                    <span className="ms-4 mb-0 h5 font-weight-bold">
                      Devwares
                    </span>
                  </CDBLink>
                  <small>&copy; Devwares, 2020. All rights reserved.</small>
                  <div className="d-flex footer-rem">
                    <CDBBtn
                      flat
                      className="p-1 border-0"
                      style={{ background: "#4F4F4F" }}
                    >
                      <CDBIcon fab icon="facebook-f" />
                    </CDBBtn>
                    <CDBBtn
                      flat
                      className="mx-3 p-1 border-0"
                      style={{ background: "#4F4F4F" }}
                    >
                      <CDBIcon fab icon="twitter" />
                    </CDBBtn>
                    <CDBBtn
                      flat
                      className="p-1 border-0"
                      style={{ background: "#4F4F4F" }}
                    >
                      <CDBIcon fab icon="instagram" />
                    </CDBBtn>
                  </div>
                </div>
              </CDBFooter>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
