import React, { Fragment } from "react";
import { CDBBtn, CDBBadge, CDBIcon } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Button = () => {
  return (
    <div className="d-flex w-100">
      <div style={{ height: "100vh", overflow: "hidden initial" }}>
        <Sidebar />
      </div>
      <Layout>
        <TemplateNav />
        <div className="r-w">
          <div className="blockcode">
            <div className="header">Basic Styles</div>
            <div className="description">Basic contrast sytles for buttons</div>
            <div className="example">
              <CDBBtn color="primary">Primary</CDBBtn>
              <CDBBtn color="secondary">Secondary</CDBBtn>
              <CDBBtn color="success">Success</CDBBtn>
              <CDBBtn color="danger">Danger</CDBBtn>
              <CDBBtn color="dark">Dark</CDBBtn>
              <CDBBtn color="warning">Warning</CDBBtn>
              <CDBBtn color="info">Info</CDBBtn>
            </div>
          </div>
          <div className="blockcode">
            <div className="header">Circular Buttons</div>
            <div className="description">Circular Buttons</div>
            <div className="example">
              <CDBBtn color="primary" circle>
                Primary
              </CDBBtn>
              <CDBBtn color="secondary" circle>
                Secondary
              </CDBBtn>
              <CDBBtn color="success" circle>
                Success
              </CDBBtn>
              <CDBBtn color="danger" circle>
                Danger
              </CDBBtn>
              <CDBBtn color="dark" circle>
                Dark
              </CDBBtn>
              <CDBBtn color="warning" circle>
                Warning
              </CDBBtn>
              <CDBBtn color="info" circle>
                Info
              </CDBBtn>
            </div>
          </div>
          <div className="blockcode">
            <div className="header">Outline Buttons</div>
            <div className="description">Outline Buttons</div>
            <div className="example">
              <CDBBtn color="primary" outline>
                Primary
              </CDBBtn>
              <CDBBtn color="secondary" outline>
                Secondary
              </CDBBtn>
              <CDBBtn color="success" outline>
                Success
              </CDBBtn>
              <CDBBtn color="danger" outline>
                Danger
              </CDBBtn>
              <CDBBtn color="dark" outline>
                Dark
              </CDBBtn>
              <CDBBtn color="warning" outline>
                Warning
              </CDBBtn>
              <CDBBtn color="info" outline>
                Info
              </CDBBtn>
            </div>
          </div>
          <div className="blockcode">
            <div className="header">Rounded Outline Buttons</div>
            <div className="description">Rounded Outline Buttons</div>
            <div className="example">
              <CDBBtn color="primary" circle outline>
                Primary
              </CDBBtn>
              <CDBBtn color="secondary" circle outline>
                Secondary
              </CDBBtn>
              <CDBBtn color="success" circle outline>
                Success
              </CDBBtn>
              <CDBBtn color="danger" circle outline>
                Danger
              </CDBBtn>
              <CDBBtn color="dark" circle outline>
                Dark
              </CDBBtn>
              <CDBBtn color="warning" circle outline>
                Warning
              </CDBBtn>
              <CDBBtn color="info" outline circle>
                Info
              </CDBBtn>
            </div>
          </div>
          <div className="blockcode">
            <div className="header">Flat Buttons</div>
            <div className="description">Flat Buttons</div>
            <div className="example">
              <CDBBtn color="primary" flat>
                Primary
              </CDBBtn>
              <CDBBtn color="secondary" flat>
                Secondary
              </CDBBtn>
              <CDBBtn color="success" flat>
                Success
              </CDBBtn>
              <CDBBtn color="danger" flat>
                Danger
              </CDBBtn>
              <CDBBtn color="dark" flat>
                Dark
              </CDBBtn>
              <CDBBtn color="warning" flat>
                Warning
              </CDBBtn>
              <CDBBtn color="info" flat>
                Info
              </CDBBtn>
            </div>
          </div>

          <div className="blockcode">
            <div className="header">
              Label at the side{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge
                  size="small"
                  style={{
                    backgroundColor: "#9871ff",
                    borderRadius: "4px",
                    width: "fit-content",
                  }}
                >
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="example">
              <CDBBtn color="primary" label="right">
                Primary
              </CDBBtn>
              <CDBBtn color="secondary" label="right">
                Secondary
              </CDBBtn>
              <CDBBtn color="success" label="right">
                Success
              </CDBBtn>
              <CDBBtn color="danger">Danger</CDBBtn>
              <CDBBtn color="dark" label="left">
                Dark
              </CDBBtn>
              <CDBBtn color="warning" label="left">
                Warning
              </CDBBtn>
              <CDBBtn color="info" label="left">
                Info
              </CDBBtn>
            </div>
          </div>
          <div className="blockcode">
            <div className="header">
              Large Button With Ending Text{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="example flex-column">
              <CDBBtn size="xl" color="primary" endingText="top-left">
                Primary
              </CDBBtn>
              <CDBBtn size="xl" color="secondary" endingText="top-right">
                Secondary
              </CDBBtn>
              <CDBBtn size="xl" color="success" endingText="bottom-left">
                Success
              </CDBBtn>
              <CDBBtn size="xl" color="danger" endingText="bottom-right">
                Danger
              </CDBBtn>
              <CDBBtn size="xl" color="dark" endingText="top">
                Dark
              </CDBBtn>
              <CDBBtn size="xl" color="warning" endingText="bottom">
                Warning
              </CDBBtn>
              <CDBBtn size="xl" color="info" endingText="center">
                Info
              </CDBBtn>
            </div>
          </div>

          <div className="blockcode">
            <div className="header">Block Buttons</div>
            <div className="description">Block Buttons</div>
            <div className="example">
              <CDBBtn color="primary" block>
                Primary
              </CDBBtn>
            </div>
            <div className="example">
              <CDBBtn color="secondary" block>
                Secondary
              </CDBBtn>
            </div>
            <div className="example">
              <CDBBtn color="success" block>
                Success
              </CDBBtn>
            </div>
            <div className="example">
              <CDBBtn color="danger" block>
                Danger
              </CDBBtn>
            </div>
            <div className="example">
              <CDBBtn color="dark" block>
                Dark
              </CDBBtn>
            </div>
          </div>
          <div className="blockcode">
            <div className="header">Buttons Sizes</div>
            <div className="description"> Buttons Sizes</div>
            <div className="example">
              <CDBBtn color="primary" size="large">
                Large Button
              </CDBBtn>
              <CDBBtn color="secondary">Normal button</CDBBtn>
              <CDBBtn color="success" size="small">
                Small button
              </CDBBtn>
            </div>
          </div>
          <div className="blockcode">
            <div className="header">
              Buttons with Icons{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="description"> Buttons with Icons</div>
            <div className="example">
              <CDBBtn color="warning">
                <CDBIcon icon="magic" className="ml-1" />
                Warning
              </CDBBtn>
              <CDBBtn color="info">
                Info
                <CDBIcon icon="magic" className="mr-1" />
              </CDBBtn>
            </div>
          </div>
          <div className="blockcode">
            <div className="header">Disabled Button</div>
            <div className="description">Disabled Buttons</div>
            <div className="example">
              <CDBBtn color="secondary" disabled>
                Disabled Button
              </CDBBtn>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
