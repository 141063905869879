import React, { Fragment } from "react";
import {
  CDBLink,
  CDBIcon,
  CDBCard,
  CDBBtn,
  CDBInput,
  CDBCardBody,
  CDBNavbar,
  CDBNavBrand,
} from "cdbreact";

export const CreateAccount = () => {
  return (
    <Fragment>
      <CDBNavbar
        className="px-0 mx-auto bg-transparent"
        style={{ width: "80%" }}
        light
        expand="md"
        scrolling
      >
        <CDBNavBrand href="/" className="d-flex align-items-center">
          <img alt="logo" src="/img/pages/logo.png" width="25px" />
          <span className="ms-3 h4 my-0 font-weight-bold">Devwares</span>
        </CDBNavBrand>
      </CDBNavbar>
      <div className="d-flex align-items-center justify-content-center">
        <CDBCard
          style={{ width: "80%", maxWidth: "375px" }}
          className="border-0"
        >
          <CDBCardBody className="my-3 p-0 mx-auto">
            <div className="mt-4 mb-5">
              <p className="h4 text-center font-weight-bold">Create Account</p>
            </div>
            <div className="d-flex my-3">
              <CDBBtn
                color="primary"
                style={{ width: "80%", border: "none" }}
                className="me-1"
              >
                <CDBIcon className="float-left mt-1" fab icon="facebook-f" />
                Continue with facebook
              </CDBBtn>
              <CDBBtn color="info" style={{ border: "none" }}>
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
            </div>
            <div className="d-flex align-items-center mb-3">
              <span style={{ border: "0.5px solid black", width: "45%" }} />
              <span className="text-center" style={{ width: "10%" }}>
                OR
              </span>
              <span style={{ border: "0.5px solid black", width: "45%" }} />
            </div>
            <div style={{ minWidth: "250px" }}>
              <CDBInput
                id="defaultRegisterUsername"
                className="mt-n3"
                background
                placeholder="Username"
                type="text"
              />
              <CDBInput
                id="defaultRegisterPassword"
                className="mt-n3"
                background
                placeholder="Password"
                type="password"
              />
            </div>
            <CDBBtn color="dark" className="btn-block w-100 mt-5">
              Sign up
            </CDBBtn>
            <p className="text-muted mt-4 text-center">
              By clicking sign up you agree to the Contrast
              <CDBLink
                style={{ fontWeight: "600" }}
                className="d-inline p-0 text-dark"
                to="#"
              >
                {" "}
                terms and conditions
              </CDBLink>
            </p>
            <p className="text-muted text-center my-5">
              Have an Account?{" "}
              <CDBLink
                style={{ fontWeight: "600" }}
                className="d-inline p-0 text-dark"
                to="#"
              >
                Sign in
              </CDBLink>
            </p>
          </CDBCardBody>
        </CDBCard>
      </div>
    </Fragment>
  );
};
