import React from "react";
import {
  CDBBtn,
  CDBCard,
  CDBCardBody,
  CDBCardTitle,
  CDBCardText,
  CDBIcon,
  CDBPane,
  CDBPanel,
  CDBPanelTitle,
  CDBPanelText,
  CDBPaneImage,
  CDBFooter,
  CDBLink,
  CDBProgress,
  CDBCircularProgress,
} from "cdbreact";
import { Line } from "react-chartjs-2";
import { buildStyles } from "react-circular-progressbar";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./ProDashboard3.css";

export const ProDashboard3 = () => {
  const data = {
    chart1: {
      labels: [
        "Eating",
        "Drinking",
        "Sleeping",
        "Designing",
        "Coding",
        "Cycling",
        "Running",
      ],
      datasets: [
        {
          label: "My Dataset",
          pointRadius: 0,
          backgroundColor: "rgba(47, 128, 237, 0.3)",
          fill: "origin",
          borderColor: "rgb(47, 128, 237)",
          borderWidth: 2,
          data: [3.5, 3.8, 4.5, 5, 6, 8, 10],
        },
      ],
    },
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="prodashboard3">
      <div className="d-flex">
        <div style={{ height: "100vh", overflow: "hidden initial" }}>
          <Sidebar bgColor="#fff" color="#333" />
        </div>
        <div
          className="text-dark"
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div className="scroll-container">
              <div className="cards-container">
                <div className="card-container1">
                  <CDBCard className="card-boundary card1 p-4">
                    <CDBCardBody className="d-flex flex-column">
                      <CDBBtn
                        flat
                        className="ms-auto p-2 bg-dark text-white border-0"
                        style={{ fontSize: "0.8em" }}
                      >
                        View Details
                        <CDBIcon className="ms-2" fas icon="arrow-right" />
                      </CDBBtn>
                      <div className="d-flex flex-wrap my-3">
                        <CDBCircularProgress
                          value={60}
                          max={100}
                          min={0}
                          className="m-0 circularProgress"
                          text="80%"
                          background
                          strokeWidth={5}
                          styles={buildStyles({
                            backgroundColor: "#BFF2BF",
                            textColor: "#27AE60",
                            pathColor: "#27AE60",
                            trailColor: "transparent",
                            rotation: 0.5,
                          })}
                        ></CDBCircularProgress>
                        <div className="my-auto d-flex flex-column align-items-center text-end ms-auto">
                          <CDBCardTitle className="mb-2 card1-text font-weight-bold">
                            $30,000
                          </CDBCardTitle>
                          <CDBCardText
                            className="text-success mb-0 ms-3"
                            style={{ fontWeight: "600" }}
                          >
                            <span
                              className="ms-2"
                              style={{ fontWeight: "600", fontSize: "0.9em" }}
                            >
                              +27.4%
                            </span>
                            <span
                              style={{ fontSize: "0.8em" }}
                              className="mb-0 ms-3 text-dark d-block"
                            >
                              Since last month
                            </span>
                          </CDBCardText>
                        </div>
                      </div>
                    </CDBCardBody>
                  </CDBCard>
                  <CDBPanel className="card-boundary w-100 d-flex flex-column card-body mt-4">
                    <CDBPane>
                      <CDBPanelTitle style={{ fontWeight: "600" }}>
                        Messages
                      </CDBPanelTitle>
                      <CDBBtn
                        flat
                        className="ms-auto p-2 bg-dark text-white border-0"
                        style={{ fontSize: "0.8em" }}
                      >
                        View Details
                        <CDBIcon className="ms-2" fas icon="arrow-right" />
                      </CDBBtn>
                    </CDBPane>
                    <CDBPane className="mt-3" style={{ height: "38px" }}>
                      <CDBPaneImage
                        src="img/pane/pane2.png"
                        size="sm"
                        style={{ minWidth: "2rem", height: "auto" }}
                      />
                      <div className="ms-3 h-100 d-flex flex-column">
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText small className="mt-auto">
                          Hey, how are you.
                        </CDBPanelText>
                      </div>
                      <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                        <span style={{ fontSize: "0.8em" }}>Online</span>
                        <span className="ms-auto mt-auto p-1 small bg-secondary text-white rounded-circle w-50 text-center">
                          44
                        </span>
                      </CDBPanelText>
                    </CDBPane>
                    <CDBPane className="mt-3" style={{ height: "38px" }}>
                      <CDBPaneImage
                        src="img/pane/pane3.png"
                        size="sm"
                        style={{ minWidth: "2rem", height: "auto" }}
                      />
                      <div className="ms-3 h-100 d-flex flex-column">
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText small className="mt-auto">
                          Hey, how are you.
                        </CDBPanelText>
                      </div>
                      <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                        <span style={{ fontSize: "0.8em" }}>Online</span>
                        <span className="ms-auto mt-auto p-1 small bg-secondary text-white rounded-circle w-50 text-center">
                          44
                        </span>
                      </CDBPanelText>
                    </CDBPane>
                    <CDBPane className="mt-3" style={{ height: "38px" }}>
                      <CDBPaneImage
                        src="img/pane/pane4.png"
                        size="sm"
                        style={{ minWidth: "2rem", height: "auto" }}
                      />
                      <div className="ms-3 h-100 d-flex flex-column">
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText small className="mt-auto">
                          Hey, how are you.
                        </CDBPanelText>
                      </div>
                      <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                        <span style={{ fontSize: "0.8em" }}>Online</span>
                        <span className="ms-auto mt-auto p-1 small bg-secondary text-white rounded-circle w-50 text-center">
                          44
                        </span>
                      </CDBPanelText>
                    </CDBPane>
                    <CDBPane className="mt-3" style={{ height: "38px" }}>
                      <CDBPaneImage
                        src="img/pane/pane5.png"
                        size="sm"
                        style={{ minWidth: "2rem", height: "auto" }}
                      />
                      <div className="ms-3 h-100 d-flex flex-column">
                        <CDBPanelTitle style={{ fontWeight: "600" }}>
                          Mezue
                        </CDBPanelTitle>
                        <CDBPanelText small className="mt-auto">
                          Hey, how are you.
                        </CDBPanelText>
                      </div>
                      <CDBPanelText className="text-muted ms-auto d-flex flex-column h-100">
                        <span style={{ fontSize: "0.8em" }}>Online</span>
                        <span className="ms-auto mt-auto p-1 small bg-secondary text-white rounded-circle w-50 text-center">
                          44
                        </span>
                      </CDBPanelText>
                    </CDBPane>
                  </CDBPanel>
                  <CDBCard className="card-boundary mt-4 p-4">
                    <CDBCardBody className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <CDBCardTitle
                          className="h6"
                          style={{ fontWeight: "600" }}
                        >
                          Page Views
                        </CDBCardTitle>
                        <CDBBtn
                          flat
                          className="ms-auto p-2 bg-dark text-white border-0"
                          style={{ fontSize: "0.8em" }}
                        >
                          View Details
                          <CDBIcon className="ms-2" fas icon="arrow-right" />
                        </CDBBtn>
                      </div>
                      <div className="d-flex flex-column">
                        <CDBCardTitle className="mt-3 mb-0 h1 font-weight-bold">
                          10,000
                        </CDBCardTitle>
                        <span
                          className="text-success"
                          style={{ fontWeight: "600", fontSize: "0.9em" }}
                        >
                          +27.4%
                        </span>
                      </div>
                      <div style={{ margin: "-1.5rem 0 -0.77rem -0.75rem" }}>
                        <Line
                          height={180}
                          data={data.chart1}
                          options={options}
                        />
                      </div>
                    </CDBCardBody>
                  </CDBCard>
                </div>
                <div className="card-container2">
                  <CDBCard className="card-boundary card4 p-4">
                    <CDBCardBody>
                      <div className="d-flex align-items-center">
                        <CDBCardTitle
                          className="h6"
                          style={{ fontWeight: "600" }}
                        >
                          Total Orders
                        </CDBCardTitle>
                        <CDBBtn
                          flat
                          className="ms-auto p-2 bg-dark text-white border-0"
                          style={{ fontSize: "0.8em" }}
                        >
                          View Details
                          <CDBIcon className="ms-2" fas icon="arrow-right" />
                        </CDBBtn>
                      </div>
                      <div className="d-flex flex-wrap mx-3 mt-4">
                        <div className="progress-label text-warning">
                          <span
                            style={{
                              fontSize: "3.5em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span className="small">Bing</span>
                        </div>
                        <div
                          className="progress-label"
                          style={{ color: "#9B51E0" }}
                        >
                          <span
                            style={{
                              fontSize: "3.5em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span className="small">Yahoo</span>
                        </div>
                        <div className="progress-label text-primary">
                          <span
                            style={{
                              fontSize: "3.5em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span className="small">Google</span>
                        </div>
                        <div className="progress-label text-success">
                          <span
                            style={{
                              fontSize: "3.5em",
                              margin: "-2rem 0px -1.5rem 0px",
                            }}
                          >
                            &#8226;
                          </span>
                          <span className="small">Mozilla</span>
                        </div>
                      </div>
                      <div
                        className="progress-container"
                        style={{ marginTop: "1.8rem" }}
                      >
                        <span>
                          <CDBCircularProgress
                            value={60}
                            max={100}
                            min={0}
                            className="circularProgress2"
                            text="80%"
                            background
                            strokeWidth={4.5}
                            styles={buildStyles({
                              backgroundColor: "#FCF1CF",
                              textColor: "#F2C94C",
                              pathColor: "#F2C94C",
                              trailColor: "transparent",
                              rotation: 0.5,
                            })}
                          ></CDBCircularProgress>
                          <CDBCircularProgress
                            value={60}
                            max={100}
                            min={0}
                            className="circularProgress2"
                            text="80%"
                            background
                            strokeWidth={4.5}
                            styles={buildStyles({
                              backgroundColor: "#EDD6F5",
                              textColor: "#BB6BD9",
                              pathColor: "#BB6BD9",
                              trailColor: "transparent",
                              rotation: 0.5,
                            })}
                          ></CDBCircularProgress>
                        </span>
                        <span>
                          <CDBCircularProgress
                            value={60}
                            max={100}
                            min={0}
                            className="circularProgress2"
                            text="80%"
                            background
                            strokeWidth={4.5}
                            styles={buildStyles({
                              backgroundColor: "#B9D3F9",
                              textColor: "#2F80ED",
                              pathColor: "#2F80ED",
                              trailColor: "transparent",
                              rotation: 0.5,
                            })}
                          ></CDBCircularProgress>
                          <CDBCircularProgress
                            value={60}
                            max={100}
                            min={0}
                            className="circularProgress2"
                            text="80%"
                            background
                            strokeWidth={4.5}
                            styles={buildStyles({
                              backgroundColor: "#BFF2BF",
                              textColor: "#27AE60",
                              pathColor: "#27AE60",
                              trailColor: "transparent",
                              rotation: 0.5,
                            })}
                          ></CDBCircularProgress>
                        </span>
                      </div>
                      <CDBCardText className="mt-5 mb-4 text-muted small">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Fugit, error amet numquam iure provident voluptate
                        esse quasi, veritatis totam voluptas nostrum quisquam
                        eum porro a pariatur accusamus veniam.
                      </CDBCardText>
                    </CDBCardBody>
                  </CDBCard>
                  <CDBCard className="card-boundary card5 p-4">
                    <CDBCardBody>
                      <div className="d-flex align-items-center">
                        <CDBCardTitle
                          className="h6 mt-3"
                          style={{ fontWeight: "600" }}
                        >
                          Sales
                        </CDBCardTitle>
                        <CDBBtn
                          flat
                          className="ms-auto p-2 bg-dark text-white border-0"
                          style={{ fontSize: "0.8em" }}
                        >
                          View Details
                          <CDBIcon className="ms-2" fas icon="arrow-right" />
                        </CDBBtn>
                      </div>
                      <div className="mt-4 px-2">
                        <div className="d-flex align-items-center line-progress">
                          <CDBCardText className="small text-muted mb-0">
                            28 Jun 2019
                          </CDBCardText>
                          <CDBProgress value={80} colors="danger"></CDBProgress>
                        </div>
                        <div className="d-flex align-items-center line-progress">
                          <CDBCardText className="small text-muted mb-0">
                            28 Jun 2019
                          </CDBCardText>
                          <CDBProgress value={80} colors="danger"></CDBProgress>
                        </div>
                        <div className="d-flex align-items-center line-progress">
                          <CDBCardText className="small text-muted mb-0">
                            07 Jan 2019
                          </CDBCardText>
                          <CDBProgress value={50} colors="danger"></CDBProgress>
                        </div>
                        <div className="d-flex align-items-center line-progress">
                          <CDBCardText className="small text-muted mb-0">
                            18 May 2019
                          </CDBCardText>
                          <CDBProgress value={60} colors="danger"></CDBProgress>
                        </div>
                        <div className="d-flex align-items-center line-progress">
                          <CDBCardText className="small text-muted mb-0">
                            23 May 2019
                          </CDBCardText>
                          <CDBProgress value={40} colors="danger"></CDBProgress>
                        </div>
                        <div className="d-flex align-items-center line-progress">
                          <CDBCardText className="small text-muted mb-0">
                            17 Feb 2019
                          </CDBCardText>
                          <CDBProgress value={70} colors="danger"></CDBProgress>
                        </div>
                      </div>
                      <div className="ticks d-flex justify-content-between">
                        <p className="small text-muted rem">01</p>
                        <p className="small text-muted rem">02</p>
                        <p className="small text-muted">03</p>
                        <p className="small text-muted rem">04</p>
                        <p className="small text-muted rem">05</p>
                        <p className="small text-muted">06</p>
                        <p className="small text-muted rem">07</p>
                        <p className="small text-muted rem">08</p>
                        <p className="small text-muted">09</p>
                        <p className="small text-muted rem">10</p>
                        <p className="small text-muted rem">11</p>
                        <p className="small text-muted">12</p>
                        <p className="small text-muted rem">13</p>
                        <p className="small text-muted rem">14</p>
                        <p className="small text-muted">15</p>
                      </div>
                    </CDBCardBody>
                  </CDBCard>
                </div>
              </div>
              <CDBFooter>
                <div className="footer">
                  <CDBLink to="/" className="footer-link">
                    <img alt="logo" src="/img/pages/logo.png" width="25px" />
                    <span className="ms-4 mb-0 h5 font-weight-bold">
                      Devwares
                    </span>
                  </CDBLink>
                  <small>&copy; Devwares, 2020. All rights reserved.</small>
                  <div className="footer-rem d-flex">
                    <CDBBtn
                      flat
                      className="p-1 border-0"
                      style={{ background: "#4F4F4F" }}
                    >
                      <CDBIcon fab icon="facebook-f" />
                    </CDBBtn>
                    <CDBBtn
                      flat
                      className="mx-3 p-1 border-0"
                      style={{ background: "#4F4F4F" }}
                    >
                      <CDBIcon fab icon="twitter" />
                    </CDBBtn>
                    <CDBBtn
                      flat
                      className="p-1 border-0"
                      style={{ background: "#4F4F4F" }}
                    >
                      <CDBIcon fab icon="instagram" />
                    </CDBBtn>
                  </div>
                </div>
              </CDBFooter>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
