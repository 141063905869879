import React, { Component, Fragment } from "react";
import { CDBCollapse, CDBBtn } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export class Collapse extends Component {
  state = {
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  render() {
    return (
      <div className="d-flex w-100">
        <div style={{height:"100vh", overflow:"hidden initial"}}>
          <Sidebar/>
        </div>
        <Layout>
          <TemplateNav/>
      <div className="r-w">
        <div
          style={{
            margin: "auto",
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <CDBBtn
            color="primary"
            onClick={this.toggleCollapse("basicCollapse")}
            style={{ marginBottom: "1rem" }}
          >
            COLLAPSE BUTTON
          </CDBBtn>
          <CDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum
            </p>
          </CDBCollapse>
        </div>
      </div>
      </Layout>
    </div>
    );
  }
}
