import React from "react";
import { CDBSpinner } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Spinner = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div classname="r-w">
      <div className="blockcode">
        <div className="header">Spinner Types</div>
        <div className="description"></div>
        <div className="example">
          <CDBSpinner />
          <CDBSpinner danger />
          <CDBSpinner success />
          <CDBSpinner warning />
          <CDBSpinner info />
          <CDBSpinner dark />
          <CDBSpinner secondary />
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Multicolor Spinner</div>
        <div className="description"></div>
        <div className="example">
          <CDBSpinner multicolor />
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Size Variations</div>
        <div className="description"></div>
        <div className="example">
          <CDBSpinner size="big" />
          <CDBSpinner secondary />
          <CDBSpinner success size="small" />
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
