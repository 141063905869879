import React, { useState } from "react";
import {
  CDBContainer,
  CDBBtn,
  CDBCard,
  CDBCardBody,
  CDBCardImage,
  CDBCardTitle,
  CDBCardText,
  CDBView,
  CDBIframe,
  CDBIcon,
  CDBInput,
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBLink,
  CDBCollapse,
} from "cdbreact";
import "./About2.css";

export const About2 = () => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="about2">
      <header className="header">
        <CDBNavbar className="px-0 bg-transparent" light expand="md" scrolling>
          <CDBNavBrand href="/">
            <img alt="logo" src="/img/pages/logo.png" width="25px" />
          </CDBNavBrand>
          <CDBNavToggle
            onClick={() => {
              setCollapse(!collapse);
            }}
          />
          <CDBCollapse id="navbarCollapse1" isOpen={collapse} navbar>
            <CDBNavbarNav left>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Resources</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Blog</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Contact</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Support</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
            <CDBNavbarNav right>
              <CDBNavItem className="d-flex align-items-center ms-3">
                <CDBNavLink to="#">
                  <CDBIcon fas icon="moon" />
                </CDBNavLink>
              </CDBNavItem>
              <CDBNavItem active>
                <CDBBtn
                  flat
                  outline
                  className="d-flex align-items-center py-1 px-3 m-0 bg-transparent"
                  style={{ borderColor: "#0000cc" }}
                >
                  <CDBNavLink style={{ color: "#0000cc" }} to="#">
                    Hi, Sammy
                  </CDBNavLink>
                  <CDBIcon style={{ color: "#0000cc" }} icon="location-arrow" />
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
          </CDBCollapse>
        </CDBNavbar>
      </header>
      <div className="d-flex align-items-center">
        <CDBCardTitle className="page-title">About Us</CDBCardTitle>
        <img alt="Background" src="img/pages/about2.png" className="image" />
      </div>

      <section className="mb-5 text-center">
        <CDBCardTitle className="intro-title big">
          Our Amazing Team
        </CDBCardTitle>
        <CDBCardText>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
          amet numquam iure provident voluptate esse quasi, veritatis totam
          voluptas nostrum quisquam eum porro a pariatur accusamus veniam.
        </CDBCardText>
      </section>

      <section className="card-group">
        <CDBCard className="card">
          <CDBCardImage className="img-fluid w-100" src="img/pages/card1.png" />
          <CDBCardBody className="py-0">
            <CDBCardTitle className="card-title mt-0">
              Clark Williams
            </CDBCardTitle>
            <CDBCardText muted>CEO/Founder</CDBCardText>
            <CDBCardText muted className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
              error amet numquam iure provident voluptate esse quasi.
            </CDBCardText>
            <div className="mt-3 d-flex">
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="mx-2 p-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </div>
          </CDBCardBody>
        </CDBCard>
        <CDBCard className="card card-right">
          <CDBCardImage className="img-fluid w-100" src="img/pages/card2.png" />
          <CDBCardBody className="py-0">
            <CDBCardTitle className="card-title mt-0">
              Madison Jane
            </CDBCardTitle>
            <CDBCardText muted>CEO/Founder</CDBCardText>
            <CDBCardText muted className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
              error amet numquam iure provident voluptate esse quasi.
            </CDBCardText>
            <div className="mt-3 d-flex">
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2 mx-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </div>
          </CDBCardBody>
        </CDBCard>
        <CDBCard className="card">
          <CDBCardImage className="img-fluid w-100" src="img/pages/card3.png" />
          <CDBCardBody className="py-0">
            <CDBCardTitle className="card-title mt-0">
              Sarina Micheal
            </CDBCardTitle>
            <CDBCardText muted>CEO/Founder</CDBCardText>
            <CDBCardText muted className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
              error amet numquam iure provident voluptate esse quasi.
            </CDBCardText>
            <div className="mt-3 d-flex">
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2 mx-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </div>
          </CDBCardBody>
        </CDBCard>
        <CDBCard className="card card-right">
          <CDBCardImage className="img-fluid w-100" src="img/pages/card4.png" />
          <CDBCardBody className="py-0">
            <CDBCardTitle className="card-title mt-0">
              Stephanie Dani
            </CDBCardTitle>
            <CDBCardText muted>CEO/Founder</CDBCardText>
            <CDBCardText muted className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
              error amet numquam iure provident voluptate esse quasi.
            </CDBCardText>
            <div className="mt-3 d-flex">
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2 mx-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </div>
          </CDBCardBody>
        </CDBCard>
        <CDBCard className="card">
          <CDBCardImage className="img-fluid w-100" src="img/pages/card5.png" />
          <CDBCardBody className="py-0">
            <CDBCardTitle className="card-title mt-0">Tiff Amanda</CDBCardTitle>
            <CDBCardText muted>CEO/Founder</CDBCardText>
            <CDBCardText muted className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
              error amet numquam iure provident voluptate esse quasi.
            </CDBCardText>
            <div className="mt-3 d-flex">
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2 mx-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </div>
          </CDBCardBody>
        </CDBCard>
        <CDBCard className="card card-right">
          <CDBCardImage className="img-fluid w-100" src="img/pages/card6.png" />
          <CDBCardBody className="py-0">
            <CDBCardTitle className="card-title mt-0">Amara Wayne</CDBCardTitle>
            <CDBCardText muted>CEO/Founder</CDBCardText>
            <CDBCardText muted className="card-text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
              error amet numquam iure provident voluptate esse quasi.
            </CDBCardText>
            <div className="mt-3 d-flex">
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2 mx-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </div>
          </CDBCardBody>
        </CDBCard>
      </section>

      <section className="info align-items-center">
        <div>
          <CDBCardTitle className="mb-4 big">
            We create awesome products
          </CDBCardTitle>
          <CDBCardText>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
            error amet numquam iure provident voluptate esse quasi, veritatis
            totam voluptas nostrum quisquam eum porro a pariatur accusamus
            veniam.
          </CDBCardText>
        </div>
        <CDBView className="mt-5">
          <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
        </CDBView>
      </section>

      <section className="contact-section">
        <div className="contact">
          <CDBCardTitle
            className="font-weight-bold mb-5"
            style={{ width: "300px", lineHeight: "1.5em" }}
          >
            Want to get in touch?
          </CDBCardTitle>
          <CDBContainer>
            <CDBCardTitle className="mb-4">Contact Information</CDBCardTitle>
            <CDBCardText>
              <CDBIcon className="ms-4" icon="map-marker-alt" />
              New York, 94126 USA
            </CDBCardText>
            <CDBCardText>
              <CDBIcon className="ms-4" icon="phone-alt" />+ 01 234 567 89
            </CDBCardText>
            <CDBCardText>
              <CDBIcon className="ms-4" icon="comment-alt" />
              contact@example.com
            </CDBCardText>
          </CDBContainer>
        </div>
        <CDBContainer className="contact-form">
          <CDBInput
            id="contactName"
            placeholder="Name"
            background
            className="mt-n3"
            type="text"
          />
          <CDBInput
            id="contactEmail"
            placeholder="Email Address"
            background
            className="mt-n3"
            type="email"
          />
          <CDBInput
            id="contactMessage"
            height="150px"
            placeholder="Type Message"
            background
            className="mt-n3"
            type="textarea"
          />
          <CDBBtn color="dark" className="btn-block">
            Send Message
          </CDBBtn>
        </CDBContainer>
      </section>

      <footer className="footer mx-auto py-4">
        <div className="footer-head">
          <div className="align-self-center">
            <CDBLink to="/" className="d-flex align-items-center p-0 text-dark">
              <img alt="logo" src="/img/pages/logo.png" width="25px" />
              <span className="me-3 h5 font-weight-bold">Devwares</span>
            </CDBLink>
            <div className="mt-5 d-flex">
              <CDBBtn flat color="dark">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="mx-3">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </div>
          </div>
          <div>
            <p className="h5 mb-4" style={{ fontWeight: "600" }}>
              Devwares
            </p>
            <ul
              style={{ padding: "0px", listStyle: "none", cursor: "pointer" }}
            >
              <li className="my-2">
                <a className="text-dark" href="/">
                  Resources
                </a>
              </li>
              <li className="my-2">
                <a className="text-dark" href="/">
                  About Us
                </a>
              </li>
              <li className="my-2">
                <a className="text-dark" href="/">
                  Contact
                </a>
              </li>
              <li className="my-2">
                <a className="text-dark" href="/">
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p className="h5 mb-4" style={{ fontWeight: "600" }}>
              Help
            </p>
            <ul
              style={{ padding: "0px", listStyle: "none", cursor: "pointer" }}
            >
              <li className="my-2">
                <a className="text-dark" href="/">
                  Support
                </a>
              </li>
              <li className="my-2">
                <a className="text-dark" href="/">
                  Sign Up
                </a>
              </li>
              <li className="my-2">
                <a className="text-dark" href="/">
                  Sign In
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p className="h5 mb-4" style={{ fontWeight: "600" }}>
              Help
            </p>
            <ul
              style={{ padding: "0px", listStyle: "none", cursor: "pointer" }}
            >
              <li className="my-2">
                <a className="text-dark" href="/">
                  Support
                </a>
              </li>
              <li className="my-2">
                <a className="text-dark" href="/">
                  Sign Up
                </a>
              </li>
              <li className="my-2">
                <a className="text-dark" href="/">
                  Sign In
                </a>
              </li>
            </ul>
          </div>
        </div>
        <small className="text-center mt-5">
          &copy; Devwares, 2020. All rights reserved.
        </small>
      </footer>
    </div>
  );
};
