import React, { Fragment } from "react";
import { CDBAnimation } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Animation = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header">Animation Types</div>
        <div className="description"></div>
        <div className="example2">
          <CDBAnimation type="bounce" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="flash" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="pulse" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="rubberBand" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="shake" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="headShake" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="swing" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>

          <CDBAnimation type="tada" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="wobble" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="jello" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
          <CDBAnimation type="heartBeat" infinite>
            <img alt="" src="img/ball.png" width="50" height="50" />
          </CDBAnimation>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
