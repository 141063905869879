import React, { useState } from "react";
import {
  CDBContainer,
  CDBBtn,
  CDBCard,
  CDBCardImage,
  CDBCardTitle,
  CDBCardText,
  CDBView,
  CDBIframe,
  CDBIcon,
  CDBMask,
  CDBInput,
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBLink,
  CDBCollapse,
} from "cdbreact";
import "./About.css";

export const About = () => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="about">
      <div className="position-relative">
        <img alt="Background" src="img/pages/about.png" className="image" />
        <CDBMask className="mask" overlay="darkLight" />
        <header className="page-header">
          <CDBNavbar className="navigation px-0" dark expand="md" scrolling>
            <CDBNavBrand href="/">
              <img alt="logo" src="/img/pages/logo.png" width="25px" />
            </CDBNavBrand>
            <CDBNavToggle
              onClick={() => {
                setCollapse(!collapse);
              }}
            />
            <CDBCollapse
              id="navbarCollapse1"
              delay={0}
              isOpen={collapse}
              navbar
            >
              <CDBNavbarNav left>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Resources</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Blog</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Contact</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Support</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
              </CDBNavbarNav>
              <CDBNavbarNav right>
                <CDBNavItem className="me-3 d-flex align-items-center">
                  <CDBNavLink to="#">
                    <CDBIcon fas className="text-white moon" icon="moon" />
                  </CDBNavLink>
                </CDBNavItem>
                <CDBNavItem active>
                  <CDBBtn
                    flat
                    outline
                    className="d-flex align-items-center py-1 px-3 m-0 bg-transparent"
                    style={{ borderColor: "#0000cc" }}
                  >
                    <CDBNavLink style={{ color: "#0000cc" }} to="#">
                      Hi, Sammy
                    </CDBNavLink>
                    <CDBIcon
                      style={{ color: "#0000cc" }}
                      icon="location-arrow"
                    />
                  </CDBBtn>
                </CDBNavItem>
              </CDBNavbarNav>
            </CDBCollapse>
          </CDBNavbar>
          <CDBContainer className="about-container">
            <CDBCardTitle className="big">About Us</CDBCardTitle>
            <CDBCardText className="text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
              error amet numquam iure provident voluptate esse quasi, veritatis
              totam voluptas nostrum quisquam eum porro a pariatur accusamus
              veniam.
            </CDBCardText>
          </CDBContainer>
        </header>
      </div>
      <div className="body">
        <section className="mt-4rem mb-2">
          <CDBCardTitle className="intro-title big">
            Our Amazing Team
          </CDBCardTitle>
          <CDBCardText>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
            error amet numquam iure provident voluptate esse quasi, veritatis
            totam voluptas nostrum quisquam eum porro a pariatur accusamus
            veniam.
          </CDBCardText>
        </section>

        <section className="mt-4rem card-container">
          <div className="card-group">
            <CDBCard className="card border-0">
              <CDBCardImage
                className="img-fluid w-100"
                src="img/pages/card1.png"
              />
              <CDBCardTitle className="card-title">Clark Williams</CDBCardTitle>
              <CDBCardText muted>CEO/Founder</CDBCardText>
              <CDBCardText muted>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </CDBCardText>
              <div className="mt-auto d-flex">
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="facebook-f" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2 mx-2">
                  <CDBIcon fab icon="twitter" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
              </div>
            </CDBCard>
            <CDBCard className="card border-0">
              <CDBCardImage
                className="img-fluid w-100"
                src="img/pages/card2.png"
              />
              <CDBCardTitle className="card-title">Madison Jane</CDBCardTitle>
              <CDBCardText muted>CEO/Founder</CDBCardText>
              <CDBCardText muted className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </CDBCardText>
              <div className="mt-auto d-flex">
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="facebook-f" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2 mx-2">
                  <CDBIcon fab icon="twitter" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
              </div>
            </CDBCard>
            <CDBCard className="card border-0">
              <CDBCardImage
                className="img-fluid w-100"
                src="img/pages/card3.png"
              />
              <CDBCardTitle className="card-title">Sarina Micheal</CDBCardTitle>
              <CDBCardText muted>CEO/Founder</CDBCardText>
              <CDBCardText muted className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </CDBCardText>
              <div className="mt-auto d-flex">
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="facebook-f" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2 mx-2">
                  <CDBIcon fab icon="twitter" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
              </div>
            </CDBCard>
            <CDBCard className="card border-0">
              <CDBCardImage
                className="img-fluid w-100"
                src="img/pages/card4.png"
              />
              <CDBCardTitle className="card-title">Stephanie Dani</CDBCardTitle>
              <CDBCardText muted>CEO/Founder</CDBCardText>
              <CDBCardText muted className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </CDBCardText>
              <div className="mt-auto d-flex">
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="facebook-f" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2 mx-2">
                  <CDBIcon fab icon="twitter" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
              </div>
            </CDBCard>
            <CDBCard className="card border-0">
              <CDBCardImage
                className="img-fluid w-100"
                src="img/pages/card5.png"
              />
              <CDBCardTitle className="card-title">Tiff Amanda</CDBCardTitle>
              <CDBCardText muted>CEO/Founder</CDBCardText>
              <CDBCardText muted className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </CDBCardText>
              <div className="mt-auto d-flex">
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="facebook-f" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2 mx-2">
                  <CDBIcon fab icon="twitter" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
              </div>
            </CDBCard>
            <CDBCard className="card border-0">
              <CDBCardImage
                className="img-fluid w-100"
                src="img/pages/card6.png"
              />
              <CDBCardTitle className="card-title">Amara Wayne</CDBCardTitle>
              <CDBCardText muted>CEO/Founder</CDBCardText>
              <CDBCardText muted className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi.
              </CDBCardText>
              <div className="mt-auto d-flex">
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="facebook-f" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2 mx-2">
                  <CDBIcon fab icon="twitter" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
              </div>
            </CDBCard>
          </div>
        </section>

        <section className="mt-4rem info align-items-center">
          <div>
            <CDBCardTitle className="mb-4 big">
              We create awesome products
            </CDBCardTitle>
            <CDBCardText>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
              error amet numquam iure provident voluptate esse quasi, veritatis
              totam voluptas nostrum quisquam eum porro a pariatur accusamus
              veniam.
            </CDBCardText>
          </div>
          <CDBView>
            <CDBIframe src="https://www.youtube.com/embed/xnczyP2jSR0"></CDBIframe>
          </CDBView>
        </section>
        <section className="mt-4rem">
          <CDBCardTitle className="big contact-title">
            Want to get in touch?
          </CDBCardTitle>
          <div className="contact">
            <CDBContainer className="contact-form">
              <CDBInput
                id="contactName"
                placeholder="Name"
                background
                className="mt-n3"
                type="text"
              />
              <CDBInput
                id="contactEmail"
                placeholder="Email Address"
                background
                className="mt-n3"
                type="email"
              />
              <CDBInput
                id="contactMessage"
                height="150px"
                placeholder="Type Message"
                background
                className="mt-n3"
                type="textarea"
              />
              <CDBBtn color="dark" className="btn-block">
                Send Message
              </CDBBtn>
            </CDBContainer>
            <CDBContainer className="contact-info">
              <CDBCardTitle className="mb-5">Contact Information</CDBCardTitle>
              <CDBCardText>
                <CDBIcon className="me-5" icon="map-marker-alt" />
                New York, 94126 USA
              </CDBCardText>
              <CDBCardText>
                <CDBIcon className="me-5" icon="phone-alt" />+ 01 234 567 89
              </CDBCardText>
              <CDBCardText>
                <CDBIcon className="me-5" icon="comment-alt" />
                contact@example.com
              </CDBCardText>
            </CDBContainer>
          </div>
        </section>
      </div>
      <footer className="footer">
        <CDBLink to="/" className="footer-logo">
          <img alt="logo" src="/img/pages/logo.png" width="25px" />
          <span className="mb-0 ms-4 h5 font-weight-bold">Devwares</span>
        </CDBLink>
        <small>&copy; Devwares, 2020. All rights reserved.</small>
        <div className="footer-contact d-flex">
          <CDBBtn flat color="dark" className="p-2">
            <CDBIcon fab icon="facebook-f" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="p-2 mx-3">
            <CDBIcon fab icon="twitter" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="p-2">
            <CDBIcon fab icon="instagram" />
          </CDBBtn>
        </div>
      </footer>
    </div>
  );
};
