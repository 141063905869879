import React, { Fragment } from "react";
import { CDBPagination, CDBPageItem, CDBPageLink} from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Pagination = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div
        style={{
          margin: "auto",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <CDBPagination circle>
          <CDBPageLink>Prev</CDBPageLink>
          <CDBPageItem>1</CDBPageItem>
          <CDBPageItem>2</CDBPageItem>
          <CDBPageItem>3</CDBPageItem>
          <CDBPageLink>Next</CDBPageLink>
        </CDBPagination>
        <CDBPagination circle size="big" color="success">
          <CDBPageLink>Prev</CDBPageLink>
          <CDBPageItem>1</CDBPageItem>
          <CDBPageItem>2</CDBPageItem>
          <CDBPageItem>3</CDBPageItem>
          <CDBPageLink>Next</CDBPageLink>
        </CDBPagination>
        <CDBPagination size="small" color="secondary">
          <CDBPageLink>Prev</CDBPageLink>
          <CDBPageItem>1</CDBPageItem>
          <CDBPageItem>2</CDBPageItem>
          <CDBPageItem>3</CDBPageItem>
          <CDBPageLink>Next</CDBPageLink>
        </CDBPagination>
        <CDBPagination color="warning" sm>
          <CDBPageLink>Prev</CDBPageLink>
          <CDBPageItem>1</CDBPageItem>
          <CDBPageItem>2</CDBPageItem>
          <CDBPageItem>3</CDBPageItem>
          <CDBPageLink>Next</CDBPageLink>
        </CDBPagination>
        <CDBPagination color="white" sm>
          <CDBPageLink>Prev</CDBPageLink>
          <CDBPageItem>1</CDBPageItem>
          <CDBPageItem>2</CDBPageItem>
          <CDBPageItem>3</CDBPageItem>
          <CDBPageLink>Next</CDBPageLink>
        </CDBPagination>
        <CDBPagination color="light">
          <CDBPageLink>Prev</CDBPageLink>
          <CDBPageItem>1</CDBPageItem>
          <CDBPageItem>2</CDBPageItem>
          <CDBPageItem>3</CDBPageItem>
          <CDBPageLink>Next</CDBPageLink>
        </CDBPagination>
        <CDBPagination color="danger">
          <CDBPageLink>Prev</CDBPageLink>
          <CDBPageItem>1</CDBPageItem>
          <CDBPageItem>2</CDBPageItem>
          <CDBPageItem>3</CDBPageItem>
          <CDBPageLink>Next</CDBPageLink>
        </CDBPagination>
        <CDBPagination>
          <CDBPageLink>Prev</CDBPageLink>
          <CDBPageItem>1</CDBPageItem>
          <CDBPageItem>2</CDBPageItem>
          <CDBPageItem>3</CDBPageItem>
          <CDBPageLink>Next</CDBPageLink>
        </CDBPagination>
      </div>
    </div>
      </Layout>
    </div>
  );
};
