import React, { Fragment } from "react";
import { 
  CDBIcon,
  CDBDropDown,
  CDBDropDownToggle,
  CDBDropDownMenu,
  CDBDropDownItem,
  CDBBtn,
  CDBBtnGrp,
  CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const ButtonGroup = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header">Multicolored Button Groups</div>
        <div className="description">Rect Secondary Large</div>
        <div className="example">
          <CDBBtnGrp size="lg">
            <CDBBtn color="primary">Click me</CDBBtn>
            <CDBBtn color="secondary">Click me</CDBBtn>
            <CDBBtn color="success">Click me</CDBBtn>
            <CDBBtn color="danger">Click me</CDBBtn>
            <CDBBtn color="dark">Click me</CDBBtn>
            <CDBBtn color="warning">Click me</CDBBtn>
          </CDBBtnGrp>
        </div>

        <div className="description">Rect Secondary Medium with Space between</div>
        <div className="example">
          <CDBBtnGrp>
            <CDBBtn color="primary">Click me</CDBBtn>
            <CDBBtn color="secondary">Click me</CDBBtn>
            <CDBBtn color="success">Click me</CDBBtn>
          </CDBBtnGrp>
          <CDBBtnGrp>
            <CDBBtn color="danger">Click me</CDBBtn>
            <CDBBtn color="dark">Click me</CDBBtn>
            <CDBBtn color="warning">Click me</CDBBtn>
          </CDBBtnGrp>
        </div>

        <div className="description">Rect Secondary Small</div>
        <div className="example">
          <CDBBtnGrp size="sm">
            <CDBBtn color="primary">Click me</CDBBtn>
            <CDBBtn color="secondary">Click me</CDBBtn>
            <CDBBtn color="success">Click me</CDBBtn>
            <CDBBtn color="danger">Click me</CDBBtn>
            <CDBBtn color="dark">Click me</CDBBtn>
            <CDBBtn color="warning">Click me</CDBBtn>
          </CDBBtnGrp>
        </div>
      </div>

      <div className="blockcode">
        <div className="description">Vertical Rect Secondary Large</div>
        <div className="example">
          <CDBBtnGrp vertical size="lg">
            <CDBBtn color="primary">Click me</CDBBtn>
            <CDBBtn color="secondary">Click me</CDBBtn>
            <CDBBtn color="success">Click me</CDBBtn>
            <CDBBtn color="danger">Click me</CDBBtn>
            <CDBBtn color="dark">Click me</CDBBtn>
            <CDBBtn color="warning">Click me</CDBBtn>
          </CDBBtnGrp>
        </div>

        <div className="description">Vertical Rect Secondary Medium</div>
        <div className="example">
          <CDBBtnGrp vertical>
            <CDBBtn color="primary">Click me</CDBBtn>
            <CDBBtn color="secondary">Click me</CDBBtn>
            <CDBBtn color="success">Click me</CDBBtn>
            <CDBBtn color="danger">Click me</CDBBtn>
            <CDBBtn color="dark">Click me</CDBBtn>
            <CDBBtn color="warning">Click me</CDBBtn>
          </CDBBtnGrp>
        </div>

        <div className="description">Vertical Rect Secondary Small</div>
        <div className="example">
          <CDBBtnGrp vertical size="sm">
            <CDBBtn color="primary">Click me</CDBBtn>
            <CDBBtn color="secondary">Click me</CDBBtn>
            <CDBBtn color="success">Click me</CDBBtn>
            <CDBBtn color="danger">Click me</CDBBtn>
            <CDBBtn color="dark">Click me</CDBBtn>
            <CDBBtn color="warning">Click me</CDBBtn>
          </CDBBtnGrp>
        </div>

        <div className="description">Button Group With Icon 
          <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white">
            <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge>
          </a>
        </div>
        <div className="example">
          <CDBBtnGrp>
            <CDBBtn color="primary"><CDBIcon fab icon="twitter"/></CDBBtn>
            <CDBBtn color="secondary"><CDBIcon fab icon="facebook"/></CDBBtn>
            <CDBBtn color="success"><CDBIcon fab icon="reddit"/></CDBBtn>
            <CDBBtn color="danger"><CDBIcon fab icon="instagram"/></CDBBtn>
            <CDBBtn color="dark"><CDBIcon fab icon="imdb"/></CDBBtn>
            <CDBBtn color="warning"><CDBIcon fab icon="stack-overflow"/></CDBBtn>
          </CDBBtnGrp>
        </div>

        <div className="description">Button Group With DropDown</div>
        <div className="example">
          <CDBBtnGrp>
            <CDBBtn color="primary">Click me</CDBBtn>
            <CDBBtn color="secondary">Click me</CDBBtn>
            <CDBBtn color="success">Click me</CDBBtn>
          </CDBBtnGrp>
          <CDBBtnGrp className="ml-2">
            <CDBDropDown>
              <CDBDropDownToggle color="warning">Dropdown<CDBIcon fas icon="caret-down"/></CDBDropDownToggle>
              <CDBDropDownMenu dropright>
                <CDBDropDownItem>Action 1</CDBDropDownItem>
                <CDBDropDownItem>Action 2</CDBDropDownItem>
              </CDBDropDownMenu>
            </CDBDropDown>
          </CDBBtnGrp>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
