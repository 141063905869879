import React, { Fragment } from "react";
import { 
  CDBPanel,
  CDBPane,
  CDBPaneImage,
  CDBPanelText,
  CDBPanelTitle,
  CDBIcon,
  CDBDropDown,
  CDBDropDownToggle,
  CDBDropDownMenu,
  CDBDropDownItem,
  CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const Pane = () => {

  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div
        style={{
          margin: "auto",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div className="blockcode">
          <div className="header  d-flex">Basic Styles <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Pane with Location</div>
          <div className="example">
            <CDBPanel style={{width:"400px"}}>
              <CDBPane>
                <CDBPaneImage size="md" src="/img/pane/pane1.png" />
                <div className="ml-3">
                  <CDBPanelTitle>Warren Briggs</CDBPanelTitle>
                  <CDBPanelText small>Australia</CDBPanelText>
                </div>
                <p className="ml-auto mb-0 text-danger">{"300m"} <CDBIcon fas icon="map-marker-alt" /></p>     
              </CDBPane>
            </CDBPanel>
          </div>
        </div>
        
        <div className="blockcode">
          <div className="description  d-flex">Pane with Dropdown <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="example">
            <CDBPanel style={{width:"400px", padding:"10px"}}>
              <CDBPane>
                <CDBPaneImage size="md" src="/img/pane/pane1.png" />
                <div className="ml-3">
                  <CDBPanelTitle>Warren Briggs</CDBPanelTitle>
                  <CDBPanelText small>Breakfast</CDBPanelText>
                </div>
                <CDBDropDown className="ml-auto">
                  <CDBDropDownToggle color="white"><CDBIcon className="text-muted" fas icon="ellipsis-h"/></CDBDropDownToggle>
                  <CDBDropDownMenu>
                    <CDBDropDownItem disabled>Edit Profile</CDBDropDownItem>
                    <CDBDropDownItem divider />
                    <CDBDropDownItem>Action 1</CDBDropDownItem>
                    <CDBDropDownItem>Action 2</CDBDropDownItem>
                  </CDBDropDownMenu>
                </CDBDropDown>          
              </CDBPane>
            </CDBPanel>
          </div>
        </div>

      </div>
    </div>
      </Layout>
    </div>
  );
};
