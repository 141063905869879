import React, { Fragment } from "react";
import {
  CDBFooter,
  CDBFooterLink,
  CDBBtn,
  CDBBadge,
  CDBIcon,
  CDBLink,
} from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Footer = () => {
  return (
    <div className="d-flex w-100">
      <div style={{ height: "100vh", overflow: "hidden initial" }}>
        <Sidebar />
      </div>
      <Layout>
        <TemplateNav />
        <div className="r-w">
          <div className="blockcode">
            <div className="header">
              Footer{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="description">Footer</div>
            <CDBFooter className="shadow">
              <div
                className="d-flex flex-column mx-auto py-5"
                style={{ width: "80%" }}
              >
                <div className="d-flex justify-content-between flex-wrap">
                  <div>
                    <a
                      href="/"
                      className="d-flex align-items-center p-0 text-dark"
                    >
                      <img
                        className="me-2"
                        alt="logo"
                        src="/img/pages/logo.png"
                        width="30px"
                      />
                      <span className="ms-3 h5 font-weight-bold">Devwares</span>
                    </a>
                    <p className="my-3" style={{ width: "250px" }}>
                      We are creating High Quality Resources and tools to Aid
                      developers during the developement of their projects
                    </p>
                    <div className="mt-4 d-flex">
                      <CDBBtn flat color="dark">
                        <CDBIcon fab icon="facebook-f" />
                      </CDBBtn>
                      <CDBBtn flat color="dark" className="mx-3">
                        <CDBIcon fab icon="twitter" />
                      </CDBBtn>
                      <CDBBtn flat color="dark">
                        <CDBIcon fab icon="instagram" />
                      </CDBBtn>
                    </div>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Devwares
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Resources</CDBFooterLink>
                      <CDBFooterLink href="/">About Us</CDBFooterLink>
                      <CDBFooterLink href="/">Contact</CDBFooterLink>
                      <CDBFooterLink href="/">Blog</CDBFooterLink>
                    </ul>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Help
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Support</CDBFooterLink>
                      <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                      <CDBFooterLink href="/">Sign In</CDBFooterLink>
                    </ul>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Help
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Support</CDBFooterLink>
                      <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                      <CDBFooterLink href="/">Sign In</CDBFooterLink>
                    </ul>
                  </div>
                </div>
                <small className="text-center mt-5">
                  &copy; Devwares, 2020. All rights reserved.
                </small>
              </div>
            </CDBFooter>
          </div>
          <div className="blockcode">
            <div className="header">
              Footer{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="description">Footer</div>
            <CDBFooter className="shadow">
              <div
                className="d-flex flex-column mx-auto py-5"
                style={{ width: "80%" }}
              >
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="align-self-center">
                    <a
                      href="/"
                      className="d-flex align-items-center p-0 text-dark"
                    >
                      <img
                        className="me-2"
                        alt="logo"
                        src="/img/pages/logo.png"
                        width="30px"
                      />
                      <span className="ms-3 h5 font-weight-bold">Devwares</span>
                    </a>
                    <div className="mt-5 d-flex">
                      <CDBBtn flat color="dark">
                        <CDBIcon fab icon="facebook-f" />
                      </CDBBtn>
                      <CDBBtn flat color="dark" className="mx-3">
                        <CDBIcon fab icon="twitter" />
                      </CDBBtn>
                      <CDBBtn flat color="dark">
                        <CDBIcon fab icon="instagram" />
                      </CDBBtn>
                    </div>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Devwares
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Resources</CDBFooterLink>
                      <CDBFooterLink href="/">About Us</CDBFooterLink>
                      <CDBFooterLink href="/">Contact</CDBFooterLink>
                      <CDBFooterLink href="/">Blog</CDBFooterLink>
                    </ul>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Help
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Support</CDBFooterLink>
                      <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                      <CDBFooterLink href="/">Sign In</CDBFooterLink>
                    </ul>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Help
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Support</CDBFooterLink>
                      <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                      <CDBFooterLink href="/">Sign In</CDBFooterLink>
                    </ul>
                  </div>
                </div>
                <small className="text-center mt-5">
                  &copy; Devwares, 2020. All rights reserved.
                </small>
              </div>
            </CDBFooter>
          </div>
          <div className="blockcode">
            <div className="header">
              Footer{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="description">Footer</div>
            <CDBFooter className="shadow">
              <div
                className="d-flex flex-column mx-auto py-5"
                style={{ width: "80%" }}
              >
                <div className="d-flex justify-content-between flex-wrap">
                  <div>
                    <a
                      href="/"
                      className="d-flex align-items-center p-0 text-dark"
                    >
                      <img
                        className="me-2"
                        alt="logo"
                        src="/img/pages/logo.png"
                        width="30px"
                      />
                      <span className="ms-3 h5 font-weight-bold">Devwares</span>
                    </a>
                    <p className="my-3" style={{ width: "250px" }}>
                      We are creating High Quality Resources and tools to Aid
                      developers during the developement of their projects
                    </p>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Devwares
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Resources</CDBFooterLink>
                      <CDBFooterLink href="/">About Us</CDBFooterLink>
                      <CDBFooterLink href="/">Contact</CDBFooterLink>
                      <CDBFooterLink href="/">Blog</CDBFooterLink>
                    </ul>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Help
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Support</CDBFooterLink>
                      <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                      <CDBFooterLink href="/">Sign In</CDBFooterLink>
                    </ul>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Help
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Support</CDBFooterLink>
                      <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                      <CDBFooterLink href="/">Sign In</CDBFooterLink>
                    </ul>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <div className="mb-4 d-flex">
                    <CDBBtn flat color="dark">
                      <CDBIcon fab icon="facebook-f" />
                    </CDBBtn>
                    <CDBBtn flat color="dark" className="mx-3">
                      <CDBIcon fab icon="twitter" />
                    </CDBBtn>
                    <CDBBtn flat color="dark">
                      <CDBIcon fab icon="instagram" />
                    </CDBBtn>
                  </div>
                  <small>&copy; Devwares, 2020. All rights reserved.</small>
                </div>
              </div>
            </CDBFooter>
          </div>
          <div className="blockcode">
            <div className="header">
              Footer{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="description">Footer</div>
            <CDBFooter className="shadow">
              <div
                className="d-flex flex-column mx-auto py-5"
                style={{ width: "80%" }}
              >
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="align-self-center">
                    <a
                      href="/"
                      className="d-flex align-items-center p-0 text-dark"
                    >
                      <img
                        className="me-2"
                        alt="logo"
                        src="/img/pages/logo.png"
                        width="30px"
                      />
                      <span className="ms-4 h5 font-weight-bold">Devwares</span>
                    </a>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Devwares
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Resources</CDBFooterLink>
                      <CDBFooterLink href="/">About Us</CDBFooterLink>
                      <CDBFooterLink href="/">Contact</CDBFooterLink>
                      <CDBFooterLink href="/">Blog</CDBFooterLink>
                    </ul>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Help
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Support</CDBFooterLink>
                      <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                      <CDBFooterLink href="/">Sign In</CDBFooterLink>
                    </ul>
                  </div>
                  <div>
                    <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                      Help
                    </p>
                    <ul
                      style={{
                        listStyle: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <CDBFooterLink href="/">Support</CDBFooterLink>
                      <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                      <CDBFooterLink href="/">Sign In</CDBFooterLink>
                    </ul>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <small>&copy; Devwares, 2020. All rights reserved.</small>
                  <div className="d-flex">
                    <CDBBtn flat color="dark">
                      <CDBIcon fab icon="facebook-f" />
                    </CDBBtn>
                    <CDBBtn flat color="dark" className="mx-3">
                      <CDBIcon fab icon="twitter" />
                    </CDBBtn>
                    <CDBBtn flat color="dark">
                      <CDBIcon fab icon="instagram" />
                    </CDBBtn>
                  </div>
                </div>
              </div>
            </CDBFooter>
          </div>
          <div className="blockcode">
            <div className="header">Footer</div>
            <div className="description">FREE Footer</div>
            <footer className="shadow">
              <div
                className="d-flex justify-content-between align-items-center mx-auto py-4 flex-wrap"
                style={{ width: "80%" }}
              >
                <div className="d-flex align-items-center">
                  <CDBLink
                    to="/"
                    className="d-flex align-items-center p-0 text-dark"
                  >
                    <img
                      className="me-2"
                      alt="logo"
                      src="/img/pages/logo.png"
                      width="30px"
                    />
                    <span className="ms-4 h5 mb-0 font-weight-bold">
                      Devwares
                    </span>
                  </CDBLink>
                  <span
                    style={{
                      fontSize: "3em",
                      margin: "-2rem 0px -1.5rem 1rem",
                      color: "#C4C4C4",
                    }}
                  >
                    &#8226;
                  </span>
                  <small className="ms-2">
                    &copy; Devwares, 2020. All rights reserved.
                  </small>
                </div>
                <div className=" d-flex">
                  <CDBBtn flat color="dark">
                    <i class="fab fa-facebook-f"></i>
                  </CDBBtn>
                  <CDBBtn flat color="dark" className="mx-3">
                    <i class="fab fa-twitter"></i>
                  </CDBBtn>
                  <CDBBtn flat color="dark">
                    <i class="fab fa-instagram"></i>
                  </CDBBtn>
                </div>
              </div>
            </footer>
          </div>
          <div className="blockcode">
            <div className="header">
              Footer{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="description">Footer</div>
            <CDBFooter className="shadow">
              <div
                className="d-flex flex-column mx-auto py-5"
                style={{ width: "80%" }}
              >
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="align-self-center">
                    <a
                      href="/"
                      className="d-flex align-items-center p-0 text-dark"
                    >
                      <img
                        className="me-2"
                        alt="logo"
                        src="/img/pages/logo.png"
                        width="30px"
                      />
                      <span className="ms-4 h5 font-weight-bold">Devwares</span>
                    </a>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "40%" }}
                  >
                    <div>
                      <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                        Devwares
                      </p>
                      <ul
                        style={{
                          listStyle: "none",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <CDBFooterLink href="/">Resources</CDBFooterLink>
                        <CDBFooterLink href="/">About Us</CDBFooterLink>
                        <CDBFooterLink href="/">Contact</CDBFooterLink>
                        <CDBFooterLink href="/">Blog</CDBFooterLink>
                      </ul>
                    </div>
                    <div>
                      <p className="h5 mb-4" style={{ fontWeight: "600" }}>
                        Help
                      </p>
                      <ul
                        style={{
                          listStyle: "none",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <CDBFooterLink href="/">Support</CDBFooterLink>
                        <CDBFooterLink href="/">Sign Up</CDBFooterLink>
                        <CDBFooterLink href="/">Sign In</CDBFooterLink>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <small>&copy; Devwares, 2020. All rights reserved.</small>
                  <div className=" d-flex">
                    <CDBBtn flat color="dark">
                      <CDBIcon fab icon="facebook-f" />
                    </CDBBtn>
                    <CDBBtn flat color="dark" className="mx-3">
                      <CDBIcon fab icon="twitter" />
                    </CDBBtn>
                    <CDBBtn flat color="dark">
                      <CDBIcon fab icon="instagram" />
                    </CDBBtn>
                  </div>
                </div>
              </div>
            </CDBFooter>
          </div>
          <div className="blockcode">
            <div className="header">
              Footer{" "}
              <a
                href="https://devwares.com/product/contrast-pro"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>
                  Pro Component
                </CDBBadge>
              </a>
            </div>
            <div className="description">Footer</div>
            <CDBFooter className="shadow">
              <div
                className="d-flex justify-content-between align-items-center mx-auto py-4 flex-wrap"
                style={{ width: "80%" }}
              >
                <a href="/" className="d-flex align-items-center p-0 text-dark">
                  <img
                    className="me-2"
                    alt="logo"
                    src="/img/pages/logo.png"
                    width="30px"
                  />
                  <span className="ms-4 h5 font-weight-bold">Devwares</span>
                </a>
                <small>&copy; Devwares, 2020. All rights reserved.</small>
                <div className=" d-flex">
                  <CDBBtn flat color="dark">
                    <CDBIcon fab icon="facebook-f" />
                  </CDBBtn>
                  <CDBBtn flat color="dark" className="mx-3">
                    <CDBIcon fab icon="twitter" />
                  </CDBBtn>
                  <CDBBtn flat color="dark">
                    <CDBIcon fab icon="instagram" />
                  </CDBBtn>
                </div>
              </div>
            </CDBFooter>
          </div>
        </div>
      </Layout>
    </div>
  );
};
