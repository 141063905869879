import React, { Fragment } from "react";
import {
  CDBPanel,
  CDBPanelTitle,
  CDBPanelText,
  CDBPane,
  CDBPaneImage,
  CDBCardImage,
  CDBIcon,
  CDBBadge,
  CDBProgress,
  CDBDropDown,
  CDBDropDownToggle,
  CDBDropDownMenu,
  CDBDropDownItem
} from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const Panel = () => {

  return (
    <div className="d-flex w-100">
      <div style={{ height: "100vh", overflow: "hidden initial" }}>
        <Sidebar />
      </div>
      <Layout>
        <TemplateNav />
        <div className="r-w">
          <div
            style={{
              margin: "auto",
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",

            }}
          >
            <div className="blockcode" style={{ borderRadius: "15px" }}>
              <div className="description d-flex">Panel with Dropdown <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
              <div className="example">
                <CDBPanel style={{ width: "22rem", borderRadius: "15px" }}>
                  <CDBPane>
                    <CDBPaneImage size="md" src="/img/pane/pane1.png" />
                    <div className="ml-3">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>Warren Briggs</CDBPanelTitle>
                      <CDBPanelText small>Chicago</CDBPanelText>
                    </div>
                    <CDBDropDown className="ml-auto">
                      <CDBDropDownToggle color="white"><CDBIcon className="text-muted" fas icon="ellipsis-h" /></CDBDropDownToggle>
                      <CDBDropDownMenu>
                        <CDBDropDownItem disabled>Edit Profile</CDBDropDownItem>
                        <CDBDropDownItem divider />
                        <CDBDropDownItem>Action 1</CDBDropDownItem>
                        <CDBDropDownItem>Action 2</CDBDropDownItem>
                      </CDBDropDownMenu>
                    </CDBDropDown>
                  </CDBPane>
                  <CDBPanelText className="my-3">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  </CDBPanelText>
                  <CDBPanelText style={{ fontWeight: "600" }} small>
                    <CDBIcon far icon="thumbs-up" />127 likes
                    <CDBIcon className="ml-2" far icon="comment" />54 comments
                  </CDBPanelText>
                </CDBPanel>
              </div>
            </div>

            <div className="blockcode" style={{ borderRadius: "15px" }}>
              <div className="description  d-flex">Panel with Users <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
              <div className="example">
                <CDBPanel className="p-0" style={{ borderRadius: "15px" }}>
                  <CDBCardImage className="img-fluid w-100" style={{ borderRadius: "8px" }} src="/img/cardbg.png" />
                  <div className="m-3">
                    <CDBPanelTitle tag="h4" style={{ fontWeight: "600" }}>Bristol</CDBPanelTitle>
                    <CDBPanelText className="my-3">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </CDBPanelText>
                    <CDBPaneImage size="sm" src="/img/pane/pane1.png" />
                    <CDBPaneImage size="sm" src="/img/pane/pane2.png" className="mx-2" />
                    <CDBPaneImage size="sm" src="/img/pane/pane3.png" />
                  </div>
                </CDBPanel>
              </div>
            </div>

            <div className="blockcode" style={{ borderRadius: "15px" }}>
              <div className="description  d-flex">Panel With Label <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
              <div className="example">
                <CDBPanel style={{ width: "22rem", borderRadius: "15px" }}>
                  <CDBBadge color="dark" className="mx-1" flat>Label 1</CDBBadge>
                  <CDBBadge color="warning" className="mx-1" flat>Label 2</CDBBadge>
                  <CDBBadge color="danger" className="mx-1" flat>Label 3</CDBBadge>
                  <div className="mt-3 d-flex align-items-center">
                    <CDBPanelTitle tag="h3" >Moon Fever</CDBPanelTitle>
                    <CDBIcon className="ml-auto" fas icon="chart-line" />
                  </div>
                  <CDBPanelText className="my-3">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt
                  </CDBPanelText>
                  <CDBPaneImage size="sm" src="/img/pane/pane4.png" />
                  <CDBPaneImage size="sm" src="/img/pane/pane5.png" />
                </CDBPanel>
              </div>
            </div>

            <div className="blockcode" style={{ borderRadius: "15px" }}>
              <div className="description  d-flex">Pane List <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
              <div className="example">
                <CDBPanel style={{ width: "22rem", borderRadius: "15px" }}>
                  <CDBPane>
                    <CDBPanelText muted>My Messages</CDBPanelText>
                    <CDBDropDown className="ml-auto">
                      <CDBDropDownToggle color="white"><CDBIcon className="text-muted" fas icon="ellipsis-h" /></CDBDropDownToggle>
                      <CDBDropDownMenu>
                        <CDBDropDownItem disabled>Edit Profile</CDBDropDownItem>
                        <CDBDropDownItem divider />
                        <CDBDropDownItem>Action 1</CDBDropDownItem>
                        <CDBDropDownItem>Action 2</CDBDropDownItem>
                      </CDBDropDownMenu>
                    </CDBDropDown>
                  </CDBPane>
                  <CDBPane className="mt-4">
                    <CDBPaneImage size="md" src="/img/pane/pane1.png" />
                    <div className="ml-3">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>Warren Briggs</CDBPanelTitle>
                      <CDBPanelText small>Cooking</CDBPanelText>
                    </div>
                    <CDBPanelText muted small className="ml-auto">5mins ago</CDBPanelText>
                  </CDBPane>
                  <CDBPane className="mt-4">
                    <CDBPaneImage size="md" src="/img/pane/pane2.png" />
                    <div className="ml-3">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>Lu Chen</CDBPanelTitle>
                      <CDBPanelText small>I'll call you back</CDBPanelText>
                    </div>
                    <CDBPanelText muted small className="ml-auto">27mins ago</CDBPanelText>
                  </CDBPane>
                  <CDBPane className="mt-4">
                    <CDBPaneImage size="md" src="/img/pane/pane3.png" />
                    <div className="ml-3">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>Sam</CDBPanelTitle>
                      <CDBPanelText small>See you at school</CDBPanelText>
                    </div>
                    <CDBPanelText muted small className="ml-auto">active</CDBPanelText>
                  </CDBPane>
                  <CDBPane className="mt-4">
                    <CDBPaneImage size="md" src="/img/pane/pane4.png" />
                    <div className="ml-3">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>Danny</CDBPanelTitle>
                      <CDBPanelText small>Yeah</CDBPanelText>
                    </div>
                    <CDBPanelText muted small className="ml-auto">busy</CDBPanelText>
                  </CDBPane>
                  <CDBPane className="mt-4">
                    <CDBPaneImage size="md" src="/img/pane/pane5.png" />
                    <div className="ml-3">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>Lilah Loselev</CDBPanelTitle>
                      <CDBPanelText small>Okay sir</CDBPanelText>
                    </div>
                    <CDBPanelText muted small className="ml-auto">1hr ago</CDBPanelText>
                  </CDBPane>
                </CDBPanel>
              </div>
            </div>

            <div className="blockcode" style={{ borderRadius: "15px" }}>
              <div className="description d-flex">Panel with Progress <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
              <div className="example">
                <CDBPanel style={{ width: "320px", borderRadius: "15px" }}>
                  <CDBPane>
                    <CDBPaneImage size="md" src="/img/pane/pane1.png" />
                    <div className="ml-3">
                      <CDBPanelTitle style={{ fontWeight: "600" }}>Warren Briggs</CDBPanelTitle>
                      <CDBPanelText small>Chicago</CDBPanelText>
                    </div>
                    <CDBDropDown className="ml-auto">
                      <CDBDropDownToggle color="white"><CDBIcon className="text-muted" fas icon="ellipsis-h" /></CDBDropDownToggle>
                      <CDBDropDownMenu>
                        <CDBDropDownItem disabled>Edit Profile</CDBDropDownItem>
                        <CDBDropDownItem divider />
                        <CDBDropDownItem>Action 1</CDBDropDownItem>
                        <CDBDropDownItem>Action 2</CDBDropDownItem>
                      </CDBDropDownMenu>
                    </CDBDropDown>
                  </CDBPane>
                  <CDBPanelText style={{ fontWeight: "600" }} className="mt-3" small>Instagram</CDBPanelText>
                  <CDBProgress value={75} height={5}></CDBProgress>
                  <CDBPanelText style={{ fontWeight: "600" }} className="mt-3" small>Facebook</CDBPanelText>
                  <CDBProgress value={60} height={5}></CDBProgress>
                  <CDBPanelText style={{ fontWeight: "600" }} className="mt-3" small>Twitter</CDBPanelText>
                  <CDBProgress value={45} height={5}></CDBProgress>
                </CDBPanel>
              </div>
            </div>


          </div>
        </div>
      </Layout>
    </div>
  );
};
