import React, { Fragment } from "react";
import { CDBMultiselect, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const Multiselect = () => {

  // "showing" key:value pair is optional
  const option = [
    {
      text: "Value 1",
      showing: true,
    },
    {
      text: "Second Value",
      showing: true,
    },
    {
      text: "Third Value",
      showing: true,
    },
    {
      text: "Final Value",
      showing: true,
    },
  ];

  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div style={{ width: "80%", margin: "0 auto" }}>
        <div className="blockcode">
          <div className="header d-flex">Multiselect Styles  <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Basic Multiselect</div>
          <div className="example" style={{width:"350px"}}>
            <CDBMultiselect options={option} selected="Value" />
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex">Multiselect With Search  <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Multiselect With Search</div>
          <div className="example" style={{width:"350px"}}>
            <CDBMultiselect color="secondary" search options={option} selected="Value" />
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex">Multiselect With Submit Option  <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Multiselect With Submit Option</div>
          <div className="example" style={{width:"350px"}}>
            <CDBMultiselect color="dark" submit options={option} selected="Value" />
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex">Multiselect with Limited options  <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Multiselect with Limited options</div>
          <div className="example" style={{width:"350px"}}>
            <CDBMultiselect color="danger" limitedOptions options={option} selected="Value" />
          </div>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
