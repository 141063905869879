import React, { Fragment } from "react";
import { CDBInput, CDBCheckbox, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Checkbox = () => {

  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div style={{ width: "80%", margin: "0 auto" }}>
        <div className="blockcode">
          <div className="header">Checkbox</div>
          <div className="description">Default Checkbox</div>
          <div className="example">
            <CDBInput type="checkbox"/> 
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Checkbox 
            <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white">
              <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge>
            </a>
          </div>
          <div className="description">CDB Checkbox</div>
          <div className="example">
            <CDBCheckbox/>
          </div>
        </div>
        <div className="blockcode">
          <div className="header">Checkbox 
            <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white">
              <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge>
            </a>
          </div>
          <div className="description">Disabled Checkbox</div>
          <div className="example">
            <CDBCheckbox disabled/>
          </div>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
