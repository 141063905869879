import React, { Fragment } from "react";
import {
  CDBLink,
  CDBCard,
  CDBBtn,
  CDBInput,
  CDBCardBody,
  CDBNavbar,
  CDBNavBrand,
} from "cdbreact";

export const ForgotPassword2 = () => {
  return (
    <Fragment>
      <CDBNavbar
        className="px-0 mx-auto bg-transparent"
        style={{ width: "80%" }}
        light
        expand="md"
        scrolling
      >
        <CDBNavBrand href="/" className="d-flex align-items-center">
          <img alt="logo" src="/img/pages/logo.png" width="25px" />
          <span className="ms-3 h4 my-0 font-weight-bold">Devwares</span>
        </CDBNavBrand>
      </CDBNavbar>
      <div className="d-flex align-items-center justify-content-center">
        <CDBCard
          style={{ width: "80%", maxWidth: "375px", height: "70vh" }}
          className="border-0"
        >
          <CDBCardBody className="m-3 d-flex flex-column p-0">
            <div className="mt-4 mb-5">
              <p className="h4 text-center font-weight-bold">Forgot Password</p>
            </div>
            <CDBInput
              id="defaultRegisterEmail"
              placeholder="Email Address"
              className="mt-3"
              background
              type="email"
            />
            <CDBBtn color="dark" className="btn-block w-100 mb-3 mt-2">
              Recover Password
            </CDBBtn>
            <p className="text-muted text-center mt-auto">
              Return to{" "}
              <CDBLink
                style={{ fontWeight: "600" }}
                className="d-inline p-0 text-dark"
                to="#"
              >
                Sign in
              </CDBLink>
            </p>
          </CDBCardBody>
        </CDBCard>
      </div>
    </Fragment>
  );
};
