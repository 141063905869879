import React, { useState } from "react";
import { 
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBIcon,
  CDBBtn,
  CDBCollapse,
  CDBCardTitle,
  CDBCardText } from "cdbreact";
import "./Hero3.css"

export const Hero3 = () => {

  const [collapse, setCollapse] = useState(false);

	return(
		<div className="hero3">
			<header className="navigation">
        <CDBNavbar className="px-0 bg-transparent" light expand="md" scrolling>
          <CDBNavBrand href="/">
            <img alt="logo" src="/img/pages/logo.png" width="25px"/>
          </CDBNavBrand>
          <CDBNavToggle
            onClick={() => {
              setCollapse(!collapse);
            }}
          />
          <CDBCollapse id="navbarCollapse1" isOpen={collapse} navbar>
            <CDBNavbarNav left>
              <CDBNavItem active>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Home</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Resources</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Blog</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Contact</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Support</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
            <CDBNavbarNav right>
              <CDBNavItem active>
                <CDBBtn flat outline style={{width:"120px"}} className="py-1 m-2 border-dark bg-transparent">
                  <CDBNavLink to="#">Sign In</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
          </CDBCollapse>
        </CDBNavbar>
      </header>
      <div className="page-body">
				<div className="page-info">
					<CDBCardTitle className="h1 big">Creativity is but skin deep</CDBCardTitle>
					<CDBCardText className="my-4" style={{maxWidth:"500px"}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</CDBCardText>
					<CDBBtn flat style={{background:"#8080ff", border:"2px #8080ff solid"}}>Start Now</CDBBtn>
				</div>
				<div className="image-container">
					<img alt="sideImage" src="/img/pages/heroImage2.png" className="image1"/>
					<img alt="sideImage" className="image2" src="/img/pages/heroImage.png" />
				</div>
			</div>
			<div className="scroll-down">
				<CDBIcon fas icon="angle-double-down"/>
				<span className="ml-2">scroll down</span>
			</div>
		</div>
	);
}
