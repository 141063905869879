import React, { Fragment, useState } from "react";
import {
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBBtn,
  CDBCollapse,
  CDBDropDown,
  CDBDropDownToggle,
  CDBDropDownMenu,
} from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";


export const Navbar = () => {
  const [collapse, setCollapse] = useState(false);
  const [collapse2, setCollapse2] = useState(false);

  const bgBlack = { backgroundColor: "#000000", color: "#f4f4f4" };

  return (
    <div className="d-flex w-100">
      <div style={{ height: "100vh", overflow: "hidden initial" }}>
        <Sidebar />
      </div>
      <Layout>
        <TemplateNav />
        <div className="r-w">
          <div className="blockcode">
            <div className="header">Basic Example</div>
            <div className="description"></div>
            <div className="example3">
                <header>
                  <CDBNavbar
                    style={bgBlack}
                    dark
                    expand="md"
                    scrolling
                    className="d-flex justify-content-between"
                  >
                    <CDBNavBrand href="/">
                      <strong>Navbar</strong>
                    </CDBNavBrand>
                    <CDBNavToggle
                      onClick={() => {
                        setCollapse(!collapse);
                      }}
                    />
                    <CDBCollapse id="navbarCollapse1" isOpen={collapse} navbar>
                      <CDBNavbarNav left>
                        <CDBNavItem>
                          <CDBNavLink to="#">
                            <i className="fab fa-facebook-f"></i>
                          </CDBNavLink>
                        </CDBNavItem>
                        <CDBNavItem>
                          <CDBNavLink to="#">
                            <i className="fab fa-twitter"></i>
                          </CDBNavLink>
                        </CDBNavItem>
                        <CDBNavItem>
                          <CDBNavLink to="#">
                            <i className="fab fa-instagram"></i>
                          </CDBNavLink>
                        </CDBNavItem>
                      </CDBNavbarNav>
                      <CDBNavbarNav right className="align-items-center">
                        <CDBNavItem active>
                          <CDBBtn color="dark" className="p-0">
                            <CDBNavLink to="#">
                              <i className="fa fa-home mr-2"></i> Home
                            </CDBNavLink>
                          </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                          <CDBBtn color="dark" className="p-0">
                            <CDBNavLink to="#">
                              <i className="fa fa-feather mr-2"></i> Features
                            </CDBNavLink>
                          </CDBBtn>
                        </CDBNavItem>
                        <CDBNavItem>
                          <CDBBtn color="dark" className="p-0">
                            <CDBNavLink to="#">
                              <i className="fa fa-dollar-sign mr-2"></i>
                              Pricing
                            </CDBNavLink>
                          </CDBBtn>
                        </CDBNavItem>

                        <CDBNavItem>
                          <CDBDropDown>
                            <CDBDropDownToggle
                              caret
                              className="p-0"
                              color="dark"
                            >
                              <i className="fa fa-cogs mr-2"></i> Options
                            </CDBDropDownToggle>
                            <CDBDropDownMenu>
                              Coming soon #pleaseStayUpdated.
                            </CDBDropDownMenu>
                          </CDBDropDown>
                        </CDBNavItem>
                      </CDBNavbarNav>
                    </CDBCollapse>
                  </CDBNavbar>
                </header>
            </div>
          </div>

          <div className="blockcode">
            <div className="header">Example2</div>
            <div className="description"></div>
              <header>
                <CDBNavbar style={bgBlack} dark expand="md" scrolling>
                  <CDBNavBrand href="/">
                    <strong>Navbar</strong>
                  </CDBNavBrand>
                  <CDBNavToggle
                    onClick={() => {
                      setCollapse2(!collapse2);
                    }}
                  />
                  <CDBCollapse id="navbarCollapse1" isOpen={collapse2} navbar>
                    <CDBNavbarNav left className="align-items-center">
                      <CDBNavItem>
                        <CDBDropDown>
                          <CDBDropDownToggle caret className="p-0" color="dark">
                            Categories
                          </CDBDropDownToggle>
                          <CDBDropDownMenu>
                            Coming soon #pleaseStayUpdated.
                          </CDBDropDownMenu>
                        </CDBDropDown>
                      </CDBNavItem>
                      <CDBNavItem>
                        <CDBBtn color="dark" className="p-0">
                          <CDBNavLink to="#">Help</CDBNavLink>
                        </CDBBtn>
                      </CDBNavItem>
                      <CDBNavItem>
                        <CDBBtn color="dark" className="p-0">
                          <CDBNavLink to="#">About</CDBNavLink>
                        </CDBBtn>
                      </CDBNavItem>
                    </CDBNavbarNav>
                    <CDBNavbarNav right>
                      <CDBNavItem>
                        <CDBBtn color="dark" className="p-0">
                          <CDBNavLink to="#">
                            <i className="fa fa-globe mr-2"></i>
                            EN
                          </CDBNavLink>
                        </CDBBtn>
                      </CDBNavItem>

                      <CDBNavItem>
                        <CDBBtn color="dark" className="p-0">
                          <CDBNavLink to="#">
                            <i className="fa fa-user mr-2"></i>
                            Login
                          </CDBNavLink>
                        </CDBBtn>
                      </CDBNavItem>
                      <CDBNavItem>
                        <CDBBtn color="white" style={{ padding: "0px 10px" }}>
                          <CDBNavLink to="#" style={{ color: "#000" }}>
                            Sign Up
                          </CDBNavLink>
                        </CDBBtn>
                      </CDBNavItem>
                    </CDBNavbarNav>
                  </CDBCollapse>
                </CDBNavbar>
              </header>
          </div>
        </div>
      </Layout>
    </div>
  );
};
