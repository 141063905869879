import React, { useState } from "react";
import {
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBIcon,
  CDBBtn,
  CDBCollapse,
  CDBCardTitle,
  CDBCardText,
  CDBMask,
} from "cdbreact";
import "./Hero2.css";

export const Hero2 = () => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="hero2">
      <div className="position-relative">
        <img alt="Background" src="/img/pages/hero.png" className="image" />
        <CDBMask overlay="darkLight" />
        <div className="scroll-down">
          <CDBIcon fas icon="angle-double-down" />
          <span className="ml-2">scroll down</span>
        </div>
      </div>
      <div className="page-body">
        <header className="navigation">
          <CDBNavbar className="bg-transparent px-0" expand="md" dark scrolling>
            <CDBNavBrand href="/">
              <img alt="logo" src="/img/pages/logo.png" width="25px" />
            </CDBNavBrand>
            <CDBNavToggle
              onClick={() => {
                setCollapse(!collapse);
              }}
            />
            <CDBCollapse
              id="navbarCollapse1"
              delay={0}
              isOpen={collapse}
              navbar
            >
              <CDBNavbarNav left>
                <CDBNavItem active>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Home</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Resources</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Blog</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">About</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Contact</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
                <CDBNavItem>
                  <CDBBtn flat className="p-3 border-0 bg-transparent">
                    <CDBNavLink to="#">Support</CDBNavLink>
                  </CDBBtn>
                </CDBNavItem>
              </CDBNavbarNav>
            </CDBCollapse>
          </CDBNavbar>
        </header>
        <div className="page-info">
          <CDBCardTitle
            className="h1 font-weight-bold"
            style={{ maxWidth: "350px" }}
          >
            Creativity is but skin deep
          </CDBCardTitle>
          <CDBCardText className="my-5" style={{ maxWidth: "500px" }}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
            error amet numquam iure provident voluptate esse quasi, veritatis
            totam voluptas nostrum quisquam eum porro a pariatur accusamus
            veniam.
          </CDBCardText>
          <div className="d-flex">
            <CDBBtn
              flat
              className="me-4"
              style={{ background: "#8080ff", border: "2px #8080ff solid" }}
            >
              Start Now
            </CDBBtn>
            <CDBBtn flat className="border-white bg-transparent text-white">
              Start Now
            </CDBBtn>
          </div>
        </div>
      </div>
    </div>
  );
};
