import React, { useState } from "react";
import { 
	CDBNavbar,
	CDBNavBrand,
	CDBNavbarNav,
	CDBNavToggle,
	CDBNavItem,
	CDBNavLink,
	CDBBtn,
	CDBCollapse,
	CDBContainer,
	CDBCardTitle,
	CDBCardText } from "cdbreact";
import "./Pricing.css"

export const Pricing = () => {

  const [collapse, setCollapse] = useState(false);

	return(
		<div className="pricing">
			<header className="navigation">
        <CDBNavbar className="bg-transparent p-0" expand="md" light scrolling>
          <CDBNavBrand href="/">
            <img alt="logo" src="/img/pages/logo.png" width="25px"/>
          </CDBNavBrand>
          <CDBNavToggle
            onClick={() => {
              setCollapse(!collapse);
            }}
          />
          <CDBCollapse id="navbarCollapse1" isOpen={collapse} navbar>
            <CDBNavbarNav left>
              <CDBNavItem active>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Home</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Resources</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Blog</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Contact</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
              <CDBNavItem>
                <CDBBtn flat className="p-3 border-0 bg-transparent">
                  <CDBNavLink to="#">Support</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
            <CDBNavbarNav right>
              <CDBNavItem>
                <CDBBtn flat outline className="my-2 py-1 px-4 border-dark bg-transparent">
                  <CDBNavLink to="#">Preview</CDBNavLink>
                </CDBBtn>
              </CDBNavItem>
            </CDBNavbarNav>
          </CDBCollapse>
        </CDBNavbar>
      </header>
			<div className="page-body">
        <div className="page-info">
					<CDBCardTitle className="page-title">Our Pricing</CDBCardTitle>
					<CDBCardText>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</CDBCardText>
				</div>
				<div className="my-5">
					<CDBContainer className="pricing-container shadow-sm bg-light">
						<div className="price-info">
							<CDBCardTitle className="h2 font-weight-bold">$0</CDBCardTitle>
							<CDBCardText className="fw-600">Free</CDBCardText>
							<CDBCardText>Great for personal use and for your side projects.</CDBCardText>
						</div>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<div className="btn-container">
							<CDBBtn color="dark" className="w-100">Set Plan</CDBBtn>
						</div>
						<p className="view-details fw-600">View Details</p>
					</CDBContainer>
					<CDBContainer className="pricing-container shadow-sm bg-light">
						<div className="price-info">
							<CDBCardTitle className="h2 font-weight-bold">$0</CDBCardTitle>
							<CDBCardText className="fw-600">Free</CDBCardText>
							<CDBCardText>Great for personal use and for your side projects.</CDBCardText>
						</div>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<div className="btn-container">
							<CDBBtn color="dark" className="w-100">Set Plan</CDBBtn>
						</div>
						<p className="view-details fw-600">View Details</p>
					</CDBContainer>
					<CDBContainer className="pricing-container shadow-sm bg-light">
						<div className="price-info">
							<CDBCardTitle className="h2 font-weight-bold">$0</CDBCardTitle>
							<CDBCardText className="fw-600">Free</CDBCardText>
							<CDBCardText>Great for personal use and for your side projects.</CDBCardText>
						</div>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<ul className="list">
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
							<li>30 Templates</li>
						</ul>
						<div className="btn-container">
							<CDBBtn color="dark" className="w-100">Set Plan</CDBBtn>
						</div>
						<p className="view-details fw-600">View Details</p>
					</CDBContainer>
				</div>
			</div>
		</div>
	);
}
