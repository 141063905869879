import React, { useState } from "react";
import { 
  CDBNavbar,
  CDBNavBrand,
  CDBNavbarNav,
  CDBNavToggle,
  CDBNavItem,
  CDBNavLink,
  CDBIcon,
  CDBBtn,
  CDBCollapse,
  CDBCardTitle,
  CDBCardText,
  CDBMask } from "cdbreact";
import "./Hero5.css";

export const Hero5 = () => {

  const [collapse, setCollapse] = useState(false);

	return(
		<div className="hero5">
			<div style={{position:"relative"}}>
				<img alt="Background" src="/img/pages/hero1.png" style={{width:"100%", height:"100vh", objectFit:"cover"}}/>
				<CDBMask overlay="darkLight"/>
			</div>
			<div className="page-body">
        <div style={{maxWidth:"1320px", margin:"0 auto"}}>
  				<header className="page-header">
            <CDBNavbar className="navigation bg-transparent p-0" expand="md" dark scrolling>
              <CDBNavBrand href="/">
                <img alt="logo" src="/img/pages/logo.png" width="25px"/>
              </CDBNavBrand>
              <CDBNavToggle
                onClick={() => {
                  setCollapse(!collapse);
                }}
              />
              <CDBCollapse id="navbarCollapse1" delay={0} isOpen={collapse} navbar>
                <CDBNavbarNav left>
                  <CDBNavItem active>
                    <CDBBtn flat className="p-1 border-0 bg-transparent">
                      <CDBNavLink to="#">Home</CDBNavLink>
                    </CDBBtn>
                  </CDBNavItem>
                  <CDBNavItem>
                    <CDBBtn flat className="p-1 border-0 bg-transparent">
                      <CDBNavLink to="#">Resources</CDBNavLink>
                    </CDBBtn>
                  </CDBNavItem>
                  <CDBNavItem>
                    <CDBBtn flat className="p-1 border-0 bg-transparent">
                      <CDBNavLink to="#">Blog</CDBNavLink>
                    </CDBBtn>
                  </CDBNavItem>
                  <CDBNavItem>
                    <CDBBtn flat className="p-1 border-0 bg-transparent">
                      <CDBNavLink to="#">Contact</CDBNavLink>
                    </CDBBtn>
                  </CDBNavItem>
                  <CDBNavItem>
                    <CDBBtn flat className="p-1 border-0 bg-transparent">
                      <CDBNavLink to="#">Support</CDBNavLink>
                    </CDBBtn>
                  </CDBNavItem>
                </CDBNavbarNav>
                <CDBNavbarNav right>
                  <CDBNavItem className="d-flex align-items-center">
                    <CDBBtn flat style={{width:"120px"}} className="p-1 border-0 bg-transparent">
                      <CDBNavLink to="#">Sign In</CDBNavLink>
                    </CDBBtn>
                  </CDBNavItem>
                  <CDBNavItem>
                    <CDBBtn flat outline style={{width:"120px"}} className="my-2 p-1 border-white bg-transparent">
                      <CDBNavLink to="#">Sign Up</CDBNavLink>
                    </CDBBtn>
                  </CDBNavItem>
                </CDBNavbarNav>
              </CDBCollapse>
            </CDBNavbar>
          </header>
          <div className="text-white text-center" style={{width:"80%", margin:"12rem auto 0 auto"}}>
  					<CDBCardTitle className="h1 font-weight-bold mx-auto">Introducing the innovating, captivating art gallery.</CDBCardTitle>
  					<CDBCardText className="my-5 mx-auto" style={{maxWidth:"70%"}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</CDBCardText>
  					<CDBBtn flat className="px-4" style={{background:"#8080ff", border:"#8080ff"}}>Explore</CDBBtn>
  					<CDBIcon fas className="d-block mt-3" icon="angle-double-down"/>
  				</div>
        </div>
      </div>
		</div>
	);
}
