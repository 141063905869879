import React, { Fragment } from "react";
import { CDBBreadcrumb, CDBBadge, CDBLink, CDBIcon } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Breadcrumb = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div
        style={{
          margin: "auto",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="blockcode">
          <div className="header">Breadcrumb</div>
          <div className="description">Breadcrumb</div>
          <div className="example d-block" style={{width:"500px"}}>
            <CDBBreadcrumb color="none">
              <li className="breadcrumb-item" ><CDBLink className="p-0 d-inline" to="#">Home</CDBLink></li>
              <li className="breadcrumb-item"><CDBLink className="p-0 d-inline" to="#">Library</CDBLink></li>
              <li className="breadcrumb-item active">Data</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="none">
              <li className="breadcrumb-item"><CDBLink className="p-0" to="#">Home</CDBLink></li>
              <li className="breadcrumb-item active">Library</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="none">
              <li className="breadcrumb-item active">Home</li>
            </CDBBreadcrumb>
          </div>
        </div>

        <div className="blockcode">
          <div className="header">Breadcrumb with Icon</div>
          <div className="description d-flex">Breadcrumb with Icon <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="example d-block" style={{width:"500px"}}>
            <CDBBreadcrumb color="none" className="align-items-center">
              <li className="breadcrumb-item" ><CDBLink className="p-0" to="#">Home</CDBLink></li>
              <CDBIcon className="mx-2 text-muted" fas icon="angle-double-right"/>
              <li className="breadcrumb-item"><CDBLink className="p-0" to="#">Library</CDBLink></li>
              <CDBIcon className="mx-2 text-muted" fas icon="angle-double-right"/>
              <li className="breadcrumb-item active">Data</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="none" className="align-items-center">
              <li className="breadcrumb-item"><CDBLink className="p-0" to="#">Home</CDBLink></li>
              <CDBIcon className="mx-2 text-muted" fas icon="angle-double-right"/>
              <li className="breadcrumb-item active">Library</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="none">
              <li className="breadcrumb-item active">Home</li>
            </CDBBreadcrumb>
          </div>
        </div>

        <div className="blockcode">
          <div className="header d-flex">Breadcrumb with Colors <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description">Breadcrumb with Colors</div>
          <div className="example d-block" style={{width:"500px"}}>
            <CDBBreadcrumb color="dark" className="align-items-center text-white">
              <li className="breadcrumb-item" ><CDBLink className="p-0 text-white" to="#">Home</CDBLink></li>
              <CDBIcon className="mx-2 text-muted" fas icon="angle-double-right"/>
              <li className="breadcrumb-item"><CDBLink className="p-0 text-white" to="#">Library</CDBLink></li>
              <CDBIcon className="mx-2 text-muted" fas icon="angle-double-right"/>
              <li className="breadcrumb-item">Data</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb className="align-items-center">
              <li className="breadcrumb-item"><CDBLink className="p-0 text-white" to="#">Home</CDBLink></li>
              <CDBIcon className="mx-2 text-muted" fas icon="angle-double-right"/>
              <li className="breadcrumb-item">Library</li>
            </CDBBreadcrumb>
            <CDBBreadcrumb color="info">
              <li className="breadcrumb-item">Home</li>
            </CDBBreadcrumb>
          </div>
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};