import React from "react";
import {
  CDBLink,
  CDBIcon,
  CDBCard,
  CDBBtn,
  CDBInput,
  CDBCardBody,
  CDBNavbar,
  CDBNavBrand,
} from "cdbreact";
import "./SignIn2.css";

export const SignIn2 = () => {
  return (
    <div className="signIn2">
      <div className="position-relative">
        <img alt="Background" src="/img/pages/signIn.png" className="image" />
      </div>
      <div className="body">
        <CDBNavbar
          className="navigation bg-transparent"
          light
          expand="md"
          scrolling
        >
          <CDBNavBrand href="/" className="d-flex align-items-center">
            <img alt="logo" src="/img/pages/logo.png" width="25px" />
            <span className="me-3 h5 my-0 font-weight-bold">Devwares</span>
          </CDBNavBrand>
        </CDBNavbar>
        <CDBCard className="form">
          <CDBCardBody className="form-body" style={{ minWidth: "300px" }}>
            <div className="mt-4 mb-5">
              <p className="h3 font-weight-bold">Sign in</p>
            </div>

            <CDBInput
              id="defaultRegisterUsername"
              className="mt-n3"
              background
              placeholder="Username"
              type="text"
            />
            <CDBInput
              id="defaultRegisterPassword"
              className="mt-n3"
              background
              placeholder="Password"
              type="password"
            />
            <CDBLink
              style={{ fontWeight: "600" }}
              className="d-inline p-0 text-dark"
              to="#"
            >
              Forgot Password?
            </CDBLink>
            <CDBBtn color="dark" className="btn-block w-100 mb-3 mt-5">
              Sign in
            </CDBBtn>
            <div className="d-flex align-items-center">
              <span style={{ border: "0.5px solid black", width: "45%" }} />
              <span className="text-center" style={{ width: "10%" }}>
                OR
              </span>
              <span style={{ border: "0.5px solid black", width: "45%" }} />
            </div>
            <div className="my-3 d-flex justify-content-center">
              <CDBBtn flat color="dark">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn color="dark" flat className="mx-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn flat color="dark">
                <CDBIcon fab icon="google-plus-g" />
              </CDBBtn>
            </div>
            <p className="text-muted text-center">
              Don't have an Account?{" "}
              <CDBLink
                style={{ fontWeight: "600" }}
                className="d-inline p-0 text-dark"
                to="#"
              >
                Sign Up
              </CDBLink>
            </p>
          </CDBCardBody>
        </CDBCard>
      </div>
    </div>
  );
};
