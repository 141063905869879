import React from "react";
import {
  CDBLink,
  CDBCard,
  CDBBtn,
  CDBInput,
  CDBCardBody,
  CDBNavbar,
  CDBNavBrand,
} from "cdbreact";
import "./ForgotPassword.css";

export const ForgotPassword = () => {
  return (
    <div className="forgotPassword">
      <div className="position-relative">
        <img
          alt="Background"
          src="/img/pages/signIn.png"
          style={{ position: "absolute", width: "100%", height: "100vh" }}
        />
      </div>
      <div className="body">
        <CDBNavbar
          className="navigation bg-transparent"
          light
          expand="md"
          scrolling
        >
          <CDBNavBrand href="/" className="d-flex align-items-center">
            <img alt="logo" src="/img/pages/logo.png" width="25px" />
            <span className="ml-3 h5 my-0 font-weight-bold">Devwares</span>
          </CDBNavBrand>
        </CDBNavbar>
        <CDBCard className="form">
          <CDBCardBody className="form-body">
            <div className="mt-4 mb-5">
              <p className="h4 font-weight-bold">Forgot Password</p>
            </div>
            <CDBInput
              id="defaultRegisterEmail"
              placeholder="Email Address"
              className="mt-n3"
              background
              type="email"
            />
            <CDBBtn color="dark" className="btn-block w-100 mb-3 mt-5">
              Recover Password
            </CDBBtn>
            <p className="text-muted text-center">
              Return to{" "}
              <CDBLink
                style={{ fontWeight: "600" }}
                className="d-inline p-0 text-dark"
                to="#"
              >
                Sign in
              </CDBLink>
            </p>
          </CDBCardBody>
        </CDBCard>
      </div>
    </div>
  );
};
