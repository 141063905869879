import React, { Fragment } from "react";
import { CDBInput } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Input = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header">Default Input types</div>
        <div className="description"></div>
        <div className="example2">
          <CDBInput label="Username" />
          <CDBInput placeholder="Your e-mail" type="email" />
          <CDBInput type="number" />
          <CDBInput material type="text" />
          <CDBInput background type="text" />
          <CDBInput type="checkbox" />
          <CDBInput className="mt-5" type="textarea" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Input Sizing</div>
        <div className="description"></div>
        <div className="example2">
          <CDBInput label="Your e-mail" type="email" size="sm" />
          <CDBInput type="number" label="Your Number" size="lg" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Input with Icons</div>
        <div className="description"></div>
          <CDBInput label="Username" type="text" icon="user"/>
          <CDBInput label="Password" type="password" icon="bell"/>
      </div>
      <div className="blockcode">
        <div className="header">Input with MD</div>
        <div className="description"></div>
        <div className="example2">
          <CDBInput material label="Your e-mail" type="email" />
          <CDBInput material type="number" label="Your Number" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Placeholder</div>
        <div className="description"></div>
        <div className="example2">
          <CDBInput placeholder="Your e-mail" type="email" />
          <CDBInput type="number" placeholder="Your Number" />
        </div>
      </div>

      <div className="blockcode">
        <div className="header">Disabled Input</div>
        <div className="description"></div>
        <div className="example2">
          <CDBInput placeholder="Your e-mail" type="email" disabled />
          <CDBInput type="number" placeholder="Your Number" disabled />
        </div>
      </div>
      <div className="blockcode">
        <div className="header">TextArea</div>
        <div className="description"></div>
        <div className="example2">
          <CDBInput label="Your name" type="textarea" />
          <CDBInput
            label="Your story"
            type="textarea"
            material
            rows={10}
            cols={30}
          />
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Background</div>
        <div className="description"></div>
        <div className="example2">
          <CDBInput placeholder="Your e-mail" type="email" background />
          <CDBInput type="number" placeholder="Your Number"material background />
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
