import React, { Component, Fragment } from "react";
import { 
  CDBContainer,
  CDBNav,
  CDBTabPane,
  CDBTabContent,
  CDBTabLink,
  CDBIcon,
  CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export class Tabs extends Component {
  state = {
    activeItem: "1",
    activeItem2: "4",
    activeItem3: "7",
  };

  toggle = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };
  toggle2 = (tab) => (e) => {
    if (this.state.activeItem2 !== tab) {
      this.setState({
        activeItem2: tab,
      });
    }
  };
  toggle3 = (tab) => (e) => {
    if (this.state.activeItem3 !== tab) {
      this.setState({
        activeItem3: tab,
      });
    }
  };

  render() {
    return (
      <div className="d-flex w-100">
        <div style={{height:"100vh", overflow:"hidden initial"}}>
          <Sidebar/>
        </div>
        <Layout>
          <TemplateNav/>
      <div className="r-w">
        <div className="blockcode">
          <div className="header d-flex">Default Tab  <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
          <div className="description"></div>
          <div className="example">
            <CDBContainer>
              <CDBNav className="nav-tabs mt-5">
                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem === "1"}
                  onClick={this.toggle("1")}
                  role="tab"
                >
                  Label 1
                </CDBTabLink>

                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem === "2"}
                  onClick={this.toggle("2")}
                  role="tab"
                >
                  Label 2
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem === "3"}
                  onClick={this.toggle("3")}
                  role="tab"
                >
                  Label 3
                </CDBTabLink>
              </CDBNav>
              <CDBTabContent activeItem={this.state.activeItem}>
                <CDBTabPane tabId="1" role="tabpanel">
                  <p className="mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Nihil odit magnam minima, soluta doloribus reiciendis
                    molestiae placeat unde eos molestias. Quisquam aperiam,
                    pariatur. Tempora, placeat ratione porro voluptate odit
                    minima.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="2" role="tabpanel">
                  <p className="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                  <p>
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="3" role="tabpanel">
                  <p className="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                </CDBTabPane>
              </CDBTabContent>
            </CDBContainer>
          </div>
        </div>
        <div className="blockcode">
          <div className="header d-flex">Tab Justified  <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></div>
          <div className="description"></div>
          <div className="example">
            <CDBContainer>
              <CDBNav className="nav-tabs mt-5 nav-justified">
                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem2 === "4"}
                  onClick={this.toggle2("4")}
                  role="tab"
                >
                  Label 1
                </CDBTabLink>

                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem2 === "5"}
                  onClick={this.toggle2("5")}
                  role="tab"
                >
                  Label 2
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem2 === "6"}
                  onClick={this.toggle2("6")}
                  role="tab"
                >
                  Label 3
                </CDBTabLink>
              </CDBNav>
              <CDBTabContent activeItem={this.state.activeItem2}>
                <CDBTabPane tabId="4" role="tabpanel">
                  <p className="mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Nihil odit magnam minima, soluta doloribus reiciendis
                    molestiae placeat unde eos molestias. Quisquam aperiam,
                    pariatur. Tempora, placeat ratione porro voluptate odit
                    minima.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="5" role="tabpanel">
                  <p className="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                  <p>
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="6" role="tabpanel">
                  <p className="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                </CDBTabPane>
              </CDBTabContent>
            </CDBContainer>
          </div>
        </div>

        <div className="blockcode">
          <div className="header d-flex">Tab with Icons  <CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></div>
          <div className="description"></div>
          <div className="example">
            <CDBContainer>
              <CDBNav className="nav-tabs mt-5 nav-justified">
                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem3 === "7"}
                  onClick={this.toggle3("7")}
                  role="tab"
                >
                  <CDBIcon icon="bomb" className="mr-2" />
                  Label 1
                </CDBTabLink>

                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem3 === "8"}
                  onClick={this.toggle3("8")}
                  role="tab"
                >
                  <CDBIcon icon="bomb" className="mr-2" />
                  Label 2
                </CDBTabLink>
                <CDBTabLink
                  link
                  to="#"
                  active={this.state.activeItem3 === "9"}
                  onClick={this.toggle3("9")}
                  role="tab"
                >
                  <CDBIcon icon="bomb" className="mr-2" />
                  Label 3
                </CDBTabLink>
              </CDBNav>
              <CDBTabContent
                activeItem={this.state.activeItem3}
                className="mt-5"
                style={{ marginTop: "30px" }}
              >
                <CDBTabPane tabId="7" role="tabpanel">
                  <p className="mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Nihil odit magnam minima, soluta doloribus reiciendis
                    molestiae placeat unde eos molestias. Quisquam aperiam,
                    pariatur. Tempora, placeat ratione porro voluptate odit
                    minima.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="8" role="tabpanel">
                  <p className="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                  <p>
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                </CDBTabPane>
                <CDBTabPane tabId="9" role="tabpanel">
                  <p className="mt-2">
                    Quisquam aperiam, pariatur. Tempora, placeat ratione porro
                    voluptate odit minima. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Nihil odit magnam minima,
                    soluta doloribus reiciendis molestiae placeat unde eos
                    molestias.
                  </p>
                </CDBTabPane>
              </CDBTabContent>
            </CDBContainer>
          </div>
        </div>
      </div>
      </Layout>
    </div>
    );
  }
}
