import React, { Fragment } from "react";
import { CDBAlert } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Alert = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div
        style={{
          margin: "auto",
          padding:"5rem 0",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <CDBAlert style={{minWidth:"none"}} color="primary">
          A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert style={{minWidth:"none"}} color="secondary">
          A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert style={{minWidth:"none"}} color="success">
          A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert style={{minWidth:"none"}} color="danger" dismiss>
          A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert style={{minWidth:"none"}} color="warning">
          A simple alert built with contrast design check it out!
        </CDBAlert>
        <CDBAlert style={{minWidth:"none"}} color="dark">
          A simple alert built with contrast design check it out!
        </CDBAlert>
      </div>
    </div>
      </Layout>
    </div>
  );
};
