import React, { Fragment } from "react";
import { CDBInputGroup, CDBInput } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const InputGroup = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div style={{ width: "80%", margin: "0 auto" }}>

        <div className="blockcode">
          <div className="header">Basic Input Group</div>
          <div className="description">Basic Input with appended text after</div>
          <div className="example d-block">
            <CDBInputGroup
              placeholder="Recipient's username"
              containerClassName="mb-3 mt-0"
              append="@example.com"
            />
          </div>
        </div>

        <div className="blockcode">
          <div className="header">Basic Input Group</div>
          <div className="description">Basic Input with appended text before</div>
          <div className="example d-block">
            <CDBInputGroup
              containerClassName="flex-nowrap mb-3"
              prepend="@"
              placeholder="Username"
            />
          </div>
        </div>

        <div className="blockcode">
          <div className="header">Input Group Icon</div>
          <div className="description">Run Function on icon click</div>
          <div className="example d-block">
            <CDBInputGroup
              icon="bell"
              onIconClick={() => alert("Wait! This is an alert!")}
            />
          </div>
        </div>

        <div className="blockcode">
          <div className="header">Input Sizes</div>
          <div className="description">Small Input Group</div>
          <div className="example d-block">
            <CDBInputGroup
              containerClassName="mb-2 mt-0"
              prepend="Small"
              size="sm"
            />
          </div>
        </div>

        <div className="blockcode">
          <div className="header">Input Sizes</div>
          <div className="description">Large Input Group</div>
          <div className="example d-block">
            <CDBInputGroup
              containerClassName="mb-2 mt-0"
              prepend="Large"
              size="lg"
            />
          </div>
        </div>

        <div className="blockcode">
          <div className="header">Input Group</div>
          <div className="description">Input Group with Checkbox</div>
          <div className="example d-block">
            <CDBInputGroup
              prepend={
                <div className="input-group-text md-addon">
                  <CDBInput type="checkbox"/>
                </div>
              }
            />
          </div>
        </div>

        <div className="blockcode">
          <div className="header">Input Group</div>
          <div className="description">Basic Input</div>
          <div className="example d-block">
            <CDBInputGroup/>
          </div>
        </div>
              
      </div>
    </div>
      </Layout>
    </div>
  );
};
