import React, { Fragment } from "react";
import { CDBTable, CDBTableHeader, CDBTableBody} from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Table = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div
        style={{
          margin: "auto",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Default Table</div>
          <CDBTable>
            <CDBTableHeader>
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
        </div>
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Table Head Options</div>
          <CDBTable>
            <CDBTableHeader color="dark">
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
          <CDBTable>
            <CDBTableHeader color="light">
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
        </div>
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Striped Table</div>
          <CDBTable striped>
            <CDBTableHeader>
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
        </div>
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Bordered Table</div>
          <CDBTable bordered>
              <CDBTableHeader>
                <tr>
                  <th>#</th>
                  <th>First</th>
                  <th>Last</th>
                  <th>Handle</th>
                </tr>
              </CDBTableHeader>
              <CDBTableBody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
              </CDBTableBody>
          </CDBTable>
        </div>
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Borderless Table</div>
          <CDBTable borderless>
            <CDBTableHeader>
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
        </div>
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Hoverable Rows</div>
          <CDBTable hover>
            <CDBTableHeader>
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
        </div>
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Small Table</div>
          <CDBTable small>
            <CDBTableHeader>
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
        </div>
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Captions</div>
          <CDBTable>
            <caption>List of users</caption>
            <CDBTableHeader>
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
        </div>
        <div className="blockcode w-100">
          <div className="header">Tables</div>
          <div className="description">Responsive Table</div>
          <CDBTable responsive>
            <CDBTableHeader>
              <tr>
                <th>#</th>
                <th>First</th>
                <th>Second</th>
                <th>Third</th>
                <th>Fourth</th>
                <th>Fifth</th>
                <th>Sixth</th>
                <th>Seventh</th>
                <th>Last</th>
                <th>Handle</th>
              </tr>
            </CDBTableHeader>
            <CDBTableBody>
              <tr>
                <td>1</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>@email</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>@email</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>Name</td>
                <td>@email</td>
              </tr>
            </CDBTableBody>
          </CDBTable>
        </div>

      </div>
    </div>
      </Layout>
    </div>
  );
};
