import React, { Fragment } from "react";
import { CDBBox } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Box = () => {
  return (
    <div className="d-flex w-100">
      <div style={{ height: "100vh", overflow: "hidden initial" }}>
        <Sidebar />
      </div>
      <Layout>
        <TemplateNav />
        <div className="r-w">
          <div>
            <div className="blockcode w-100">
              <div className="header">Basic Box Examples</div>
              <div className="description">Basic Box</div>
              <div className="border p-3" style={{ borderRadius: "15px" }}>
                <CDBBox tag="span">
                  Span
                </CDBBox>
                <CDBBox tag="div">
                  Div
                </CDBBox>
                <CDBBox tag="p">
                  Paragraph
                </CDBBox>
                <CDBBox tag="section">
                  Section
                </CDBBox>
              </div>
            </div>
            <div className="blockcode w-100">
              <div className="header">Display</div>
              <div className="description">Display Box Examples</div>
              <div className="border p-3" style={{ borderRadius: "15px" }}>
                <CDBBox display="flex">
                  Display Flex
                </CDBBox>
                <CDBBox display="block">
                  Display Block
                </CDBBox>
                <CDBBox display="none">
                  Dislpay None
                </CDBBox>
                <CDBBox display="inline">
                  Display Inline
                </CDBBox>
              </div>
            </div>
            <div className="blockcode w-100">
              <div className="header">Flex</div>
              <div className="description">Flex Box Examples</div>
              <div className="border p-3" style={{ borderRadius: "15px" }}>
                <CDBBox display="flex" justifyContent="center">
                  Justify Content Center
                </CDBBox>
                <CDBBox display="flex" justifyContent="end">
                  Justify Content End
                </CDBBox>
                <CDBBox display="flex" flex="column">
                  <CDBBox tag="p">Column</CDBBox>
                  <CDBBox tag="p">Column</CDBBox>
                  <CDBBox tag="p">Column</CDBBox>
                </CDBBox>
                <CDBBox display="flex" flex="row">
                  <CDBBox tag="p">Row</CDBBox>
                  <CDBBox tag="p">Row</CDBBox>
                  <CDBBox tag="p">Row</CDBBox>
                </CDBBox>
                <CDBBox display="none">
                  <CDBBox display="flex" flex="fill">
                    <CDBBox tag="p">Fill</CDBBox>
                  </CDBBox>
                  <CDBBox display="flex" flex="row" alignItems="start">
                    Align Items Start
                  </CDBBox>
                  <CDBBox display="flex" flex="row" alignItems="center">
                    Align Items Center
                  </CDBBox>
                  <CDBBox display="flex" alignContent="start">
                    Align Content Start
                  </CDBBox>
                  <CDBBox display="flex" alignContent="center">
                    Align Content Center
                  </CDBBox>
                  <CDBBox display="flex" alignSelf="start">
                    Align Self Start
                  </CDBBox>
                  <CDBBox display="flex" alignSelf="center">
                    Align Self Center
                  </CDBBox>
                </CDBBox>
              </div>
            </div>
            <div className="blockcode w-100">
              <div className="header">Colors</div>
              <div className="description">Color Box Examples</div>
              <div className="border p-3" style={{ borderRadius: "15px" }}>
                <CDBBox color="red">
                  Red Color
                </CDBBox>
                <CDBBox color="green">
                  Green Color
                </CDBBox>
                <CDBBox color="blue">
                  Blue Color
                </CDBBox>
                <CDBBox color="pink">
                  Pink Color
                </CDBBox>
              </div>
            </div>
            <div className="blockcode w-100">
              <div className="header">Background Colors</div>
              <div className="description">Background Color Box Examples</div>
              <div className="border p-3" style={{ borderRadius: "15px" }}>
                <CDBBox bgColor="success">
                  Success Background
                </CDBBox>
                <CDBBox bgColor="secondary">
                  Secondary Background
                </CDBBox>
                <CDBBox bgColor="danger">
                  Danger Background
                </CDBBox>
                <CDBBox bgColor="warning">
                  Warning Background
                </CDBBox>
              </div>
            </div>

          </div>
        </div>
      </Layout>
    </div>
  );
};
