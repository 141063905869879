import React, { Fragment } from "react";
import { CDBBtn, CDBIcon, CDBBadge } from "cdbreact";
import { Layout } from "../Layout";
import { TemplateNav } from "../TemplateNav";
import Sidebar from "../Sidebar";

export const Icon = () => {
  return (
    <div className="d-flex w-100">
      <div style={{height:"100vh", overflow:"hidden initial"}}>
        <Sidebar/>
      </div>
      <Layout>
        <TemplateNav/>
    <div className="r-w">
      <div className="blockcode">
        <div className="header d-flex">Default Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Default Icons</div>
        <div className="example">
          <CDBBtn color="primary">
            <CDBIcon fab icon="stack-overflow" />
            Click me
          </CDBBtn>
          <CDBBtn color="secondary">
            <CDBIcon fab icon="facebook" />
            Click me
          </CDBBtn>
          <CDBBtn color="success">
            <CDBIcon fab icon="reddit" />
            Click me
          </CDBBtn>
          <CDBBtn color="danger">
            <CDBIcon fab icon="twitter" />
            Click me
          </CDBBtn>
          <CDBBtn color="dark">
            <CDBIcon fab icon="instagram" />
            Click me
          </CDBBtn>
          <CDBBtn color="warning">
            <CDBIcon fab icon="google" />
            Click me
          </CDBBtn>
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Border Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Border Icons</div>
        <div className="example">
          <CDBIcon fab border icon="stack-overflow" />
          <CDBIcon fab border icon="facebook" />
          <CDBIcon fab border icon="reddit" />
          <CDBIcon fab border icon="twitter" />
          <CDBIcon fab border icon="instagram" />
          <CDBIcon fab border icon="google" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Spin Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Spin Icons</div>
        <div className="example">
          <CDBIcon fab spin icon="stack-overflow" />
          <CDBIcon fab spin icon="facebook" />
          <CDBIcon fab spin icon="reddit" />
          <CDBIcon fab spin icon="twitter" />
          <CDBIcon fab spin icon="instagram" />
          <CDBIcon fab spin icon="google" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Pull Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Pull Icons</div>
        <div className="example">
          <CDBIcon fab pull="left" icon="stack-overflow" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Pulse Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Pulse Icons</div>
        <div className="example">
          <CDBIcon fab pulse icon="stack-overflow" />
          <CDBIcon fab pulse icon="facebook" />
          <CDBIcon fab pulse icon="reddit" />
          <CDBIcon fab pulse icon="twitter" />
          <CDBIcon fab pulse icon="instagram" />
          <CDBIcon fab pulse icon="google" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Flip Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Flip Icons</div>
        <div className="example">
          <CDBIcon fab flip="horizontal" icon="stack-overflow" />
          <CDBIcon fab flip="vertical" icon="facebook" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header d-flex">Rotate Icons <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a></div>
        <div className="description">Rotate Icons</div>
        <div className="example">
          <CDBIcon fab rotate="90" icon="stack-overflow" />
          <CDBIcon fab rotate="180" icon="facebook" />
        </div>
      </div>
      <div className="blockcode">
        <div className="header">Sizes <a href="https://devwares.com/product/contrast-pro" target="_blank" rel="noreferrer" className="text-white"><CDBBadge size="large" style={{ backgroundColor: "#9871ff" }}>Pro Component</CDBBadge></a> </div>
        <div className="description">Sizes </div>
        <div className="example">
          <CDBIcon icon="facebook" fab size="lg" />
          <CDBIcon fab icon="facebook" size="2x" />
          <CDBIcon fab icon="facebook" size="3x" />
          <CDBIcon fab icon="facebook" size="4x" />
          <CDBIcon fab icon="facebook" size="5x" />
        </div>
      </div>
    </div>
      </Layout>
    </div>
  );
};
